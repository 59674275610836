export function generateRandomPassword(): string {
  const length: number = 6;
  const charset: string =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let password: string = "";

  for (let i: number = 0; i < length; i++) {
    const randomIndex: number = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }

  return password;
}
