import React, { useState } from "react";
import ToastComponent from "../components/ToastComponent";

type ToastOptions = {
  header: string;
  message: string;
  type: "error" | "warning" | "success";
  duration?: number;
};

export const useToast = () => {
  const [toastOptions, setToastOptions] = useState<ToastOptions | null>(null);

  const showToast = (options: ToastOptions) => {
    const duration = options.duration || 5;
    setToastOptions(options);
    setTimeout(() => {
      setToastOptions(null);
    }, duration * 5000);
  };

  const ToastElement = toastOptions ? (
    <ToastComponent
      show={true}
      onClose={() => setToastOptions(null)}
      header={toastOptions.header}
      message={toastOptions.message}
      type={toastOptions.type}
    />
  ) : null;

  return { showToast, ToastElement };
};
