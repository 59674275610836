import React, { useState } from 'react';
import { InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useRequest, Product } from './context';
import { Product as SelectableProduct } from '../../services/ProductService';
import { NumericFormat } from '../../components/NumericFormat';
import { string } from 'prop-types';
import { formatToFloat } from "../../utils/formatCurrency";

import {
    Button,
    Tab,
    Tabs,
    Spinner,
    Modal,
    Badge,
    Tooltip,
    ButtonToolbar,
    OverlayTrigger
} from "react-bootstrap";
import ProductSelect from '../../components/ProductSelect';
import { useSelector } from 'react-redux';
import { SellerConsignmentProduct } from '../../types/SellerConsignment';

interface ListProductsProps {
    index: number;
    product: Product;
    status?: string;
    isEdit?: boolean;
    entityId?: number | string;
    onClickAddProduct?: (typedText: string, index: number, product: Product) => void;
    sellerConsignmentProducts?: SellerConsignmentProduct[];
}

export const ListProducts: React.FC<ListProductsProps> = ({
    index,
    product,
    status,
    isEdit,
    entityId,
    onClickAddProduct,
    sellerConsignmentProducts,
}) => {
    const { user } = useSelector((state: any) => state.auth);

    const { dispatchProducts } = useRequest();

    const handleChangeProduct = (value: SelectableProduct | string) => {
        const productObj = typeof value === 'string' ? null : value;
        const productName = typeof value === 'string' ? value : '';

        dispatchProducts({
            type: "CHANGE",
            payload: {
                index,
                product: {
                    isLinked: !!productObj,
                    name: productObj?.name ?? productName,
                    amount: 1,
                    INSTANCE: productObj ?? {} as SelectableProduct,
                    total: productObj?.saleValue ?? 0,
                    unitaryValue: productObj?.saleValue ?? 0,
                    cfop: "0",
                    icms: "0",
                    ipi: "0",
                    ncm: "0",
                },
            }
        })
    }

    const handleChangeValues = (amount: number, unitaryValue: any) => {
        dispatchProducts({
            type: "CHANGE",
            payload: {
                index,
                product: {
                    ...product,
                    amount: amount,
                    unitaryValue: formatToFloat(unitaryValue),
                    total: amount * formatToFloat(unitaryValue),
                }
            }
        })
    }

    return (
        <div className="row col-lg-12 ml-2">
            <div className="col-lg-4 d-flex align-items-center p-1 ">
                <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2">
                    <label className="text-white m-0">{index + 1}.</label>

                    {
                        product.INSTANCE.isKit == "y"
                            ?
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id='tooltip-top'>
                                        <b>Produtos do Kit</b><br />
                                        {product.INSTANCE.kit}
                                    </Tooltip>
                                }
                            >
                                <Button
                                    size="sm"
                                    variant="primary"
                                    className="d-flex flex-row align-items-center justify-content-center ml-2 p-0"
                                    style={{ padding: "5px", background: "transparent" }}
                                >
                                    <i className="flaticon2-information m-0 p-0"></i>
                                </Button>
                            </OverlayTrigger>
                            :
                            <></>
                    }
                </div>

                {sellerConsignmentProducts ? (
                    <Autocomplete
                        size="small"
                        noOptionsText="Sem opções"
                        options={sellerConsignmentProducts.map(prod => prod.INSTANCE)}
                        renderOption={(option) => {
                            if (!option) return '';

                            const consignmentProduct = sellerConsignmentProducts.find((prod) => prod.productId === option.id);

                            return `${option.name} - Qtde: ${consignmentProduct?.amount ?? 0}`;
                        }}
                        style={{ width: "100%" }}
                        getOptionLabel={(option) => option?.name ?? ''}
                        onChange={(e, val) => handleChangeProduct(val ?? {} as SelectableProduct)}
                        value={product.INSTANCE}
                        disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                        renderInput={(params) =>
                            <TextField
                                size="small"
                                {...params}
                                label='Produto'
                                margin='normal'
                                variant='outlined'
                                error={product.hasError}
                            />
                        }
                    />
                ) : (
                    <ProductSelect
                        label="Produto"
                        isEdit={isEdit}
                        selectedProduct={product}
                        onChangeProduct={handleChangeProduct}
                        entityType="request"
                        entityId={entityId}
                        disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                        hasError={product.hasError}
                        allowIncomplete={true}
                        type="product"
                        onClickAddProduct={onClickAddProduct ? (typedText) => onClickAddProduct(typedText, index, product) : undefined}
                    />
                )}
            </div>

            <div className="col p-1">
                <TextField
                    type="number"
                    size="small"
                    label="Qtde."
                    margin="normal"
                    variant="outlined"
                    name="amount"

                    value={product.amount}
                    onChange={
                        (evt) => handleChangeValues(Number(evt.target.value), product.unitaryValue)
                    }
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                />
            </div>

            <div className="col p-1">
                <NumericFormat
                    label="IPI %"
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                    value={product.ipi}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    ipi: evt.target.value
                                }
                            }
                        })
                    }
                    onBlur={(e) => {
                        var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                        if (converted > 100) {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        ipi: "100"
                                    }
                                }
                            })

                        } else {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        ipi: converted ? String(converted) : "0"
                                    }
                                }
                            })
                        }
                    }}
                />
            </div>

            <div className="col p-1">
                <NumericFormat
                    label="ICMS %"
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                    value={product.icms}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    icms: evt.target.value
                                }
                            }
                        })
                    }
                    onBlur={(e) => {
                        var converted = e.target.value ? formatToFloat(e.target.value) : 0;

                        if (converted > 100) {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        icms: "100"
                                    }
                                }
                            })

                        } else {
                            dispatchProducts({
                                type: "CHANGE",
                                payload: {
                                    index,
                                    product: {
                                        ...product,
                                        icms: converted ? String(converted) : "0"
                                    }
                                }
                            })
                        }
                    }}
                />
            </div>

            <div className="col-lg-2 p-1">
                <NumericFormat
                    label="Valor Unit."
                    value={product.unitaryValue}
                    onChange={
                        (evt) => handleChangeValues(Number(product.amount), evt.target.value)
                    }
                    startAdornment="R$"
                    disabled={user.isAccountant == "y" ? true : false}
                />
            </div>

            <div className="col p-1">
                <NumericFormat
                    format="####"
                    label="CFOP"
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                    value={product.cfop}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    cfop: evt.target.value
                                }
                            }
                        })
                    }
                />
            </div>

            <div className="col p-1">
                <NumericFormat
                    label="NCM"
                    format="########"
                    disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                    value={product.ncm}
                    onChange={
                        (evt) => dispatchProducts({
                            type: "CHANGE",
                            payload: {
                                index,
                                product: {
                                    ...product,
                                    ncm: evt.target.value
                                }
                            }
                        })
                    }
                />
            </div>

            <div className="col-lg-2 p-1">
                <NumericFormat
                    label="Valor Total"
                    disabled
                    value={product.total}
                    startAdornment="R$"
                />
            </div>

            {
                (index > 0 && user.isAccountant == "n") && (
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => dispatchProducts({
                            type: "DELETE",
                            payload: {
                                index
                            }
                        })}
                        disabled={status == "attended" || user.isAccountant == "y" ? true : false}
                    >
                        <i className="flaticon-delete"></i>
                    </button>
                )
            }
        </div>
    );
}