/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useCallback, useEffect, useState } from "react";
import {
  Redirect,
  Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { NewPdv } from "./pages/Pdv/NewPdv";
import ReceiptPdv from "./pages/Pdv/Pdf/ReceiptPdv";
import PdfOrderView from "./components/PdfOrder/PdfOrderView";
import { PersonalHooks } from "./hooks";
import useMenu, { Menus } from "./hooks/menu";
import api from "./services/Api";
import useCompany from "./hooks/company";
import { AnswerQuotation } from "./pages/Quotation/AnswerQuotation";
import { isStandaloneRoute } from "./utils/isStandaloneRoute";
import { useCompanyBranch } from "./hooks/companyBranch";
import CompanyService from "./services/CompanyService";
import NewPassword from "./pages/Auth/New-Password";
import CompanySubscriptionPlanService from "./services/CompanySubscriptionPlanService";
import { AcceptIndication } from "./pages/Indication/AcceptIndication";
import { DeclineIndication } from "./pages/Indication/DeclineIndication";
import { CompleteCompanyData } from "./pages/Indication/CompleteCompanyData";
import { CreateCompanyFromIndicationLink } from "./pages/Indication/CreateCompanyFromIndicationLink";
import { routeHasUniqueLayout } from "./utils/routeHasUniqueLayout";
import VerifyCellPage from "./pages/Auth/VerifyCellPage";
import useQueryParams from "./hooks/queryParams";
import { getAllowedRoutesWhenPlanPaymentIsRequired } from "./utils/getAllowedRoutesWhenPlanPaymentIsRequired";
import { FreeTrialPage } from "./pages/FreeTrial/FreeTrialPage";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  const user = useSelector(({ auth }) => auth.user, shallowEqual);

  const { menusPlain, superRoutes } = useMenu();

  const location = useLocation();
  const { getQueryParam } = useQueryParams();
  const history = useHistory();
  const { companies, companyCanHaveBranches } = useCompany({
    only: user?.companyId,
    appendSubscriptionPlan: true,
  });
  const [mainCompany] = companies;
  const { selectedCompany } = useCompanyBranch();

  const [customRedirectRoute, setCustomRedirectRoute] = useState("");
  const [redirectFromAuthRoute, setRedirectFromAuthRoute] = useState("");

  useEffect(() => {
    if (!user) {
      return;
    }

    function accessVerifications(pathname) {
      verifyRoutePermission(pathname);
      verifyCompanySubscription(pathname);
      userCellIsVerified(pathname);
    }

    accessVerifications(history.location.pathname);

    const unlisten = history.listen((location, action) => {
      accessVerifications(location.pathname);
    });

    return unlisten;
  }, [menusPlain, user, superRoutes, selectedCompany]);

  useEffect(() => {
    const customRedirectRoute = getQueryParam("custom_redirect_route");

    setCustomRedirectRoute(customRedirectRoute);

    const redirect = getQueryParam("redirect");
    setRedirectFromAuthRoute(redirect);
  }, [getQueryParam]);

  const verifyRoutePermission = useCallback(
    (path) => {
      if (path.startsWith("/usuarios") && user.isAdmin === "y") {
        return;
      }

      if (
        user.isSuper === "n" &&
        superRoutes.find(
          (route) => path.startsWith(`${route}/`) || path === route
        )
      ) {
        history.push("/error/error-permission");
      }

      const currentModule = menusPlain.find((menu) =>
        path.startsWith(menu.route)
      );

      if (currentModule && selectedCompany) {
        const permissions =
          user.isAccountant == "y"
            ? JSON.parse(user.permissions).find(
                (permission) => permission.companyId == selectedCompany.id
              ).permissions
            : JSON.parse(user.permissions);
        let allowed = permissions.includes(currentModule.id);

        if (
          mainCompany &&
          currentModule.id === Menus.BRANCHES &&
          !companyCanHaveBranches(mainCompany) &&
          !CompanyService.isMainCompany(selectedCompany)
        ) {
          allowed = false;
        }

        if (!allowed) {
          history.push("/error/error-permission");
        }
      }
    },
    [menusPlain, user, superRoutes, mainCompany, selectedCompany]
  );

  const verifyCompanySubscription = useCallback(
    async (path) => {
      if (!user || !selectedCompany) return;

      const allowedRoutes = getAllowedRoutesWhenPlanPaymentIsRequired(
        user.isAccountant === "y"
      );

      if (allowedRoutes.find((route) => path.startsWith(route))) {
        return;
      }

      const companyPaymentRequired = await CompanySubscriptionPlanService.planPaymentIsRequired(
        user.isAccountant === "y" ? selectedCompany.id : user.companyId
      );

      if (companyPaymentRequired.isRequired) {
        history.push(
          user.isAccountant === "y"
            ? "/contador/empresa-bloqueada"
            : "/plano/pagamento"
        );
      }
    },
    [user, history, selectedCompany]
  );

  const userCellIsVerified = useCallback(
    async (path) => {
      if (!user) return;

      if (
        path.startsWith("/verificar-whatsapp") ||
        path.startsWith("/logout") ||
        path.startsWith("/auth")
      )
        return;

      const cellRequiresVerification =
        !user.cellVerifiedAt && user.requiresCellVerification === "y";

      if (cellRequiresVerification) {
        history.push("/verificar-whatsapp");
      }
    },
    [user, history]
  );

  return (
    <Switch>
      <Route path="/teste-gratuito" component={FreeTrialPage} />

      {customRedirectRoute && (
        <>
          {!isAuthorized ? (
            <Redirect to={`/auth/login?redirect=${customRedirectRoute}`} />
          ) : (
            <Redirect to={customRedirectRoute} />
          )}
        </>
      )}

      {/* Create new password from email */}

      {!isAuthorized && !isStandaloneRoute(location.pathname) ? (
        <>
          {/*  Render auth page when user at `/auth` and not authorized. */}
          <Route>
            <AuthPage />
          </Route>
        </>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect
          from="/auth"
          to={redirectFromAuthRoute ? redirectFromAuthRoute : "/"}
        />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      <Route path="/pdf/:type/:id" component={PdfOrderView} />
      <Route
        path="/responder-cotacao/:quotationId/:quotationSupplierId"
        component={AnswerQuotation}
      />
      <Route path="/criar-senha/:idUser" component={NewPassword} />
      <Route
        path="/indicacao/link"
        component={CreateCompanyFromIndicationLink}
      />
      <Route
        path="/indicacao/:indicationId/aceitar"
        component={AcceptIndication}
      />
      <Route
        path="/indicacao/:indicationId/cancelar"
        component={DeclineIndication}
      />
      <Route
        path="/indicacao/:indicationId/completar-cadastro"
        component={CompleteCompanyData}
      />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <>
          <PersonalHooks>
            {/* Rotas que não usam o layout padrão */}
            <Route path="/pdv/venda" exact component={() => <NewPdv />} />
            <Route path="/verificar-whatsapp" component={VerifyCellPage} />

            {routeHasUniqueLayout(location.pathname) ? (
              ""
            ) : (
              <Layout>
                <BasePage />
              </Layout>
            )}
          </PersonalHooks>
        </>
      )}
    </Switch>
  );
}
