import React, {
  Dispatch,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  SetStateAction,
} from "react";
import filesize from "filesize";
import { v4 as uuidV4 } from "uuid";

import api from "../services/Api";

export type Product = {
  id: number;
  name: string;
  code: string;
  saleValue: number;
  codeGtinEan: string;
  stockInitial: string;
  currentStock: number;
  hasVariation: string;
  isKit: string;
  kit: string;
  situation?: string;
  brand?: string;
  category?: string;
  stockHistoric?: StockProductHistoric[];
  type: "product" | "service" | "variation" | "kit";
  ncm?: string;
  ipiRate?: string;
  icmsRate?: string;
};

export type StockProductHistoric = {
  id: number;
  productId: number;
  hasVariation: "y" | "n";
  variationId: number;
  date: string;
  entry: number;
  output: number;
  observation: string;
  value: number;
  stockId: number;
  stockName: string;
  type:
    | "Saída manual"
    | "Entrada manual"
    | "Pedido de compra"
    | "Pedido de compra (Dev)"
    | "Pedido de venda"
    | "Pedido de venda (Dev)"
    | "Transferência saída"
    | "Transferência entrada"
    | "Balanço"
    | "Entrada de mercadoria"
    | "Venda PDV";
  companyId: number;
  totalLocation: number;
  total: number;
  requestOrPurchaseId: number;
};

export type CategoryProduct = {
  id: number;
  uuid: string;
  description: string;
  nameCategory: string;
};

export interface IFile {
  id: string;
  name: string;
  readableSize: string;
  uploaded?: boolean;
  preview: string;
  file: File | null;
  progress?: number;
  error?: boolean;
  url: string;
}

type Variation = {
  id: number;
  productId: number;
  gridId?: number;
  name: string;
  saleValue: number;
  costValue: number;
};

interface ProductsContextData {
  files: IFile[];
  all: Product[];
  variations: Variation[];
  productAndVariations: Product[];
  products: Product[];
  services: Product[];
  allProductsNames: string[];
  handleRemove: (id: string) => void;
  onDelete(id: number): Promise<void>;
  categoryProducts: CategoryProduct[];
  handleUpload: (files: File[]) => void;
  setAll: Dispatch<SetStateAction<Product[]>>;
  productByName(name: string): Promise<Product>;
}

const ProductContext = createContext<ProductsContextData>(
  {} as ProductsContextData
);

const ProductProvider: React.FC = ({ children }) => {
  const [all, setAll] = useState<Product[]>([]);
  const [variations] = useState<Variation[]>([]);
  const [productAndVariations] = useState<Product[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [services, setServices] = useState<Product[]>([]);
  const [allProductsNames] = useState([""]);
  const [categoryProducts] = useState<CategoryProduct[]>([]);

  const productByName = useCallback(async (name: string) => {
    const response = await api.get(`products/${name}`);

    return response.data;
  }, []);

  const onDelete = useCallback(
    async (id: number) => {
      try {
        const product = all.find((prod) => prod.id === id);

        if (!product) {
          throw new Error();
        }

        await api.delete(`products/${id}`);

        const newState = all.filter((prod) => prod.id !== id);
        const p = newState.filter((d) => d.type === "product");
        const s = newState.filter((d) => d.type === "service");

        setProducts(p);
        setServices(s);

        setAll(newState);
      } catch {
        alert("Erro na deleção do produto!");
      }
    },
    [all]
  );

  const handleRemove = useCallback((id: string) => {
    setFiles((prev) => prev.filter((item) => item.id !== id));
  }, []);

  const handleUpload = useCallback((files: File[]) => {
    const newUploadedFiles: IFile[] = files.map((file) => ({
      file,
      id: uuidV4(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: "",
    }));

    setFiles((prev) => prev.concat(newUploadedFiles));
  }, []);

  return (
    <ProductContext.Provider
      value={{
        all,
        variations,
        productAndVariations,
        files,
        setAll,
        products,
        services,
        onDelete,
        handleRemove,
        handleUpload,
        productByName,
        categoryProducts,
        allProductsNames,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

function useProducts() {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error(
      "The method useProduct must be used within a ProductProvider!"
    );
  }

  return context;
}

export { ProductProvider, useProducts };
