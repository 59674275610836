import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { Tab, Tabs, Button, Modal } from "react-bootstrap";
import { Box, Tooltip, TextField, MenuItem } from "@material-ui/core";

import ModalAdd from "./ModalAdd";
import ModalEdit from "./ModalEdit";
import ModalDelete from "./ModalDelete";
import ModalSubAdd from "./ModalSubAdd";
import ModalSubEdit from "./ModalSubEdit";
import ModalSubDelete from "./ModalSubDelete";

import "../../style.css";
import api from "../../services/Api";
import { Category, SubCategory } from "../../types/Dre";
import { useSelector } from "react-redux";

export function ListAccountsPlan() {
  const { user } = useSelector((state: any) => state.auth);

  /* Add Category */
  const [modalAdd, setModalAdd] = useState(false);
  const [nameAdd, setNameAdd] = useState("");
  const [type, setType] = useState("");
  const [listCategoriesAdd, setListCategoriesAdd] = useState<Category[]>([]);

  function setAdd(type: string) {
    type == "revenue"
      ? setListCategoriesAdd(listCategoriesRevenue)
      : setListCategoriesAdd(listCategoriesExpense);
    setType(type);
    setModalAdd(true);
  }
  /* */

  /* Edit Category */
  const [modalEdit, setModalEdit] = useState(false);
  const [idEdit, setIdEdit] = useState(0);
  const [nameEdit, setNameEdit] = useState("");

  function setEdit(category: Category) {
    setIdEdit(category.id);
    setNameEdit(category.name);
    setModalEdit(true);
  }
  /* */

  /* Delete Category */
  const [modalDelete, setModalDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(0);
  const [nameDelete, setNameDelete] = useState("");

  function setDelete(category: Category) {
    setIdDelete(category.id);
    setNameDelete(category.name);
    setModalDelete(true);
  }
  /* */

  /* Add Sub */
  const [modalSubAdd, setModalSubAdd] = useState(false);
  const [nameSubAdd, setNameSubAdd] = useState("");
  const [dreCategoryId, setDreCategoryId] = useState(0);

  function setSubAdd(category: Category) {
    setDreCategoryId(category.id);
    setModalSubAdd(true);
  }
  /* */

  /* Edit Sub */
  const [modalSubEdit, setModalSubEdit] = useState(false);
  const [idSubEdit, setIdSubEdit] = useState(0);
  const [dreCategoryIdSubEdit, setDreCategoryIdSubEdit] = useState(0);
  const [nameSubEdit, setNameSubEdit] = useState("");
  const [listCategoriesSubEdit, setListCategoriesSubEdit] = useState<
    Category[]
  >([]);

  function setSubEdit(sub: SubCategory, category: Category) {
    setIdSubEdit(sub.id);
    setDreCategoryIdSubEdit(sub.dreCategoryId);
    setNameSubEdit(sub.name);
    category.type == "revenue"
      ? setListCategoriesSubEdit(listCategoriesRevenue)
      : setListCategoriesSubEdit(listCategoriesExpense);
    setModalSubEdit(true);
  }

  /* Delete Sub */
  const [modalSubDelete, setModalSubDelete] = useState(false);
  const [idSubDelete, setIdSubDelete] = useState(0);
  const [nameSubDelete, setNameSubDelete] = useState("");

  function setSubDelete(sub: SubCategory) {
    setIdSubDelete(sub.id);
    setNameSubDelete(sub.name);
    setModalSubDelete(true);
  }

  const [listCategoriesRevenue, setListCategoriesRevenue] = useState<
    Category[]
  >([]);
  const [listCategoriesExpense, setListCategoriesExpense] = useState<
    Category[]
  >([]);

  useEffect(() => {
    getCategoriesRevenue();
    getCategoriesExpense();
  }, []);

  async function getCategoriesRevenue() {
    var response = await api.get(`/dre/revenue`);
    setListCategoriesRevenue(response.data);
  }

  async function getCategoriesExpense() {
    var response = await api.get(`/dre/expense`);
    setListCategoriesExpense(response.data);
  }

  return (
    <div>
      <div className="card card-body pt-4">
        <Tabs defaultActiveKey="dre-receitas" id="dre-form-tabs">
          <Tab eventKey="dre-receitas" title="Receitas">
            {user.isAccountant == "n" ? (
              <div className="row mt-4">
                <div className="col-12 d-flex justify-content-end align-items-center mt-3">
                  <Button
                    type="button"
                    variant="success"
                    onClick={() => setAdd("revenue")}
                  >
                    Adicionar Categoria
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}

            <Box>
              <nav aria-label="main mailbox folders">
                {listCategoriesRevenue.map((category) => {
                  return (
                    <React.Fragment key={category.id}>
                      <ul className="list-group my-4 py-4">
                        <li className="list-group-item list-group-item-secondary rounded-top border-secondary">
                          {/* category.editable === false */ false ? (
                            <div className="d-flex justify-content-between align-items-center">
                              <b>
                                {category.name} -{" "}
                                {category.company ? category.company.name : ""}
                              </b>

                              {user.isAccountant == "n" ? (
                                <div>
                                  <Tooltip title="Adicionar Sub Categoria">
                                    <Button
                                      className="btn-light-dark p-2 mr-3"
                                      variant="primary"
                                      type="button"
                                      onClick={() => setSubAdd(category)}
                                    >
                                      <i className="flaticon2-add p-0"></i>
                                    </Button>
                                  </Tooltip>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center">
                              <b>
                                {category.name} -{" "}
                                {category.company ? category.company.name : ""}
                              </b>

                              {user.isAccountant == "n" ? (
                                <div>
                                  <Tooltip title="Adicionar Sub Categoria">
                                    <Button
                                      className="btn-light-dark p-2 mr-3"
                                      variant="primary"
                                      type="button"
                                      onClick={() => setSubAdd(category)}
                                    >
                                      <i className="flaticon2-add p-0"></i>
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Editar">
                                    <Button
                                      className="btn-light-primary p-2 mr-3"
                                      variant="primary"
                                      type="button"
                                      onClick={() => setEdit(category)}
                                    >
                                      <i className="flaticon2-edit p-0"></i>
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Excluir">
                                    <Button
                                      className="btn-light-danger p-2 mr-3"
                                      variant="danger"
                                      type="button"
                                      onClick={() => {
                                        setType("revenue");
                                        setDelete(category);
                                      }}
                                    >
                                      <i className="flaticon2-trash p-0"></i>
                                    </Button>
                                  </Tooltip>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </li>
                        {category.subCategories.length > 0 ? (
                          category.subCategories.map((sub: SubCategory) => {
                            return (
                              <React.Fragment key={sub.id}>
                                <li className="list-group-item border-secondary px-4">
                                  {/* sub.editable === false */ false ? (
                                    <p className="px-4 m-0 ml-3">{sub.name}</p>
                                  ) : (
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="px-4 m-0 ml-3">
                                        {sub.name}
                                      </p>

                                      {user.isAccountant == "n" ? (
                                        <div>
                                          <Tooltip title="Editar">
                                            <Button
                                              className="btn-light-primary p-2 mr-3"
                                              variant="primary"
                                              type="button"
                                              onClick={() =>
                                                setSubEdit(sub, category)
                                              }
                                            >
                                              <i className="flaticon2-edit p-0"></i>
                                            </Button>
                                          </Tooltip>
                                          <Tooltip title="Excluir">
                                            <Button
                                              className="btn-light-danger p-2 mr-3"
                                              variant="danger"
                                              type="button"
                                              onClick={() => {
                                                setType("revenue");
                                                setSubDelete(sub);
                                              }}
                                            >
                                              <i className="flaticon2-trash p-0"></i>
                                            </Button>
                                          </Tooltip>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )}
                                </li>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <li className="list-group-item text-center border-secondary">
                            Esta categoria não possui sub-categorias
                          </li>
                        )}
                      </ul>
                    </React.Fragment>
                  );
                })}
              </nav>
            </Box>
          </Tab>

          <Tab eventKey="dre-despesas" title="Despesas">
            {user.isAccountant == "n" ? (
              <div className="row mt-4">
                <div className="col-12 d-flex justify-content-end align-items-center mt-3">
                  <Button
                    type="button"
                    variant="success"
                    onClick={() => setAdd("expense")}
                  >
                    Adicionar Categoria
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}

            <Box>
              <nav aria-label="main mailbox folders">
                {listCategoriesExpense.map((category) => {
                  return (
                    <React.Fragment key={category.id}>
                      <ul className="list-group my-4 py-4">
                        <li className="list-group-item list-group-item-secondary rounded-top border-secondary">
                          {/* category.editable === false */ false ? (
                            <div className="d-flex justify-content-between align-items-center">
                              <b>
                                {category.name} -{" "}
                                {category.company ? category.company.name : ""}
                              </b>

                              {user.isAccountant == "n" ? (
                                <div>
                                  <Tooltip title="Adicionar Sub Categoria">
                                    <Button
                                      className="btn-light-dark p-2 mr-3"
                                      variant="primary"
                                      type="button"
                                      onClick={() => setSubAdd(category)}
                                    >
                                      <i className="flaticon2-add p-0"></i>
                                    </Button>
                                  </Tooltip>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <div className="d-flex justify-content-between align-items-center">
                              <b>
                                {category.name} -{" "}
                                {category.company ? category.company.name : ""}
                              </b>

                              {user.isAccountant == "n" ? (
                                <div>
                                  <Tooltip title="Adicionar Sub Categoria">
                                    <Button
                                      className="btn-light-dark p-2 mr-3"
                                      variant="primary"
                                      type="button"
                                      onClick={() => setSubAdd(category)}
                                    >
                                      <i className="flaticon2-add p-0"></i>
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Editar">
                                    <Button
                                      className="btn-light-primary p-2 mr-3"
                                      variant="primary"
                                      type="button"
                                      onClick={() => setEdit(category)}
                                    >
                                      <i className="flaticon2-edit p-0"></i>
                                    </Button>
                                  </Tooltip>
                                  <Tooltip title="Excluir">
                                    <Button
                                      className="btn-light-danger p-2 mr-3"
                                      variant="danger"
                                      type="button"
                                      onClick={() => {
                                        setType("expense");
                                        setDelete(category);
                                      }}
                                    >
                                      <i className="flaticon2-trash p-0"></i>
                                    </Button>
                                  </Tooltip>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          )}
                        </li>
                        {category.subCategories.length > 0 ? (
                          category.subCategories.map((sub: SubCategory) => {
                            return (
                              <React.Fragment key={sub.id}>
                                <li className="list-group-item border-secondary px-4">
                                  {/* sub.editable === false */ false ? (
                                    <p className="px-4 m-0 ml-3">{sub.name}</p>
                                  ) : (
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="px-4 m-0 ml-3">
                                        {sub.name}
                                      </p>

                                      {user.isAccountant == "n" ? (
                                        <div>
                                          <Tooltip title="Editar">
                                            <Button
                                              className="btn-light-primary p-2 mr-3"
                                              variant="primary"
                                              type="button"
                                              onClick={() =>
                                                setSubEdit(sub, category)
                                              }
                                            >
                                              <i className="flaticon2-edit p-0"></i>
                                            </Button>
                                          </Tooltip>
                                          <Tooltip title="Excluir">
                                            <Button
                                              className="btn-light-danger p-2 mr-3"
                                              variant="danger"
                                              type="button"
                                              onClick={() => {
                                                setType("expense");
                                                setSubDelete(sub);
                                              }}
                                            >
                                              <i className="flaticon2-trash p-0"></i>
                                            </Button>
                                          </Tooltip>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  )}
                                </li>
                              </React.Fragment>
                            );
                          })
                        ) : (
                          <li className="list-group-item text-center border-secondary">
                            Esta categoria não possui sub-categorias
                          </li>
                        )}
                      </ul>
                    </React.Fragment>
                  );
                })}
              </nav>
            </Box>
          </Tab>
        </Tabs>
      </div>

      <ModalAdd
        modalAdd={modalAdd}
        setModalAdd={setModalAdd}
        nameAdd={nameAdd}
        setNameAdd={setNameAdd}
        type={type}
        setType={setType}
        listCategoriesAdd={listCategoriesAdd}
        setListCategoriesAdd={setListCategoriesAdd}
        getCategoriesRevenue={getCategoriesRevenue}
        getCategoriesExpense={getCategoriesExpense}
      />

      <ModalEdit
        modalEdit={modalEdit}
        setModalEdit={setModalEdit}
        idEdit={idEdit}
        setIdEdit={setIdEdit}
        nameEdit={nameEdit}
        setNameEdit={setNameEdit}
        getCategoriesRevenue={getCategoriesRevenue}
        getCategoriesExpense={getCategoriesExpense}
      />

      <ModalDelete
        modalDelete={modalDelete}
        setModalDelete={setModalDelete}
        idDelete={idDelete}
        setIdDelete={setIdDelete}
        nameDelete={nameDelete}
        setNameDelete={setNameDelete}
        type={type}
        getCategoriesRevenue={getCategoriesRevenue}
        getCategoriesExpense={getCategoriesExpense}
      />

      <ModalSubAdd
        modalSubAdd={modalSubAdd}
        setModalSubAdd={setModalSubAdd}
        nameSubAdd={nameSubAdd}
        dreCategoryId={dreCategoryId}
        setDreCategoryId={setDreCategoryId}
        setNameSubAdd={setNameSubAdd}
        getCategoriesRevenue={getCategoriesRevenue}
        getCategoriesExpense={getCategoriesExpense}
      />

      <ModalSubEdit
        modalSubEdit={modalSubEdit}
        setModalSubEdit={setModalSubEdit}
        idSubEdit={idSubEdit}
        setIdSubEdit={setIdSubEdit}
        nameSubEdit={nameSubEdit}
        setNameSubEdit={setNameSubEdit}
        dreCategoryIdSubEdit={dreCategoryIdSubEdit}
        setDreCategoryIdSubEdit={setDreCategoryIdSubEdit}
        listCategoriesSubEdit={listCategoriesSubEdit}
        getCategoriesRevenue={getCategoriesRevenue}
        getCategoriesExpense={getCategoriesExpense}
      />

      <ModalSubDelete
        modalSubDelete={modalSubDelete}
        setModalSubDelete={setModalSubDelete}
        idSubDelete={idSubDelete}
        setIdSubDelete={setIdSubDelete}
        nameSubDelete={nameSubDelete}
        setNameSubDelete={setNameSubDelete}
        type={type}
        getCategoriesRevenue={getCategoriesRevenue}
        getCategoriesExpense={getCategoriesExpense}
      />
    </div>
  );
}
