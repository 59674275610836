import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ValidationBadge } from "../../components/ValidationBadge";
import api from "../../services/Api";
import { Indications, status } from "../../types/Indications";

export function DeclineIndication() {
    const { indicationId } = useParams<{ indicationId: string }>();
    const [indicationExpired, setIndicationExpired] = useState(false);
    const [resultLoaded, setResultLoaded] = useState(false);

    useEffect(() => {
        async function declineIndication() {
            if (!indicationId) {
                return;
            }

            try {
                const indicationDeclineResponse = await api.patch<Indications>(`/indications/status`, { id: indicationId, status: status.CANCELED });
            } catch (error) {
                console.log('error', error);
                setIndicationExpired(true);
            } finally {
                setResultLoaded(true);
            }
        }

        declineIndication();
    }, [indicationId])

    return (
        <div className="d-flex flex-column flex-root">
            <div
                className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
                id="kt_login"
            >
                <div
                    className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                    style={{ backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-12.jpg")})` }}
                >
                    <div className="d-flex flex-row-fluid flex-column justify-content-between">
                        <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                            <img
                                alt="Logo"
                                className="max-h-70px"
                                src={toAbsoluteUrl("/media/logos/header/logo-header-gold.png")}
                            />
                        </Link>

                        <div className="flex-column-fluid d-flex flex-column justify-content-center">
                            <h3 className="font-size-h1 mb-5 text-white">
                                Bem-vindo!
                            </h3>
                            <p className="font-weight-lighter text-white">
                                Milliontech, o sistema que faltava para você ter mais LUCRO e LIBERDADE
                            </p>
                        </div>

                        <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                            <div className="font-weight-bold	text-white">
                                {new Date().getFullYear()} &copy; Milliontech
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                    <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                        <div className="login-form login-signin" style={{ display: "block" }}>
                            <div className="text-center mb-10 mb-lg-20">
                                {resultLoaded && (
                                    <>
                                        {!indicationExpired && (
                                            <>
                                                <h3 className="font-size-h1">
                                                    <ValidationBadge label="" variant="success" />
                                                    Convite recusado corretamente! Você já pode fechar esta aba.
                                                </h3>
                                            </>
                                        )}

                                        {indicationExpired && (
                                            <>
                                                <h3 className="font-size-h1">
                                                    <ValidationBadge label="" variant="danger" />
                                                    Convite não é válido!
                                                </h3>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}