import React from 'react';
import {useSubheader} from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import { ListModelForms } from '../pages/Bpm/ModelForm/List-ModelForm';
import { ListModelProcess } from '../pages/Bpm/ModelProcess/List-ModelProcess';
import { ListSolicitationProcess } from '../pages/Bpm/Solicitation/List-SolicitationProcess';
import { NewModelForm } from '../pages/Bpm/ModelForm/New-ModelForm';

export function Bpm() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("BPM");
    
    return (
        <Switch>
            <Route path="/bpm/modelagem-de-formulario/adicionar" component={NewModelForm}/>
            {/* <Route path="/bpm/modelagem-de-formulario/:id" component={ListModelForms}/> */}
            <Route path="/bpm/modelagem-de-formulario" component={ListModelForms}/>

            <Route path="/bpm/modelagem-de-processos" component={ListModelProcess}/>
            <Route path="/bpm/solicitacoes-de-processos" component={ListSolicitationProcess}/>
        </Switch>
    );
}