import React, { useCallback, useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Workbook, CellValue } from "exceljs";
import FileSaver from "file-saver";
import ModalError from "../../components/ModalError";
import ModalSuccess from "../../components/ModalSuccess";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import api from "../../services/Api";
import { formatToFloat } from "../../utils/formatCurrency";
import productHelpers from "../../utils/productHelpers";

export function ImportExcelProduct() {
  const productOrigin: any = {
    "0": "0 - Nacional",
    "1": "1 - Estrangeira - Importação direta",
    "2": "2 - Estrangeira - Adquirida no mercado interno",
    "3":
      "3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%",
    "4":
      "4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes",
    "5":
      "5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%",
    "6":
      "6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX",
    "7":
      "7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX",
    "8":
      "8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%",
  };
  const [productLocation, setProductLocation] = useState<any>([]);

  const [isImporting, setIsImporting] = useState(false);

  const [msgModal, setMsgModal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [msgError, setMsgError] = useState<string | string[]>("");
  const [showModalError, setShowModalError] = useState(false);
  const [labelProgress, setLabelProgress] = useState("");

  useEffect(() => {
    const fetch = async () => {
      let prodList = await productHelpers.allStockValues();
      setProductLocation(prodList);
    };
    fetch().then();
  }, []);

  type ResponseType = {
    status: any;
    message: any;
  };

  const readExcel = useCallback(
    (file: FileList | any) => {
      setLabelProgress("Carregando arquivo");

      if (file.length) {
        const workbook = new Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(file[0]);
        reader.onload = () => {
          const buffer = reader.result as ArrayBuffer;
          try {
            setLabelProgress("Lendo arquivo, aguarde...");
            workbook.xlsx.load(buffer).then((workbook) => {
              const sheets = workbook.worksheets;

              const processSheet = (index: number) => {
                if (index >= sheets.length) {
                  // Todas as planilhas foram processadas
                  setIsImporting(false);
                  setMsgModal("Excel importado com sucesso!");
                  setShowModal(true);
                  return;
                }

                const sheet = sheets[index];
                let response: ResponseType = {
                  status: "success",
                  message: "",
                };

                const validateSheetRows = (rowIndex: number) => {
                  if (rowIndex >= sheet.rowCount) {
                    // Validação concluída
                    if (response.status === "success") {
                      processImportRows(sheet, 2); // Inicia a importação a partir da linha 2
                    } else {
                      setMsgError(response.message);
                      setShowModalError(true);
                    }
                    return;
                  }

                  const row = sheet.getRow(rowIndex);
                  setLabelProgress(`Validando linha ${rowIndex}`);

                  if (rowIndex > 1) {
                    const responseValidate = validateSheet(row.values, sheet);

                    if (responseValidate.status === "error") {
                      response = responseValidate;
                      setMsgError(response.message);
                      setShowModalError(true);
                      return;
                    }
                  }

                  setTimeout(() => validateSheetRows(rowIndex + 1), 0); // Processa a próxima linha
                };

                const processImportRows = (sheet: any, rowIndex: number) => {
                  if (rowIndex > sheet.rowCount) {
                    setIsImporting(false);
                    setMsgModal("Excel importado com sucesso!");
                    setShowModal(true);
                    processSheet(index + 1); // Processa a próxima planilha
                    return;
                  }

                  const row = sheet.getRow(rowIndex);
                  setLabelProgress(`Importando linha ${rowIndex}`);

                  if (Array.isArray(row.values) && rowIndex > 1) {
                    const [
                      ,
                      type,
                      name,
                      unit,
                      codeGtinEan,
                      saleValue,
                      productOriginValue,
                      ncm,
                      cestCode,
                      icmsRate,
                      ipiRate,
                      code,
                      costValue,
                      stockLocation,
                      stockMin,
                      stockMax,
                      stockInitial,
                    ] = row.values as CellValue[];

                    const productToCreate = {
                      type:
                        capitalizeFirstLetter(
                          (type as string).toLowerCase()
                        ) === "Produto"
                          ? "product"
                          : "service",
                      name,
                      unit: capitalizeFirstLetter(
                        (unit as string).toLowerCase()
                      ),
                      codeGtinEan,
                      saleValue: saleValue
                        ? String(saleValue).replaceAll(".", ",")
                        : 0,
                      productOrigin:
                        productOriginValue || productOriginValue === "0"
                          ? productOrigin[String(productOriginValue)]
                          : "",
                      ncm: ncm ? productHelpers.getNcm(ncm.toString()) : "",
                      cestCode: cestCode
                        ? productHelpers.getCest(cestCode.toString())
                        : "",
                      icmsRate: icmsRate ? formatToFloat(icmsRate) : "",
                      ipiRate: ipiRate ? formatToFloat(ipiRate) : "",
                      code,
                      costValue: costValue
                        ? String(costValue).replaceAll(".", ",")
                        : 0,
                      stockLocation: stockLocation
                        ? productHelpers.searchStockLocation(
                            stockLocation.toString(),
                            productLocation
                          )
                        : 0,
                      stockMin,
                      stockMax,
                      stockInitial,
                      hasVariation: "n",
                      situation: "active",
                      isKit: "n",
                    };

                    api
                      .post("/products", productToCreate)
                      .then(() => {
                        setTimeout(
                          () => processImportRows(sheet, rowIndex + 1),
                          0
                        );
                      })
                      .catch((error) => {
                        console.error(error);
                        setMsgError("Erro ao importar produto");
                        setShowModalError(true);
                      });
                  } else {
                    setTimeout(() => processImportRows(sheet, rowIndex + 1), 0);
                  }
                };

                validateSheetRows(1); // Inicia a validação a partir da linha 1
              };

              processSheet(0); // Inicia o processamento da primeira planilha
            });
          } catch (error) {
            console.log(error);
          }
        };
      }
    },
    [productLocation]
  );

  const getSheetModel = useCallback(async () => {
    const workbook = new Workbook();
    const sheet = workbook.addWorksheet("Produtos");
    sheet.columns = [
      { header: "Tipo", key: "type", width: 25 },
      { header: "Nome", key: "name", width: 50 },
      { header: "Unidade", key: "unit", width: 25 },
      { header: "Código GTIN/EAN", key: "codeGtinEan", width: 30 },
      { header: "Valor de Venda", key: "saleValue", width: 25 },
      { header: "Origem do Produto", key: "saleValue", width: 25 },
      { header: "NCM", key: "saleValue", width: 25 },
      { header: "Código CEST", key: "saleValue", width: 25 },
      { header: "ICMS", key: "saleValue", width: 25 },
      { header: "IPI", key: "saleValue", width: 25 },
      { header: "Código do produto", key: "productCode", width: 25 },
      { header: "Valor de custo", key: "productCostValue", width: 25 },
      { header: "Localização no estoque", key: "stockLocation", width: 25 },
      { header: "Estoque mínimo", key: "minStock", width: 25 },
      { header: "Estoque máximo", key: "maxStock", width: 25 },
      { header: "Estoque inicial", key: "initStock", width: 25 },
    ];

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), "Excel-Modelo.xlsx");
  }, []);

  const validateSheet = useCallback(
    (row, rows) => {
      // VERIFICAÇÕES COLUNA DE TIPO

      if (!row[1]) {
        return {
          status: "error",
          message: "Coluna tipo deve ser preenchida!",
        };
      }

      if (
        !["Produto", "Serviço"].includes(
          capitalizeFirstLetter(String(row[1]).toLowerCase())
        )
      ) {
        return {
          status: "error",
          message: "Coluna tipo deve ser Produto ou Serviço!",
        };
      }
      //===================

      // VERIFICAÇÕES COLUNA DE NOME
      if (!row[2]) {
        return {
          status: "error",
          message: "Coluna nome deve ser preenchida!",
        };
      }

      if (row[2].length > 255) {
        return {
          status: "error",
          message: "Coluna nome deve ter no máximo 255 caracteres!",
        };
      }
      //===================

      // VERIFICAÇÕES COLUNA DE UNIDADE
      if (!row[3]) {
        return {
          status: "error",
          message: "Coluna unidade deve ser preenchida!",
        };
      }

      if (
        !["Pç", "Kg", "Un"].includes(
          capitalizeFirstLetter(String(row[3]).toLowerCase())
        )
      ) {
        return {
          status: "error",
          message: "Coluna unidade deve ser Pç, Kg ou Un!",
        };
      }
      //===================

      // VERIFICAÇÕES COLUNA DE VALOR DE VENDA
      if (!row[5]) {
        return {
          status: "error",
          message: "Coluna valor de venda deve ser preenchida!",
        };
      }
      //===================~

      // VERIFICAÇÕES COLUNA DE ORIGEM DO PRODUTO
      if (!row[6] && row[6] != "0") {
        return {
          status: "error",
          message: "Coluna origem do produto deve ser preenchida!",
        };
      }

      if (
        row[6] &&
        !["0", "1", "2", "3", "4", "5", "6", "7", "8"].includes(String(row[6]))
      ) {
        return {
          status: "error",
          message: [
            "Coluna origem do produto deve informar um valor numérico entre 0 e 8 que corresponde a:",
            "0 - Nacional",
            "1 - Estrangeira - Importação direta",
            "2 - Estrangeira - Adquirida no mercado interno",
            "3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%",
            "4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes",
            "5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%",
            "6 - Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX",
            "7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX",
            "8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%",
          ],
        };
      }
      //===================

      // VERIFICAÇÕES COLUNA DE NCM
      if (!row[7]) {
        return {
          status: "error",
          message: "Coluna NCM deve ser preenchida!",
        };
      }
      if (row[7] && /[a-zA-Z]/.test(row[7])) {
        return {
          status: "error",
          message: "Coluna NCM não pode conter letras!",
        };
      }
      if (row[7] && !productHelpers.searchNcm(row[7])) {
        return {
          status: "error",
          message:
            "Coluna NCM possui valor inválido, não contém na lista de códigos de NCM!",
        };
      }
      //===================

      // VERIFICAÇÕES COLUNA DE CEST
      // if (!row[8]) {
      //     return {
      //         status: "error",
      //         message: "Coluna código CEST deve ser preenchida!",
      //     };
      // }
      if (row[8] && /[a-zA-Z]/.test(row[8])) {
        return {
          status: "error",
          message: "Coluna código CEST não pode conter letras!",
        };
      }
      if (row[8] && !productHelpers.searchCest(row[8])) {
        return {
          status: "error",
          message:
            "Coluna código CEST possui valor inválido, não contém na lista de códigos CEST!",
        };
      }
      //===================

      // VERIFICAÇÕES COLUNA DE ICMS
      // if (!row[9]) {
      //     return {
      //         status: "error",
      //         message: "Coluna ICMS deve ser preenchida!",
      //     };
      // }
      if (row[9] && /[a-zA-Z]/.test(row[9])) {
        return {
          status: "error",
          message: "Coluna ICMS não pode conter letras!",
        };
      }
      //===================

      // VERIFICAÇÕES COLUNA DE IPI
      // if (!row[10]) {
      //     return {
      //         status: "error",
      //         message: "Coluna IPI deve ser preenchida!",
      //     };
      // }
      if (row[10] && /[a-zA-Z]/.test(row[10])) {
        return {
          status: "error",
          message: "Coluna IPI não pode conter letras!",
        };
      }
      //===================
      // if (!row[11]) {
      //   return {
      //     status: "error",
      //     message: "Coluna código do produto não pode estar vazia",
      //   };
      // }

      if (!row[12]) {
        return {
          status: "error",
          message: "Coluna valor de custo não pode estar vazia",
        };
      }

      if (!row[13]) {
        return {
          status: "error",
          message: "Coluna localização no estoque não pode estar vazia",
        };
      }

      let stockCheck = productHelpers.searchStockLocation(
        row[13],
        productLocation
      );
      if (!stockCheck) {
        let messArray = [
          "Possíveis valores para o estoque, valor numerico ou nome respeitando letras maiúsculas e minúsculas",
        ];
        let count = productLocation ? productLocation.length : 0;
        for (let i = 0; i < count; i++) {
          // let mess = `${productLocation[i].id} - ${productLocation[i].name}`;
          let mess = row[13];
          messArray.push(mess);
        }

        return {
          status: "error",
          message: messArray,
        };
      }

      // if (!row[14]) {
      //   return {
      //     status: "error",
      //     message: "Coluna estoque mínimo não pode estar vazia",
      //   };
      // }

      if (row[14] && /[a-zA-Z]/.test(row[14])) {
        return {
          status: "error",
          message: "Coluna estoque mínimo não pode conter letras",
        };
      }

      // if (!row[15]) {
      //   return {
      //     status: "error",
      //     message: "Coluna estoque máximo não pode estar vazia",
      //   };
      // }

      if (row[15] && /[a-zA-Z]/.test(row[15])) {
        return {
          status: "error",
          message: "Coluna estoque máximo não pode conter letras",
        };
      }

      if (row[16] !== 0 && !row[16]) {
        return {
          status: "error",
          message: "Coluna estoque inicial não pode estar vazia",
        };
      }

      if (row[16] && /[a-zA-Z]/.test(row[16])) {
        return {
          status: "error",
          message: "Coluna estoque inicial não pode conter letras",
        };
      }

      return {
        status: "success",
        message: "",
      };
    },
    [productLocation]
  );

  return (
    <>
      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
        redirect="/produtos/importar-excel"
      />

      <ModalSuccess
        msgModal={msgModal}
        showModal={showModal}
        setShowModal={setShowModal}
        redirect="/produtos"
      />
      <div className="card card-body pt-4 newProductWrapper">
        <div className="row d-flex flex-column align-items-center mt-5 mb-5">
          <div
            className="col-lg-4 d-flex flex-column justify-content-center align-items-center p-5"
            style={{ border: "2px dashed #eee", background: "#fefefe" }}
          >
            <label htmlFor="formFile">
              <div className="w-100 d-flex justify-content-center">
                {isImporting ? (
                  <Spinner
                    as="span"
                    variant="primary"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <img src="/media/icons/folder.png" className="mr-5" />
                )}
              </div>
              <h6 className="text-center mt-3">
                Importe o arquivo de excel aqui
              </h6>
              <span>{labelProgress}</span>
              <input
                type="file"
                id="formFile"
                accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => readExcel(e.target.files)}
                style={{ display: "none" }}
              />
            </label>
            {/* <Button
              type="button"
              variant="secondary"
              className="mr-2"
              onClick={() => getSheetModel()}
            >
              Baixar Modelo
            </Button> */}
          </div>

          {/* <div className='d-flex justify-content-center col-lg-4 mt-5'>
                        <Button
                            type="button"
                            variant="primary"
                            className="mr-2"
                        >
                            Concluir Importação
                        </Button>
                    </div> */}
        </div>
      </div>
    </>
  );
}
