import { format } from "date-fns";
import { useState } from "react";
import { InstallmentDetails } from "../pages/ServiceOrder/context";
import BankAccountService from "../services/BankAccountService";
import DreCategoryService from "../services/DreCategoryService";
import { SubCategory } from "../types/Dre";
import { ThrowBillInstallment } from "../types/ThrowBillInstallment";
import { formatDate } from "../utils/dateFormat";
import { BankAccount } from "./bankAccount";
import { calcPercentage } from "../utils/calcPercentage";

export type OrderObject = {
  installments?: string;
  sellerId?: string | number;
  sellerHasCommission?: boolean;
  sellerCommissionPercentage?: number;
};

export default function useThrowToBills() {
  const [isThrowToBillsModalOpen, setIsThrowToBillsModalOpen] = useState(false);
  const [dreSubCategoryId, setDreSubCategoryId] = useState(0);
  const [centerCost, setCenterCost] = useState("");
  const [installments, setInstallments] = useState<ThrowBillInstallment[]>([]);
  const [dreSubCategoryIdCommission, setDreSubCategoryIdCommission] = useState(
    0
  );
  const [centerCostCommission, setCenterCostCommission] = useState("");
  const [installmentsCommission, setInstallmentsCommission] = useState<
    ThrowBillInstallment[]
  >([]);
  const [revenue, setRevenue] = useState("");

  const handleSetIsPaid = (index: number) => {
    const aux = installments;

    aux[index].isPaid = !aux[index].isPaid;

    setInstallments([...aux]);
  };

  const handleSetInstallmentBankAccount = (value: any, index: number) => {
    const aux = installments;

    aux[index].bankAccount = value;

    setInstallments([...aux]);
  };

  const handleSetInstallmentPaymentDate = (value: string, index: number) => {
    const aux = installments;

    aux[index].paymentDate = value;

    setInstallments([...aux]);
  };

  const handleSetIsPaidCommission = (index: number) => {
    const aux = installmentsCommission;

    aux[index].isPaid = !aux[index].isPaid;

    setInstallmentsCommission([...aux]);
  };

  const handleSetInstallmentBankAccountCommission = (
    value: any,
    index: number
  ) => {
    const aux = installmentsCommission;

    aux[index].bankAccount = value;

    setInstallmentsCommission([...aux]);
  };

  const handleSetInstallmentPaymentMethodCommission = (
    value: string,
    index: number
  ) => {
    const aux = installmentsCommission;

    aux[index].payment = value;

    setInstallmentsCommission([...aux]);
  };

  const handleSetInstallmentPaymentDateCommission = (
    value: string,
    index: number
  ) => {
    const aux = installmentsCommission;

    aux[index].paymentDate = value;

    setInstallmentsCommission([...aux]);
  };

  const handleSetBillInstallments = async ({
    order,
    typeOrder,
    orderObject,
  }: {
    order?: boolean;
    typeOrder?: string;
    orderObject: OrderObject;
  }) => {
    const installments = orderObject.installments;

    if (!installments) return;

    const defaultPaymentDate = format(Date.now(), "yyyy-MM-dd");

    let defaultBankAccount = "";
    let defaultBankAccountCommission = "";
    let totalSumInstallments = 0;

    if (order) {
      const {
        standardAccountForRecipes,
        standardExpenseAccount,
      } = await BankAccountService.getDefaultBankAccounts();
      defaultBankAccountCommission = String(standardExpenseAccount?.id ?? "");

      if (typeOrder == "request" || typeOrder == "service") {
        defaultBankAccount = String(standardAccountForRecipes?.id ?? "");
      } else if (typeOrder == "purchase") {
        defaultBankAccount = defaultBankAccountCommission;
      }
    }

    const parsedInstallments: InstallmentDetails[] = JSON.parse(installments);

    const mappedInstallments: ThrowBillInstallment[] = parsedInstallments.map(
      (data) => {
        const [year, month, day] = data.date.split("-");
        const date = new Date(Number(year), Number(month) - 1, Number(day));
        const formatedDate = format(date, "dd/MM/yyyy");

        totalSumInstallments += data.value;

        return {
          ...data,
          payment: data.payment,
          paymentDate: data.date || defaultPaymentDate,
          isPaid: false,
          date: formatedDate,
          bankAccount: defaultBankAccount,
        };
      }
    );

    setRevenue("Serviços");
    setInstallments(mappedInstallments);

    if (typeOrder != "purchase") {
      const sellerId = orderObject.sellerId;

      let commissionInstallments: ThrowBillInstallment[] = [];

      if (
        sellerId &&
        orderObject.sellerHasCommission &&
        orderObject.sellerCommissionPercentage
      ) {
        commissionInstallments = parsedInstallments.map((installment) => ({
          paymentDate: installment.date,
          bankAccount: defaultBankAccountCommission,
          comments: "",
          date: formatDate(installment.date),
          value: calcPercentage(
            installment.value,
            orderObject.sellerCommissionPercentage!
          ),
          isPaid: false,
          payment: installment.payment,
        }));

        const [
          dreCategoryForCommission,
        ] = await DreCategoryService.getDreSubCategoriesFiltered(
          { name: "Comissão Vendedores" },
          "expense"
        );

        setDreSubCategoryIdCommission(dreCategoryForCommission?.id ?? 0);
      }

      setInstallmentsCommission(commissionInstallments);
    }
  };

  return {
    isThrowToBillsModalOpen,
    setIsThrowToBillsModalOpen,
    dreSubCategoryId,
    setDreSubCategoryId,
    centerCost,
    setCenterCost,
    installments,
    setInstallments,
    dreSubCategoryIdCommission,
    setDreSubCategoryIdCommission,
    centerCostCommission,
    setCenterCostCommission,
    installmentsCommission,
    setInstallmentsCommission,
    revenue,
    setRevenue,
    handleSetIsPaid,
    handleSetInstallmentBankAccount,
    handleSetInstallmentPaymentMethodCommission,
    handleSetInstallmentPaymentDate,
    handleSetIsPaidCommission,
    handleSetInstallmentBankAccountCommission,
    handleSetInstallmentPaymentDateCommission,
    handleSetBillInstallments,
  };
}
