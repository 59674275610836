// src/components/ToastComponent.tsx

import React from "react";
import { Toast } from "react-bootstrap";

type ToastProps = {
  show: boolean;
  onClose: () => void;
  header: string;
  message: string;
  type: "error" | "warning" | "success";
};

const ToastComponent: React.FC<ToastProps> = ({
  show,
  onClose,
  header,
  message,
  type,
}) => {
  const getVariantClass = () => {
    switch (type) {
      case "error":
        return "bg-danger text-white";
      case "warning":
        return "bg-warning text-dark";
      case "success":
        return "bg-success text-white";
      default:
        return "";
    }
  };

  return (
    <div
      className="p-3"
      style={{ position: "fixed", top: 0, right: 0, zIndex: 1050 }}
    >
      <Toast show={show} onClose={onClose} className={getVariantClass()}>
        <Toast.Header closeButton>
          <strong className="me-auto">{header}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
};

export default ToastComponent;
