const textSuccess = {
  color: "#3ACFC3",
};

const textDanger = {
  color: "#d9534f",
};
const textMuted = {
  color: "#999999",
};

const btnSuccess = {
  background: "#3ACFC3",
  border: "1px solid #3ACFC3",
  color: "#fff",
  ":hover": {
    background: "#1bc5bd",
    border: "1px solid #1bc5bd",
  },
};

const btnWarning = {
  background: "#FFA800",
  border: "1px solid #FFA800",
  color: "#fff",
  ":hover": {
    background: "#1bc5bd",
    border: "1px solid #1bc5bd",
  },
};

const btnNfce = {
  background: "#006400",
  border: "1px solid #006400",
  color: "#fff",
  ":hover": {
    background: "#1bc5bd",
    border: "1px solid #1bc5bd",
  },
};

const colorSuccess = {
  background: "#1bc5bd",
  color: "#fff",
};

const colorBlue = {
  background: "#6993ff",
  color: "#fff",
};

export {
  btnSuccess,
  btnWarning,
  colorSuccess,
  colorBlue,
  textSuccess,
  textDanger,
  textMuted,
  btnNfce,
};
