import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductPromotion } from '../../../types/ProductPromotion';
import api  from "../../../services/Api";
import { SendPromotionForm } from '../../../components/Product/Promotion/SendPromotionForm';
import { Customer } from '../../../types/Customer';

export function SendPromotion() {
    const { id, promotionId } = useParams<{ id: string; promotionId: string }>();

    const [promotion, setPromotion] = useState<ProductPromotion | undefined>(undefined);
    const [customers, setCustomers] = useState<Customer[]>([]);

    useEffect(() => {
      const fetchPromotion = async () => {
           const { data } = await api.get<ProductPromotion>(`product/promotions/${promotionId}`);
          setPromotion(data);
      };
  
      fetchPromotion();
    }, [promotionId]);

    useEffect(() => {
        const fetchCustomers = async () => {
             const { data } = await api.get<Customer[]>('customer',{params: {sortReference: 'name', sortDirection: 'asc'}});
            setCustomers(data);
        };

        fetchCustomers();
      }, [promotion]);
  
    function handleClosePromotionSentSuccessModal() {
      window.location.href = `/produtos/${id}/promocoes`;
    }

   return (
        <SendPromotionForm
            promotion={promotion}
            customers={customers}
            onClosePromotionSentSuccessModal={handleClosePromotionSentSuccessModal}
        />
   );
}