import React, { useEffect, useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import api from "../../services/Api";
import {
  frequency,
  SubscriptionPlan,
  yesOrNo,
} from "../../types/SubscriptionPlan";
import { bypassAuthHeader } from "../../utils/bypassAuthHeader";
import { PlanList } from "./PlanList";
import { GenericObject } from "../../types/GenericObject";

type SubscriptionPlanSelectorProps = {
  selectedSubscriptionPlan?: SubscriptionPlan;
  handleChangeSubscriptionPlan: (
    subscriptionPlan: SubscriptionPlan | null
  ) => void;
  bypassAuth?: boolean;
  selectFirstSubscriptionPlan?: boolean;
  additionalFilters?: GenericObject;
  showNotVisiblePlans?: boolean;
};

export function SubscriptionPlanSelector({
  selectedSubscriptionPlan,
  handleChangeSubscriptionPlan,
  bypassAuth,
  selectFirstSubscriptionPlan = true,
  additionalFilters,
  showNotVisiblePlans,
}: SubscriptionPlanSelectorProps) {
  const [activeTab, setActiveTab] = useState("");
  const [singleSubscriptionPlans, setSingleSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);
  const [daysSubscriptionPlans, setDaysSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);
  const [monthlySubscriptionPlans, setMonthlySubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);
  const [bimonthlySubscriptionPlans, setBimonthlySubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);
  const [
    trimonthlySubscriptionPlans,
    setTrimonthlySubscriptionPlans,
  ] = useState<SubscriptionPlan[]>([]);
  const [quarterlySubscriptionPlans, setQuarterlySubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);
  const [
    semiannualSubscriptionPlans,
    setSemiannualSubscriptionPlans,
  ] = useState<SubscriptionPlan[]>([]);
  const [annualSubscriptionPlans, setAnnualSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);
  const [isLoadingPlans, setIsLoadingPlans] = useState(true);

  useEffect(() => {
    async function loadSubscriptionPlans() {
      setIsLoadingPlans(true);
      // handleChangeSubscriptionPlan(null);

      const { data } = await api.get<SubscriptionPlan[]>("subscriptionPlans", {
        params: {
          filters: {
            visible: showNotVisiblePlans ? undefined : yesOrNo.YES,
            ...additionalFilters,
          },
          sortReference: "value",
          sortDirection: "ASC",
        },
        headers: bypassAuth ? bypassAuthHeader : undefined,
      });

      const rows = data;

      const foundSingleSubscriptionPlans = rows.filter(
        (plan) => plan.paymentFrequency === frequency.NONE
      );
      const foundDaysSubscriptionPlans = rows.filter(
        (plan) => plan.paymentFrequency === frequency.DAYS
      );
      const foundMonthlySubscriptionPlans = rows.filter(
        (plan) => plan.paymentFrequency === frequency.MONTHLY
      );
      const foundBimonthlySubscriptionPlans = rows.filter(
        (plan) => plan.paymentFrequency === frequency.BIMONTHLY
      );
      const foundTrimonthlySubscriptionPlans = rows.filter(
        (plan) => plan.paymentFrequency === frequency.TRIMONTHLY
      );
      const foundQuarterlySubscriptionPlans = rows.filter(
        (plan) => plan.paymentFrequency === frequency.QUARTERLY
      );
      const foundSemiannualSubscriptionPlans = rows.filter(
        (plan) => plan.paymentFrequency === frequency.SEMIANNUAL
      );
      const foundAnnualSubscriptionPlans = rows.filter(
        (plan) => plan.paymentFrequency === frequency.ANNUAL
      );

      if (foundSingleSubscriptionPlans.length > 0) {
        setActiveTab("single");
      } else if (foundDaysSubscriptionPlans.length > 0) {
        setActiveTab("days");
      } else if (foundMonthlySubscriptionPlans.length > 0) {
        setActiveTab("monthly");
      } else if (foundBimonthlySubscriptionPlans.length > 0) {
        setActiveTab("bimonthly");
      } else if (foundTrimonthlySubscriptionPlans.length > 0) {
        setActiveTab("trimonthly");
      } else if (foundQuarterlySubscriptionPlans.length > 0) {
        setActiveTab("quarterly");
      } else if (foundSemiannualSubscriptionPlans.length > 0) {
        setActiveTab("semiannual");
      } else if (foundAnnualSubscriptionPlans.length > 0) {
        setActiveTab("annual");
      }

      setSingleSubscriptionPlans(foundSingleSubscriptionPlans);
      setDaysSubscriptionPlans(foundDaysSubscriptionPlans);
      setMonthlySubscriptionPlans(foundMonthlySubscriptionPlans);
      setBimonthlySubscriptionPlans(foundBimonthlySubscriptionPlans);
      setTrimonthlySubscriptionPlans(foundTrimonthlySubscriptionPlans);
      setQuarterlySubscriptionPlans(foundQuarterlySubscriptionPlans);
      setSemiannualSubscriptionPlans(foundSemiannualSubscriptionPlans);
      setAnnualSubscriptionPlans(foundAnnualSubscriptionPlans);
      setIsLoadingPlans(false);
    }

    loadSubscriptionPlans();
  }, [additionalFilters, showNotVisiblePlans]);

  useEffect(() => {
    if (
      isLoadingPlans ||
      selectedSubscriptionPlan ||
      !selectFirstSubscriptionPlan
    ) {
      return;
    }

    if (singleSubscriptionPlans.length > 0) {
      handleChangeSubscriptionPlan(singleSubscriptionPlans[0]);
    } else if (daysSubscriptionPlans.length > 0) {
      handleChangeSubscriptionPlan(daysSubscriptionPlans[0]);
    } else if (monthlySubscriptionPlans.length > 0) {
      handleChangeSubscriptionPlan(monthlySubscriptionPlans[0]);
    } else if (bimonthlySubscriptionPlans.length > 0) {
      handleChangeSubscriptionPlan(bimonthlySubscriptionPlans[0]);
    } else if (trimonthlySubscriptionPlans.length > 0) {
      handleChangeSubscriptionPlan(trimonthlySubscriptionPlans[0]);
    } else if (quarterlySubscriptionPlans.length > 0) {
      handleChangeSubscriptionPlan(quarterlySubscriptionPlans[0]);
    } else if (semiannualSubscriptionPlans.length > 0) {
      handleChangeSubscriptionPlan(semiannualSubscriptionPlans[0]);
    } else if (annualSubscriptionPlans.length > 0) {
      handleChangeSubscriptionPlan(annualSubscriptionPlans[0]);
    }
  }, [
    selectedSubscriptionPlan,
    singleSubscriptionPlans,
    daysSubscriptionPlans,
    monthlySubscriptionPlans,
    bimonthlySubscriptionPlans,
    trimonthlySubscriptionPlans,
    quarterlySubscriptionPlans,
    semiannualSubscriptionPlans,
    annualSubscriptionPlans,
    isLoadingPlans,
    selectFirstSubscriptionPlan,
  ]);

  if (isLoadingPlans) {
    return (
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Tabs
        activeKey={activeTab}
        onSelect={(tab: string) => setActiveTab(tab)}
        id="subscription-plan-tabs"
        className="d-flex justify-content-center"
      >
        {singleSubscriptionPlans.length > 0 && (
          <Tab eventKey="single" title="Único">
            <PlanList
              subscriptionPlans={singleSubscriptionPlans}
              selectedSubscriptionPlan={selectedSubscriptionPlan}
              handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
            />
          </Tab>
        )}
        {daysSubscriptionPlans.length > 0 && (
          <Tab eventKey="days" title="Dias">
            <PlanList
              subscriptionPlans={daysSubscriptionPlans}
              selectedSubscriptionPlan={selectedSubscriptionPlan}
              handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
            />
          </Tab>
        )}
        {monthlySubscriptionPlans.length > 0 && (
          <Tab eventKey="monthly" title="Mensal">
            <PlanList
              subscriptionPlans={monthlySubscriptionPlans}
              selectedSubscriptionPlan={selectedSubscriptionPlan}
              handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
            />
          </Tab>
        )}
        {bimonthlySubscriptionPlans.length > 0 && (
          <Tab eventKey="bimonthly" title="Bimestral">
            <PlanList
              subscriptionPlans={bimonthlySubscriptionPlans}
              selectedSubscriptionPlan={selectedSubscriptionPlan}
              handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
            />
          </Tab>
        )}
        {trimonthlySubscriptionPlans.length > 0 && (
          <Tab eventKey="trimonthly" title="Trimestral">
            <PlanList
              subscriptionPlans={trimonthlySubscriptionPlans}
              selectedSubscriptionPlan={selectedSubscriptionPlan}
              handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
            />
          </Tab>
        )}
        {quarterlySubscriptionPlans.length > 0 && (
          <Tab eventKey="quarterly" title="Quadrimestral">
            <PlanList
              subscriptionPlans={quarterlySubscriptionPlans}
              selectedSubscriptionPlan={selectedSubscriptionPlan}
              handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
            />
          </Tab>
        )}
        {semiannualSubscriptionPlans.length > 0 && (
          <Tab eventKey="semiannual" title="Semestral">
            <PlanList
              subscriptionPlans={semiannualSubscriptionPlans}
              selectedSubscriptionPlan={selectedSubscriptionPlan}
              handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
            />
          </Tab>
        )}
        {annualSubscriptionPlans.length > 0 && (
          <Tab eventKey="annual" title="Anual">
            <PlanList
              subscriptionPlans={annualSubscriptionPlans}
              selectedSubscriptionPlan={selectedSubscriptionPlan}
              handleChangeSubscriptionPlan={handleChangeSubscriptionPlan}
            />
          </Tab>
        )}
      </Tabs>
    </>
  );
}
