import apiBFF from "../../services/ApiBFF";

export const makeScheduleMessage = async (budget: any, companie: any) => {
  try {
    // console.log("--make schedule--");
    // console.log("budget", budget);
    // console.log("companie", companie);

    function addTwoHoursAndFormat() {
      const now = new Date();
      now.setHours(now.getHours() + 2);
      const hours = String(now.getUTCHours()).padStart(2, "0");
      const minutes = String(now.getUTCMinutes()).padStart(2, "0");
      const seconds = String(now.getUTCSeconds()).padStart(2, "0");
      const milliseconds = String(now.getUTCMilliseconds()).padStart(3, "0");

      return `T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    }

    const medicalAppointmentDate = JSON.parse(
      budget?.opticsData || { medicalAppointmentDate: null }
    )?.medicalAppointmentDate;

    if (medicalAppointmentDate) {
      const dataLoginBFF = await apiBFF.post("millionzap/login", {
        email: companie?.email,
        // passwordHash,
      });
      const { token, user } = dataLoginBFF.data;

      const data = {
        contact: {
          name: budget?.customerEntity?.name,
          number: budget?.customerEntity?.cell?.replace(/\D/g, ""),
        },
        schedule: {
          body: companie?.templateOpticsMessage,
          sendAt: `${medicalAppointmentDate}${addTwoHoursAndFormat()}`,
          sentAt: "",
          userId: user.id,
        },
      };

      await apiBFF.post("millionzap/schedules/create", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
};
