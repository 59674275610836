import { useCallback, useEffect, useState } from "react";
import api from "../services/Api";
import CashFlowService from "../services/CashFlowService";
import { BankAccount } from "../types/BankAccount";
import { formatToFloat } from "../utils/formatCurrency";

type Options = {
  initalBankAccounts?: BankAccount[];
  finalDate?: string;
};

export default function useCurrentBalance(
  company: string | number = "0",
  { initalBankAccounts, finalDate }: Options = {}
) {
  const [totalBalance, setTotalBalance] = useState<number>(0);

  const fillColors = [
    "#E001E0",
    "#5EA2F0",
    "#F00E00",
    "#5E01E0",
    "#8FE000",
    "#B0D3E0",
    "#E06822",
    "#FFEC5C",
    "#009283",
    "#D9B0DF",
    "#379425",
    "#9C95DE",
    "#BDE038",
    "#160BE0",
    "#00EBF7",
    "#00F703",
    "#40D6E0",
    "#E0C722",
    "#E00B8D",
    "#008A94",
    "#5CE041",
    "#E0CD6E",
    "#22E04B",
    "#6C22E0",
    "#10454F",
    "#F2AE30",
    "#FF4858",
    "#72F2EB",
    "#E06017",
    "#FF5A33",
  ];

  useEffect(() => {
    getTotalBalance();
  }, [initalBankAccounts]);

  async function getTotalBalance() {
    var accounts = initalBankAccounts;

    if (!accounts) {
      const accountsResponse =
        company != "0"
          ? await api.post<any[]>(`/accountBank/config/dashboard/${company}`)
          : await api.post<any[]>(`/accountBank/config/dashboard`);
      accounts = accountsResponse.data;
    }

    var bills = await CashFlowService.getBills({
      accountBank: "",
      initialDate: "",
      finalDate: finalDate || "",
      foreseen: false,
      accomplished: true,
      company: String(company || ""),
    });

    const totalAll = await CashFlowService.getTotalAccount(bills, accounts);

    var totalAccountsObj = [];
    var index = 0;
    for (var account of accounts) {
      var totals = await CashFlowService.getTotalAccount(
        bills,
        accounts,
        String(account.id)
      );

      const totalBankAccount =
        formatToFloat(totals.openingBalance) + totals.totalReceive;

      if (totalBankAccount - totals.totalPay > 0 || accounts.length === 1) {
        totalAccountsObj.push({
          name: account.name,
          value: totalBankAccount - totals.totalPay,
          cash: account.cashierMoney,
          fill: account.color ? "#" + account.color : fillColors[index],
        });
      }

      index++;
    }

    setTotalBalance(totalAll.totalAccount);

    return {
      objects: totalAccountsObj,
      totalBalance: parseFloat(totalAll.totalAccount.toFixed(2)),
    };
  }

  const getAccountBalance = useCallback(
    async (idAccount: string | number) => {
      const accountBanks = initalBankAccounts
        ? initalBankAccounts
        : (await api.get("/accountsBank")).data;
      var bills = await CashFlowService.getBills({
        accountBank: String(idAccount),
        initialDate: "",
        finalDate: finalDate || "",
        foreseen: false,
        accomplished: true,
      });
      const totals = CashFlowService.getTotalAccount(
        bills,
        accountBanks,
        String(idAccount)
      );

      return totals.totalAccount;
    },
    [initalBankAccounts]
  );

  return { totalBalance, getTotalBalance, getAccountBalance };
}
