import React from "react";
import { Route, Switch } from "react-router-dom";
import { ListHelp } from "../pages/Help/ListHelp";

export function Help() {
  return (
    <Switch>
      <Route path="/ajuda" component={ListHelp} />
    </Switch>
  );
}
