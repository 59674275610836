import api from "../services/Api";

type ncmNomenclature = {
  Codigo: string;
  Descricao: string;
  Data_Inicio: string;
  Data_Fim: string;
  Tipo_Ato: string;
  Numero_Ato: string;
  Ano_Ato: string;
};

type ncm = {
  code: string;
  description: string;
};

type cestCodeFetch = {
  numeroAnexo: string;
  descAnexo: string;
  item: string;
  cest: string;
  ncms: string[];
  descricao: string;
};

type cestCode = {
  code: string;
  description: string;
};

const getNcmList = () => {
  var json = require("../../json/ncm-list.json");
  var nomenclatures: ncmNomenclature[] = json.Nomenclaturas;

  /**
   * ncms de 8 digitos
   */
  // var nomenclaturesComplete = nomenclatures.filter((obj) => obj.Codigo.replaceAll('.', '').length === 8);

  var ncm: ncm[] = [];
  nomenclatures.map((obj, index) => {
    if (obj.Codigo.replaceAll(".", "").length !== 8) {
      return;
    }
    var description: string = "";
    var objCode = obj.Codigo.replaceAll(".", "");
    var smallArray = nomenclatures.slice(
      index - 200 >= 0 ? index - 200 : 0,
      index
    );

    var partialNomenclatures = smallArray.filter((obj2) => {
      var obj2Code = obj2.Codigo.replaceAll(".", "");
      return (
        obj2Code.replaceAll(".", "") ===
          objCode.replaceAll(".", "").substring(0, 2) ||
        obj2Code.replaceAll(".", "") ===
          objCode.replaceAll(".", "").substring(0, 3) ||
        obj2Code.replaceAll(".", "") ===
          objCode.replaceAll(".", "").substring(0, 4) ||
        obj2Code.replaceAll(".", "") ===
          objCode.replaceAll(".", "").substring(0, 5) ||
        obj2Code.replaceAll(".", "") ===
          objCode.replaceAll(".", "").substring(0, 6) ||
        obj2Code.replaceAll(".", "") ===
          objCode.replaceAll(".", "").substring(0, 7)
      );
    });
    partialNomenclatures.map((obj3) => {
      description += " " + obj3.Descricao;
    });
    description += " " + obj.Descricao;
    ncm.push({
      code: obj.Codigo,
      description: description,
    });
  });

  return ncm;
};

const searchNcm = (code: string) => {
  const ncm = getNcmList();

  const isSearched = ncm.find((value: any) => {
    let formated_value = value.code.replaceAll(".", "");
    if (value.code == code || formated_value == code) {
      return true;
    }
  });

  return isSearched;
};

const getCestCodeList = () => {
  var json = require("../../json/cest-list.json");
  var cestFetch: cestCodeFetch[] = json;

  var cestCode: cestCode[] = [];
  cestFetch.map((obj, index) => {
    cestCode.push({
      code: obj.cest,
      description: obj.descAnexo + " - " + obj.descricao,
    });
  });

  return cestCode;
};

const searchCest = (code: string) => {
  const cest = getCestCodeList();

  const isSearched = cest.find((value) => {
    let formated_value = value.code.replaceAll(".", "");
    if (value.code == code || formated_value == code) {
      return true;
    }
  });

  return isSearched;
};

const getCest = (code: string) => {
  const cest = getCestCodeList();

  const cestValue = cest.find((value) => {
    let formated_value = value.code.replaceAll(".", "");
    if (value.code == code || formated_value == code) {
      return true;
    }
  });

  return cestValue?.code;
};

const getNcm = (code: string) => {
  const ncm = getNcmList();

  const ncmValue = ncm.find((value: any) => {
    let formated_value = value.code.replaceAll(".", "");
    if (value.code == code || formated_value == code) {
      return true;
    }
  });

  return ncmValue?.code;
};

const searchStockLocation = (search: string, locationList: any) => {
  let count = locationList.length;
  let id = 0;
  for (let i = 0; i < count; i++) {
    if (search == locationList[i].id || search == locationList[i].name) {
      id = locationList[i].id;
    }
  }

  return id;
};

const allStockValues = async () => {
  const stockValues = await api.get(`allCompanyStockLocations`);
  return stockValues.data;
};

export default {
  getNcmList,
  searchNcm,
  getNcm,

  getCestCodeList,
  searchCest,
  getCest,

  searchStockLocation,
  allStockValues,
};
