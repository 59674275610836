import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProductPromotion } from '../../../types/ProductPromotion';
import { EditPromotionForm } from '../../../components/Product/Promotion/EditPromotionForm';
import api  from "../../../services/Api";

export function EditPromotion() {
  const { id, promotionId } = useParams<{ id: string; promotionId: string }>();
  const [promotion, setPromotion] = useState<ProductPromotion | undefined>(undefined);

  useEffect(() => {
    const fetchPromotion = async () => {
         const { data } = await api.get<ProductPromotion>(`product/promotions/${promotionId}`);
        setPromotion(data);
    };

    fetchPromotion();
  }, [promotionId]);

  function handleClosePromotionUpdatedSuccessModal() {
    window.location.href = `/produtos/${id}/promocoes`;
  }

  return (
    <EditPromotionForm
      promotion={promotion}
      onClosePromotionUpdatedSuccessModal={handleClosePromotionUpdatedSuccessModal}
    />
  );
}