import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import api from "../../services/Api";
import { useFormik } from "formik";
// import * as Yup from "yup";
import { Spinner } from "react-bootstrap";
import ModalSuccess from "../../components/ModalSuccess";
import ModalError from "../../components/ModalError";
import ReactInputMask from "react-input-mask";
import { cnpjSearch } from "../../utils/cnpjSearch";
import { validateCpfCnpj } from "../../utils/validateCpfCnpj";
import BusinessCategoryList from "../Company/Components/BusinessCategoryList";
import { Validators } from "../../utils/validators";

export function FreeTrialPage() {
  const [contactName, setContactName] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [cell, setCell] = useState<string>("");
  const [cnpj, setCnpj] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [businessCategory, setBusinessCategory] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [showModalError, setShowModalError] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<string>("");
  const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
  const [companySegmentInvalid, setCompanySegmentInvalid] = useState<boolean>(
    false
  );
  const [nameInvalid, setNameInvalid] = useState<boolean>(false);
  const [contactNameInvalid, setContactNameInvalid] = useState<boolean>(false);
  const [cellInvalid, setCellInvalid] = useState<boolean>(false);
  const [cnpjInvalid, setCnpjInvalid] = useState<boolean>(false);
  const [passwordInvalid, setPasswordInvalid] = useState<boolean>(false);

  function inputsVerify() {
    setLoading(true);
    setEmailInvalid(false);
    setNameInvalid(false);
    setContactNameInvalid(false);
    setCellInvalid(false);
    setPasswordInvalid(false);
    setCompanySegmentInvalid(false);

    if (!email || !Validators.validateEmail(email)) {
      setMsgError("Preencha o e-mail corretamente");
      setShowModalError(true);
      setEmailInvalid(true);
      setLoading(false);

      return false;
    }

    if (businessCategory === "") {
      setMsgError("Escolha o segmento da empresa");
      setShowModalError(true);
      setCompanySegmentInvalid(true);
      setLoading(false);

      return false;
    }

    if (!name) {
      setMsgError("Preencha o nome de usuário corretamente");
      setShowModalError(true);
      setNameInvalid(true);
      setLoading(false);

      return false;
    }

    if (!contactName) {
      setMsgError("Preencha o nome do contato corretamente");
      setShowModalError(true);
      setContactNameInvalid(true);
      setLoading(false);

      return false;
    }

    if (!cell || !Validators.validateCellPhoneNumber(cell)) {
      setMsgError("Preencha o telefone corretamente");
      setShowModalError(true);
      setCellInvalid(true);
      setLoading(false);

      return false;
    }

    if (!cnpj) {
      setMsgError("Preencha o CNPJ corretamente");
      setShowModalError(true);
      setCnpjInvalid(true);
      setLoading(false);

      return false;
    }

    if (!password) {
      setMsgError("Preencha a senha corretamente");
      setShowModalError(true);
      setPasswordInvalid(true);
      setLoading(false);

      return false;
    }

    return true;
  }

  async function searchCnpj(cnpj: string) {
    setLoading(true);

    const searchResponse = await cnpjSearch(cnpj);

    if (searchResponse.status === "ERROR") {
      setMsgError(searchResponse.message);
      setShowModalError(true);
      setCnpjInvalid(true);
      setLoading(false);

      return;
    }

    setLoading(false);

    setName(searchResponse.nome);
  }

  async function handleSubmit(evt: any) {
    evt.preventDefault();

    setLoading(true);

    if (!inputsVerify()) return;

    if (!validateCpfCnpj(cnpj)) {
      setMsgError("CNPJ inválido");
      setShowModalError(true);
      setCnpjInvalid(true);
      setLoading(false);

      return;
    }

    const formattedCnpj = cnpj.replace(/[^0-9]/g, "");

    try {
      const raw = {
        name,
        cell,
        email,
        password,
        cnpj: formattedCnpj,
        corporateName: name,
        businessCategory,
        responsible: contactName,
      };

      const response = await api.post("/companies/free", raw);

      const buttonClickEvent = {
        event: "buttonClick",
        button: {
          id: "pageTesteGratis:buttonIniciarTesteGratis",
          label: "Iniciar teste gratuito",
        },
      };

      window.dataLayer.push(buttonClickEvent);

      setLoading(false);
      setShowModalSuccess(true);
    } catch (error) {
      setLoading(false);
      setMsgError(
        error.response.data.message ?? "Erro ao criar conta de teste gratuito"
      );
      setShowModalError(true);
    }
  }

  useEffect(() => {
    const pageLoadEvent = {
      event: "pageView",
      page: {
        path: window.location.pathname,
        title: document.title,
      },
    };

    window.dataLayer.push(pageLoadEvent);
  }, []);

  return (
    <section className="d-flex align-items-center justify-content-center py-5 bg-gray-200 min-vh-100">
      <ModalSuccess
        msgModal="Conta para teste gratuito criada com sucesso!"
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
        redirect="/auth/login"
      />

      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div
              className="shadow-sm p-6 overflow-hidden d-flex bg-white rounded animate__animated animate__fadeInDown"
              style={{ gap: "3rem" }}
            >
              <form
                onSubmit={handleSubmit}
                className="p-3"
                action=""
                style={{ flex: "1 1 0" }}
              >
                <h4 className="mb-6 text-secondary">
                  Comece seu teste gratuito!
                </h4>
                <div className="form-group">
                  <label
                    className={
                      "d-block font-weight-bold " +
                      (emailInvalid ? "text-danger" : "")
                    }
                    htmlFor="email"
                  >
                    E-mail*
                  </label>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value.toLowerCase());
                      setEmailInvalid(e.target.value ? false : true);
                    }}
                    value={email}
                    name="email"
                    id="email"
                    className={
                      "form-control " + (emailInvalid ? "border-danger" : "")
                    }
                    type="email"
                  />
                </div>
                <div className="form-group">
                  <label
                    className={
                      "d-block font-weight-bold " +
                      (cnpjInvalid ? "text-danger" : "")
                    }
                    htmlFor="cnpj"
                  >
                    CNPJ*
                  </label>
                  <ReactInputMask
                    mask="99.999.999/9999-99"
                    onChange={(e) => {
                      setCnpj(e.target.value);
                      setCnpjInvalid(e.target.value ? false : true);
                    }}
                    value={cnpj}
                    name="cnpj"
                    id="cnpj"
                    className={
                      "form-control " + (cnpjInvalid ? "border-danger" : "")
                    }
                    type="text"
                    onBlur={(e) => searchCnpj(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label
                    className={
                      "d-block font-weight-bold " +
                      (companySegmentInvalid ? "text-danger" : "")
                    }
                    htmlFor="segment"
                    style={{}}
                  >
                    Segmento da empresa*
                  </label>
                  <BusinessCategoryList
                    style={{ marginTop: "0px" }}
                    setBusinessCategory={setBusinessCategory}
                    businessCategory={businessCategory}
                    error={companySegmentInvalid}
                    showLabel={false}
                  />
                </div>
                <div className="form-group">
                  <label
                    className={
                      "d-block font-weight-bold " +
                      (nameInvalid ? "text-danger" : "")
                    }
                    htmlFor="name"
                  >
                    Nome*
                  </label>
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameInvalid(e.target.value ? false : true);
                    }}
                    value={name}
                    name="name"
                    id="name"
                    className={
                      "form-control " + (nameInvalid ? "border-danger" : "")
                    }
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label
                    className={
                      "d-block font-weight-bold " +
                      (contactNameInvalid ? "text-danger" : "")
                    }
                    htmlFor="name"
                  >
                    Contato*
                  </label>
                  <input
                    onChange={(e) => {
                      setContactName(e.target.value);
                      setContactNameInvalid(e.target.value ? false : true);
                    }}
                    value={contactName}
                    name="contactName"
                    id="contactNameId"
                    className={
                      "form-control " +
                      (contactNameInvalid ? "border-danger" : "")
                    }
                    type="text"
                  />
                </div>
                <div className="form-group">
                  <label
                    className={
                      "d-block font-weight-bold " +
                      (cellInvalid ? "text-danger" : "")
                    }
                    htmlFor="cell"
                  >
                    Celular*
                  </label>
                  <ReactInputMask
                    mask="(99) 99999-9999"
                    onChange={(e) => {
                      setCell(e.target.value);
                      setCellInvalid(e.target.value ? false : true);
                    }}
                    value={cell}
                    name="cell"
                    id="cell"
                    className={
                      "form-control " + (cellInvalid ? "border-danger" : "")
                    }
                    onBlur={(e) => {
                      setCellInvalid(
                        !Validators.validateCellPhoneNumber(e.target.value)
                      );
                    }}
                    type="text"
                  />
                </div>
                <div className="form-group d-flex align-items-end">
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => searchCnpj(cnpj)}
                  >
                    <i className="flaticon-search p-0"></i>
                  </button> */}
                </div>
                <div className="form-group">
                  <label
                    className={
                      "d-block font-weight-bold " +
                      (passwordInvalid ? "text-danger" : "")
                    }
                    htmlFor="password"
                  >
                    Senha*
                  </label>
                  <input
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordInvalid(e.target.value ? false : true);
                    }}
                    value={password}
                    name="password"
                    id="password"
                    className={
                      "form-control " + (passwordInvalid ? "border-danger" : "")
                    }
                    type="password"
                  />
                </div>

                <button
                  type="submit"
                  className="btn btn-success w-100 font-weight-bold"
                  disabled={loading}
                >
                  {!loading ? (
                    <>
                      <span>Iniciar teste gratuito</span>
                    </>
                  ) : (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        size="sm"
                        aria-hidden="true"
                      />
                      <span className="ml-2">Aguarde...</span>
                    </>
                  )}
                </button>
              </form>
              <aside
                className="rounded-2 d-xl-flex d-lg-flex d-md-flex d-sm-flex flex-column align-items-center px-3 py-10 bg-secondary d-none"
                style={{ gap: "3.5rem", flex: "1 1 0" }}
              >
                <img
                  src={toAbsoluteUrl(
                    "/media/logos/header/logo-header-completo-gold.png"
                  )}
                  alt=""
                  width="210"
                  height="130"
                />

                <div className="px-lg-10 px-md-10 px-sm-2 text-white">
                  <h2 className="mb-6">
                    Utilize o Milliontech por 3 dias, gratuitamente!
                  </h2>
                  <p style={{ fontSize: "16px" }}>
                    <i className="flaticon2-check-mark text-primary mr-1"></i>{" "}
                    Tenha controle sobre seus negócios{" "}
                  </p>
                  <p style={{ fontSize: "16px" }}>
                    <i className="flaticon2-check-mark text-primary mr-1"></i>{" "}
                    Relatórios sobre estoque, vendas, financeiro, serviços e
                    muito mais
                  </p>
                  <p style={{ fontSize: "16px" }}>
                    <i className="flaticon2-check-mark text-primary mr-1"></i>{" "}
                    Acesso a um dashboard com gráficos precisos sobre seus
                    resultados
                  </p>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
