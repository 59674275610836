import { Grid, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export type Field = {
    name?: string,
    type?: string,
    size?: number,
    rows?: number,
    coluns?: number,
    required?: boolean
}

type Props = {
    addField: (index: number) => void,
    groupIndex: number,
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    nameField: string,
    setNameField: React.Dispatch<React.SetStateAction<string>>,
    typeField: string,
    setTypeField: React.Dispatch<React.SetStateAction<string>>,
    sizeField: number,
    setSizeField: React.Dispatch<React.SetStateAction<number>>,
    rowsField: number,
    setRowsField: React.Dispatch<React.SetStateAction<number>>,
    colunsField: number,
    setColunsField: React.Dispatch<React.SetStateAction<number>>,
    requiredField: string,
    setRequiredField: React.Dispatch<React.SetStateAction<string>>,
}

export function ModalField ({
    addField,
    groupIndex,
    showModal,
    setShowModal,
    nameField,
    setNameField,
    typeField,
    setTypeField,
    sizeField,
    setSizeField,
    rowsField,
    setRowsField,
    colunsField,
    setColunsField,
    requiredField,
    setRequiredField,
}: Props) {
    return (
        <Modal
            size='lg'
            show={showModal}
            onHide={() => setShowModal(false)}
            aria-labelledby="contained-modal-warning"
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title className="d-flex align-items-center">
                  Criação de Campo
               </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Grid container spacing={3}>
                    <Grid item lg={6}>
                        <TextField
                            type="string"
                            label="Nome do Campo"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={ nameField}
                            onChange={(e) => {
                                setNameField(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item lg={6}>
                        <TextField
                            select
                            label="Tipo"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            value={ typeField}
                            onChange={(e) => {
                                setTypeField(e.target.value);
                            }}
                        >
                            <MenuItem key="0" value="Texto">
                                Texto
                            </MenuItem>

                            <MenuItem key="1" value="Número Inteiro">
                                Número Inteiro
                            </MenuItem>

                            <MenuItem key="2" value="Número Decimal">
                                Número Decimal
                            </MenuItem>

                            <MenuItem key="3" value="Opções">
                                Opções
                            </MenuItem>

                            <MenuItem key="4" value="Máscara">
                                Máscara
                            </MenuItem>

                            <MenuItem key="5" value="Data">
                                Data
                            </MenuItem>

                            <MenuItem key="6" value="Anexo">
                                Anexo
                            </MenuItem>

                            <MenuItem key="7" value="Caixa de Verificação">
                                Caixa de Verificação
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={3}>
                    <Grid item lg={6}>
                        <TextField
                            type="string"
                            label="Máscara"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={ fieldToEdit}
                            onChange={(e) => {
                                if (!fieldToEdit) {
                                    setNameField(e.target.value);
                                }

                                setFieldToEdit({
                                    ...fieldToEdit,
                                    fieldToEdit: e.target.value
                                });
                            }}
                        />   
                    </Grid>
                </Grid> */}
                <Grid container spacing={3}>
                    <Grid item lg={3}>
                        <TextField
                            type="number"
                            label="Tamanho"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={ sizeField}
                            onChange={(e) => {
                                setSizeField(Number(e.target.value));
                            }}
                        />
                    </Grid>
                    <Grid item lg={3}>
                        <TextField
                            type="number"
                            label="Linhas"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={ rowsField}
                            onChange={(e) => {
                                setRowsField(Number(e.target.value));
                            }}
                        />
                    </Grid>
                    <Grid item lg={3}>
                        <TextField
                            type="number"
                            label="Colunas"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={ colunsField}
                            onChange={(e) => {
                                setColunsField(Number(e.target.value));
                            }}
                        />
                    </Grid>
                    <Grid item lg={3}>
                        <TextField
                            select
                            label="Obrigatório"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            value={ requiredField}
                            onChange={(e) => {
                                setRequiredField(e.target.value);
                            }}
                        >
                            <MenuItem key="0" value="y">
                                Sim
                            </MenuItem>

                            <MenuItem key="1" value="n">
                                Não
                            </MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => setShowModal(!showModal)}>Fechar</Button>
               <Button variant="primary" onClick={() => addField(groupIndex)}>Adicionar</Button>
            </Modal.Footer>
         </Modal>
    );
}