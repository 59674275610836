import { Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { Badge, Button, Col, Nav, Row, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Field, ModalField } from './ModalField';


export function NewModelForm() {

    const { register, watch, handleSubmit } = useForm();
    
    const [name, setName] = useState<string>("");
    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const [showModalField, setShowModalField] = useState(false);
    
    const [groupIndex, setGroupIndex] = useState(0);
    const [fieldToEdit, setFieldToEdit] = useState<Field>(); 
    const [nameField, setNameField] = useState<string>("");
    const [typeField, setTypeField] = useState<string>("Texto");
    const [sizeField, setSizeField] = useState<number>(50);
    const [rowsField, setRowsField] = useState<number>(1);
    const [colunsField, setColunsField] = useState<number>(3);
    const [requiredField, setRequiredField] = useState<string>("y");
    
    const [groups, setGroups] = useState({ group: [
        {
            name: "",
            index: 0,
            inputs: [
                {
                    name: "",
                    type: "",
                    size: 0,
                    rows: 0,
                    coluns: 0,
                    required: false
                }
            ],
            tables: []
        }
    ]});

    const addGroup = useCallback(() => {
        var groupArr = groups.group;
        var lastIndex = groups.group.length ? groups.group.length : 0;
        groupArr.push({
            name: "",
            index: lastIndex,
            inputs: [],
            tables: []
        });
        setGroups({group: groupArr});
    }, []);

    const deleteGroup = useCallback((index) => {
        var groupArr = groups.group;
        groupArr.splice(index, 1);
        setGroups({ group: groupArr });
    }, []);

    const changeNameGroup = useCallback((index: number, value: string) => {
        var groupArr = groups.group;
        groupArr[index].name = value;
        setGroups({ group: groupArr });
    }, []);

    const changeIndexGroup = useCallback((index: number, direction: "down" | "up") => {
        if (!(index == 0 && direction == "up") && !(groups.group.length == (index + 1) && direction == "down")) {
            var groupArr = groups.group;
            if (direction == "up") {
                var position = index - 1;
                var group    = groupArr[index];
        
                for (var i = index; i >= position; i--) {
                    groupArr[i] = groupArr[i - 1];
                }
                groupArr[position] = group; 
                
                setGroups({ group: groupArr });
            } else {
                var position = index + 1;
                var group    = groupArr[index];
        
                for (var i = index; i <= position; i++) {
                    groupArr[i] = groupArr[i + 1];
                }
                groupArr[position] = group; 
                
                setGroups({ group: groupArr });
            }
        }
    }, []);

    const addField = useCallback((index: number) => {
        var groupArr = groups.group;
        groupArr[index].inputs.push({
            name: nameField,
            type: typeField,
            size: sizeField,
            rows: rowsField,
            coluns: colunsField,
            required: requiredField == "y" ? true : false
        });

        setNameField("");
        setTypeField("Texto");
        setSizeField(50);
        setRowsField(1);
        setColunsField(3);
        setRequiredField("y");
        setShowModalField(false);
    }, [nameField, typeField, sizeField, rowsField, colunsField, requiredField]);

    const editField = useCallback((field: Field) => {
        setFieldToEdit(field);
        setShowModalField(true);
    }, []);
    
    async function onSubmit() {
        console.log("SALVOU!");
    }
    
    return (
        <>
            <ModalField
                addField={addField}
                groupIndex={groupIndex}
                showModal={showModalField}
                setShowModal={setShowModalField}
                nameField={nameField}
                setNameField={setNameField}
                typeField={typeField}
                setTypeField={setTypeField}
                sizeField={sizeField}
                setSizeField={setSizeField}
                rowsField={rowsField}
                setRowsField={setRowsField}
                colunsField={colunsField}
                setColunsField={setColunsField}
                requiredField={requiredField}
                setRequiredField={setRequiredField}
            />
        
            <form  onSubmit={handleSubmit(onSubmit)} className="card card-body pt-4">
                <Grid container spacing={3}>
                    <Grid item lg={4}>
                        <TextField
                            type="string"
                            label="Nome do Formulário"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Grid>
                    <Grid item lg={8}>
                        <div className="h-100 d-flex justify-content-end align-items-end">
                            <Button
                                type="button"
                                variant="secondary"
                                className="mb-2 mr-3"
                                onClick={() => addGroup()}
                            >
                                <i className="flaticon2-plus"></i>
                                <span>
                                    Grupo
                                </span>
                            </Button>
                            <Button
                                type="button"
                                variant="primary"
                                disabled={isSubmit}
                                className="mb-2"
                            >
                                {isSubmit ? <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />

                                    <span className="ml-2">
                                        Aguarde...
                                    </span>
                                </> : <>
                                    <span>
                                        Salvar
                                    </span>
                                </>}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                <hr />
                {
                    groups.group.map((group, index) => {
                        return (
                            <>
                                <Grid key={index} container spacing={3}>
                                    <Grid item lg={4} className="d-flex flex-row align-items-center">
                                            {
                                                groups.group.length > 1
                                                ?
                                                <>
                                                    <Tooltip title="Mover para cima" className={index == 0 ? 'invisible' : ''}>
                                                        <IconButton
                                                            onClick={() => changeIndexGroup(index, "up")}
                                                        >
                                                            <i className='flaticon2-arrow-up'></i>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Mover para baixo" className={(index + 1) == groups.group.length ? 'invisible' : ''}>
                                                        <IconButton
                                                            onClick={() => changeIndexGroup(index, "down")}
                                                        >
                                                            <i className='flaticon2-arrow-down'></i>
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                                : <></>
                                            }
                                            <TextField
                                                type="string"
                                                label="Nome do Grupo"
                                                size="small"
                                                margin="normal"
                                                variant="outlined"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                className={ groups.group.length > 1 ? 'ml-3' : ''}
                                                value={group.name}
                                                onChange={(e) => changeNameGroup(index, e.target.value)}
                                            />
                                    </Grid>

                                    <Grid item lg={8} className="d-flex flex-row align-items-center justify-content-end">
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            className="mt-3 mr-3"
                                            onClick={() => {
                                                setGroupIndex(index);
                                                setShowModalField(true);
                                            }}
                                        >
                                            <i className="flaticon2-plus"></i>
                                            <span>
                                                Campo
                                            </span>
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="secondary"
                                            className="mt-3 mr-3"
                                        >
                                            <i className="flaticon2-plus"></i>
                                            <span>
                                                Tabela
                                            </span>
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="danger"
                                            className="mt-3"
                                            onClick={() => deleteGroup(index)}
                                        >
                                            <i className="flaticon2-trash p-0"></i>
                                        </Button>
                                    </Grid>
                                    
                                </Grid>
                                
                                <div key={`field-${index}`} className='row'>
                                        {
                                            group.inputs.map((field) => {
                                                if (field.name) {
                                                    return (
                                                        <div className={`col-lg-${field.coluns}`}>
                                                            <Tooltip title="Editar Campo">
                                                                <Button
                                                                    type="button"
                                                                    variant="light"
                                                                    onClick={() => editField(field)}
                                                                    style={{ border: "1px solid #ccc", borderRadius: "5px", width: "100%", textAlign: "left"}}
                                                                >
                                                                    <Badge variant="secondary" className="m-1">
                                                                        Campo: {field.name}
                                                                    </Badge>
                                                                    <br />
                                                                    <Badge variant="secondary" className="m-1">
                                                                        Tipo: {field.type}
                                                                    </Badge>
                                                                    <Badge variant="primary" className="m-1">
                                                                        {field.required ? "Obrigatório" : "Opcional"}
                                                                    </Badge>
                                                                </Button>                                                                
                                                            </Tooltip>
                                                            <div>
                                                                <Tooltip title="Mover para direita">
                                                                    <IconButton
                                                                        // onClick={() => changeIndexGroup(index, "up")}
                                                                        className="p-1"
                                                                    >
                                                                        <i className='flaticon2-left-arrow'></i>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Mover para esquerda">
                                                                    <IconButton
                                                                        // onClick={() => changeIndexGroup(index, "down")}
                                                                        className="p-1"
                                                                    >
                                                                        <i className='flaticon2-right-arrow'></i>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })
                                        }
                                </div>
                            </>
                        )
                    })
                }
            </form>
        </>
    );
}