export class Validators {
  static validateCellPhoneNumber(number: string): boolean {
    const cleanedNumber = number.replace(/\D/g, "");
    console.log(cleanedNumber.length >= 10);
    return cleanedNumber.length >= 11;
  }

  static validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.toLowerCase());
  }
}
