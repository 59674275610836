import React from "react";
import { TextField, MenuItem, InputAdornment } from "@material-ui/core";

import { parseNumber } from "../../utils/parseNumber";
import { useRequest, InstallmentDetails } from "./context";
import { paymentOptions } from "../../utils/paymentOptions";
import { NumericFormat } from "../../components/NumericFormat";
import "../../style.css";
import { formatToFloat } from "../../utils/formatCurrency";
import { useSelector } from "react-redux";

interface InstallmentProps {
  index: number;
  installmentAmount: number;
  installment: InstallmentDetails;
  status?: string;
}

export const Installment: React.FC<InstallmentProps> = ({
  index,
  installment,
  installmentAmount,
  status,
}) => {
  const { user } = useSelector((state: any) => state.auth);
  const { classes, installments, dispatchInstallments } = useRequest();

  function handleInstallmentDateChange(date: string) {
    dispatchInstallments({
      type: "CHANGE_DATE",
      payload: {
        index,
        date,
        installment: {
          ...installment,
          value: parseFloat(installment?.value?.toFixed(2)),
        },
      },
    });
  }

  return (
    <div className="row col-lg-12 ml-2 newProductWrapper">
      <div className="col-lg-3 d-flex align-items-center p-1">
        <div className="d-flex justify-content-center align-items-center bg-primary p-4 mt-2">
          <label className="text-white m-0">{index + 1}.</label>
        </div>

        <TextField
          type="date"
          label="Data"
          margin="normal"
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          value={installment.date}
          onChange={(event) => handleInstallmentDateChange(event.target.value)}
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
        />
      </div>

      <div className="col p-1">
        {index + 1 !== installments.length ? (
          <NumericFormat
            key={index}
            label="Valor"
            value={installment.value}
            startAdornment="R$"
            onChange={({ target: { value } }) =>
              dispatchInstallments({
                type: "CHANGE_VALUE",
                payload: {
                  index,
                  value: formatToFloat(value),
                  amount: installmentAmount,
                  installment: {
                    ...installment,
                    value: formatToFloat(value),
                  },
                },
              })
            }
            disabled={
              status == "attended" || user.isAccountant == "y" ? true : false
            }
          />
        ) : (
          <NumericFormat
            disabled
            key={index}
            label="Valor"
            value={installment.value}
            startAdornment="R$"
          />
        )}
      </div>

      <div className="col p-1">
        <TextField
          select
          label="Forma de pgto"
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
          }}
          margin="normal"
          variant="outlined"
          value={installment.payment}
          size="small"
          onChange={(evt) =>
            dispatchInstallments({
              type: "CHANGE",
              payload: {
                index,
                installment: {
                  ...installment,
                  payment: evt.target.value,
                },
              },
            })
          }
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
        >
          <MenuItem key={-1} value="">
            Selecione
          </MenuItem>
          {paymentOptions.map((payment, i) => {
            return (
              <MenuItem key={i} value={payment.value}>
                {payment.value}
              </MenuItem>
            );
          })}
        </TextField>
      </div>

      <div className="col p-1">
        <TextField
          label="Observações"
          margin="normal"
          variant="outlined"
          value={installment.comments}
          size="small"
          onChange={(evt) =>
            dispatchInstallments({
              type: "CHANGE",
              payload: {
                index,
                installment: {
                  ...installment,
                  comments: evt.target.value,
                },
              },
            })
          }
          disabled={
            status == "attended" || user.isAccountant == "y" ? true : false
          }
        />
      </div>
    </div>
  );
};
