import React from 'react';

export function ListSolicitationProcess() {
    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <h1>Solicitação de Processos</h1>
            </div>
        </div>
    );
}