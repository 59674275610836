import React, { useState, useEffect, useRef } from "react";

import { useSubheader } from "../../../_metronic/layout";

import {
  makeStyles,
  TextField,
  Checkbox,
  MenuItem,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";

import { Button, Spinner, Tabs, Tab } from "react-bootstrap";

import ModalError from "../../components/ModalError";
import useMenu from "../../hooks/menu";
import api from "../../services/Api";
import { api as ApiUrl } from "../../services/ApiURL";
import { useHistory, useParams } from "react-router";
import { NumericFormat } from "../../components/NumericFormat";
import { Profile } from "../../types/Profile";
import { Autocomplete } from "@material-ui/lab";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { PartnersObj } from "../../types/Partners";
import PermissionMenuSelector from "../../components/PermissionMenuSelector";
import { SubscriptionPlan, yesOrNo } from "../../types/SubscriptionPlan";
import { formatToFloat } from "../../utils/formatCurrency";
import { sanitizeInput, SanitizeInputType } from "../../utils/sanitizeInput";
import ModalSuccess from "../../components/ModalSuccess";
import { useSelector } from "react-redux";
import SubscriptionPlanService from "../../services/SubscriptionPlanService";
import { getDate } from "../../utils/dateTimeHelper";
import { CompanySubscriptionPlan } from "../../types/CompanySubscriptionPlan";
import { CompanyAccountant } from "../../types/CompanyAccountant";
import { cnpjSearch } from "../../utils/cnpjSearch";
import { addressSearch } from "../../utils/addressSearch";
import CompanyService from "../../services/CompanyService";
import UserService from "../../services/UserService";
import { CompanyFormAccountant } from "../../components/CompanyForm/CompanyFormAccountant";
import useAllUf from "../../hooks/uf";
import { Link } from "react-router-dom";
import { PatternFormat } from "../../components/PatternFormat";
import { PermissionMenu } from "../../types/PermissionMenu";
import { BusinessCategory } from "../../types/BusinessCategory";
import BusinessCategoryList from "./Components/BusinessCategoryList";

type InfoAddressData = {
  zipcode?: string;
  type?: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  fontLg: {
    fontSize: 22,
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

export type NewCompanyParams = {
  mainCompanyId?: number;
};

export function EditCompany({ mainCompanyId }: NewCompanyParams = {}) {
  const { user } = useSelector((state: any) => state.auth);

  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const classes = useStyles();

  const subHeader = useSubheader();
  subHeader.setTitle("Editar Empresa");

  const { allStates } = useAllUf();

  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [subscriptionPlans, setSubscriptionPlans] = useState<
    SubscriptionPlan[]
  >([]);

  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");

  /* Logo */
  const imgFileInput = useRef<HTMLInputElement>(null);
  const [logo, setLogo] = useState("");
  const [savedLogo, setSavedLogo] = useState("");

  const [activeTab, setActiveTab] = useState("details");

  const [companyMainUser, setCompanyMainUser] = useState<any>(null);

  const [typePeople, setTypePeople] = useState("");
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [municipalRegistration, setMunicipalRegistration] = useState("");
  const [stateRegistration, setStateRegistration] = useState("");
  const [taxRegime, setTaxRegime] = useState("");
  const [cnae, setCnae] = useState("");
  const [rntrc, setRntrc] = useState("");
  const [responsible, setResponsible] = useState("");
  const [cell, setCell] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [notes, setNotes] = useState("");

  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");

  const [nameInvalid, setNameInvalid] = useState(false);
  const [cpfInvalid, setCpfInvalid] = useState(false);
  const [corporateNameInvalid, setCorporateNameInvalid] = useState(false);
  const [cnpjInvalid, setCnpjInvalid] = useState(false);
  const [responsibleInvalid, setResponsibleInvalid] = useState(false);
  const [cellInvalid, setCellInvalid] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [phoneInvalid, setPhoneInvalid] = useState(false);
  const [zipCodeInvalid, setZipCodeInvalid] = useState(false);
  const [addressInvalid, setAddressInvalid] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [districtInvalid, setDistrictInvalid] = useState(false);
  const [cityInvalid, setCityInvalid] = useState(false);
  const [stateInvalid, setStateInvalid] = useState(false);

  /* Sócios */
  const [partners, setPartners] = useState<PartnersObj[]>([]);
  const [partnersIdsToDelete, setPartnersIdsToDelete] = useState<string[]>([]);

  /* Horário de funcionamento */
  const [onBusinessDay, setOnBusinessDay] = useState(false);
  const [onSaturday, setOnSaturday] = useState(false);
  const [onSunday, setOnSunday] = useState(false);
  const [businessDayOpenHour, setBusinessDayOpenHour] = useState("");
  const [saturdayOpenHour, setSaturdayOpenHour] = useState("");
  const [sundayOpenHour, setSundayOpenHour] = useState("");
  const [businessDayCloseHour, setBusinessDayCloseHour] = useState("");
  const [saturdayCloseHour, setSaturdayCloseHour] = useState("");
  const [sundayCloseHour, setSundayCloseHour] = useState("");
  const [businessCategory, setBusinessCategory] = useState(
    BusinessCategory.DEFAULT
  );

  /* Contabilidade */
  const [accountants, setAccountants] = useState<CompanyAccountant[]>([]);
  const [accountantsIdsToDelete, setAccountantsIdsToDelete] = useState<
    string[]
  >([]);

  /* Permissoes */
  const { menus } = useMenu();
  const [permissionMenus, setPermissionMenus] = useState<PermissionMenu[]>(
    menus as PermissionMenu[]
  );
  const [savedPermissions, setSavedPermissions] = useState<number[]>();
  const [profileId, setProfileId] = useState(0);

  /* Plano */
  const [
    savedCompanySubscriptionPlan,
    setSavedCompanySubscriptionPlan,
  ] = useState<CompanySubscriptionPlan | null>(null);
  const [subscriptionPlanId, setSubscriptionPlanId] = useState(0);
  const [expirationDate, setExpirationDate] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  const [msgError, setMsgError] = useState("");
  const [showModalError, setShowModalError] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (!menus.length) return;
    setPermissionMenus(menus as PermissionMenu[]);
    if (savedPermissions !== undefined) {
      const aux = menus.map((menu) => ({ ...menu }));
      const permissions = mapPermissionsChecked(
        savedPermissions,
        menus as PermissionMenu[]
      );
      setPermissionMenus([...permissions]);
    }
  }, [menus, savedPermissions]);

  useEffect(() => {
    getProfiles();
    getSubscriptionPlans();

    async function loadData() {
      const response = await api.get(`companies/${id}`);
      const data = response.data;

      setTypePeople(data.typePeople ?? "");
      setName(data.name ?? "");
      setCpf(data.cpf ?? "");
      setCorporateName(data.corporateName ?? "");
      setCnpj(data.cnpj ?? "");
      setMunicipalRegistration(data.municipalRegistration ?? "");
      setStateRegistration(data.stateRegistration ?? "");
      setTaxRegime(data.taxRegime ?? "");
      setCnae(data.cnae ?? "");
      setRntrc(data.rntrc ?? "");
      setBusinessCategory(data.businessCategory ?? "");
      setResponsible(data.responsible ?? "");
      setCell(data.cell ?? "");
      setPhone(data.phone ?? "");
      setEmail(data.email ?? "");
      setWebsite(data.website ?? "");
      setNotes(data.notes ?? "");

      setZipCode(data.zipCode ?? "");
      setAddress(data.address ?? "");
      setDistrict(data.district ?? "");
      setComplement(data.complement ?? "");
      setCity(data.city ?? "");
      setState(data.state ?? "");
      setNumber(data.number ?? "");

      setOnBusinessDay(data.onBusinessDay === "y" ? true : false);
      setOnSaturday(data.onSaturday === "y" ? true : false);
      setOnSunday(data.onSunday === "y" ? true : false);
      setBusinessDayOpenHour(data.businessDayOpenHour ?? "");
      setSaturdayOpenHour(data.saturdayOpenHour ?? "");
      setSundayOpenHour(data.sundayOpenHour ?? "");
      setBusinessDayCloseHour(data.businessDayCloseHour ?? "");
      setSaturdayCloseHour(data.saturdayCloseHour ?? "");
      setSundayCloseHour(data.sundayCloseHour ?? "");

      if (data.logo) {
        setLogo(`${ApiUrl}/${data.logo}`);
        setSavedLogo(data.logo);
      }

      setProfileId(data.profileId ?? 0);
      setSavedPermissions(JSON.parse(response.data.permissions));

      await getAccountants(Number(id));

      await getPartners(Number(id));

      await getSubscriptionPlan(Number(id));

      const mainUser = await UserService.getFirstUserByCompanyId(Number(id));
      setCompanyMainUser(mainUser);
    }

    loadData();
  }, []);

  async function getSubscriptionPlan(id: number) {
    const { data } = await api.get(`companies/${id}/subscriptionPlans`);
    if (data.length === 0) {
      return;
    }

    const [plan] = data;

    setSavedCompanySubscriptionPlan(plan);
    setSubscriptionPlanId(plan.planId);
    setExpirationDate(plan.expirationDate ?? "");
    setAlertMsg(plan.alertMsg ?? "");
  }

  async function getAccountants(id: number) {
    const { data } = await api.get(`companies/${id}/accountants`);
    if (data.length === 0) {
      return;
    }

    const savedAccountants = [];

    for (const accountant of data) {
      savedAccountants.push({
        id: String(accountant.id || ""),
        userId: String(accountant.userId || ""),
        name: accountant.name ?? "",
        cpf: accountant.cpf ?? "",
        cnpj: accountant.cnpj ?? "",
        crc: accountant.crc ?? "",
        email: accountant.email ?? "",
        cell: accountant.cell ?? "",
        phone: accountant.phone ?? "",
        zipCode: accountant.zipCode ?? "",
        address: accountant.address ?? "",
        district: accountant.district ?? "",
        city: accountant.city ?? "",
        state: accountant.state ?? "",
        number: accountant.number ?? "",
        complement: accountant.complement ?? "",
      });
    }

    setAccountants(savedAccountants);
  }

  async function getPartners(id: number) {
    const { data } = await api.get(`companies/${id}/partners`);
    if (data.length === 0) {
      return;
    }

    const partnersFound: PartnersObj[] = data.map((obj: any) => {
      return {
        id: String(obj.id ?? ""),
        name: String(obj.name ?? ""),
        cpf: String(obj.cpf ?? ""),
        phone: String(obj.phone ?? ""),
        cell: String(obj.cell ?? ""),
        rg: String(obj.rg ?? ""),
        email: String(obj.email ?? ""),
        birthDate: String(obj.birthDate ?? ""),
        share: String(obj.share ?? ""),
      };
    });

    setPartners(partnersFound);
  }

  async function getProfiles() {
    const { data } = await api.get<Profile[]>("profiles");
    setProfiles(data);
  }

  async function getSubscriptionPlans() {
    const { data } = await api.get<SubscriptionPlan[]>("subscriptionPlans");
    setSubscriptionPlans(data);
  }

  function cleanInputs() {
    setName("");
    setCpf("");
    setCorporateName("");
    setCnpj("");
    setMunicipalRegistration("");
    setStateRegistration("");
    setTaxRegime("");
    setCnae("");
    setRntrc("");
    setBusinessCategory(BusinessCategory.DEFAULT);
  }

  async function CheckCnpj(value: string) {
    if (value) {
      const searchResponse = await cnpjSearch(value ?? "");

      if (searchResponse.status === "ERROR") {
        setMsgError(searchResponse.message);
        setShowModalError(true);
        setCnpjInvalid(true);
        return;
      }

      setCorporateName(searchResponse.nome ?? "");
      setName(searchResponse.fantasia ?? "");
      setEmail(searchResponse.email ?? "");
      setPhone(searchResponse.telefone ?? "");

      setZipCode(searchResponse.cep || "");
      setAddress(searchResponse.logradouro || "");
      setDistrict(searchResponse.bairro || "");
      setCity(searchResponse.municipio || "");
      setState(searchResponse.uf || "");
      setNumber(searchResponse.numero || "");
      setComplement(searchResponse.complemento || "");
    }
  }

  async function CheckCnpjAccountant(value: string, index: number) {
    if (value) {
      const accountantListAux = accountants;
      const searchResponse = await cnpjSearch(value ?? "");

      if (searchResponse.status === "ERROR") {
        setMsgError(searchResponse.message);
        setShowModalError(true);
        return;
      }

      accountantListAux[index].name = searchResponse.nome ?? "";
      accountantListAux[index].email = searchResponse.email ?? "";
      accountantListAux[index].phone = searchResponse.telefone ?? "";
      accountantListAux[index].zipCode = searchResponse.cep ?? "";
      accountantListAux[index].address = searchResponse.logradouro ?? "";
      accountantListAux[index].number = searchResponse.numero ?? "";
      accountantListAux[index].district = searchResponse.bairro ?? "";
      accountantListAux[index].complement = searchResponse.complemento ?? "";
      accountantListAux[index].city = searchResponse.municipio ?? "";
      accountantListAux[index].state = searchResponse.uf ?? "";

      setAccountants([...accountantListAux]);
    }
  }

  async function infoAddress({ zipcode }: InfoAddressData = {}) {
    const zipcodeState = zipCode;
    const targetZipcode = zipcode ? zipcode : zipcodeState;

    const address = await addressSearch(targetZipcode ?? "");

    setAddress(address.logradouro);
    setDistrict(address.bairro);
    setCity(address.localidade);
    setState(address.uf);
  }

  async function infoAddressAccountant(zipcode: string, index: number) {
    const address = await addressSearch(zipcode ?? "");

    const accountantListAux = accountants;

    accountantListAux[index].address = address.logradouro ?? "";
    accountantListAux[index].district = address.bairro ?? "";
    accountantListAux[index].city = address.localidade ?? "";
    accountantListAux[index].state = address.uf ?? "";

    setAccountants([...accountantListAux]);
  }

  async function inputsVerify(): Promise<boolean> {
    if (!name) {
      setMsgError("Preencha o nome corretamente");
      setNameInvalid(true);
      return false;
    }
    if (!responsible) {
      setMsgError("Preencha o nome corretamente");
      setResponsibleInvalid(true);
      return false;
    }
    if (!cell) {
      setActiveTab("details");
      setMsgError("Preencha o celular corretamente");
      setCellInvalid(true);
      return false;
    }
    // if(!(await verifyCellExists())) {
    //     setActiveTab("details");
    //     setMsgError("Celular já existe");
    //     return false;
    // }
    if (!email) {
      setActiveTab("details");
      setMsgError("Preencha o e-mail corretamente");
      setEmailInvalid(true);
      return false;
    }

    if (!phone || phone.replace(/\D/g, "").length < 7) {
      setActiveTab("details");
      setMsgError("Preencha o telefone corretamente");
      setPhoneInvalid(true);
      return false;
    }
    // if(!(await verifyEmailExists())) {
    //     setActiveTab("details");
    //     setMsgError("E-mail já existe");
    //     return false;
    // }
    // if(!(await verifyCnpjExists())) {
    //     setActiveTab("details");
    //     setMsgError("CNPJ já existe");
    //     return false;
    // }
    if (typePeople === "physical") {
      if (!cpf || sanitizeInput(SanitizeInputType.NUMERIC, cpf).length !== 11) {
        setActiveTab("details");
        setMsgError("Preencha o CPF corretamente");
        setCpfInvalid(true);
        return false;
      }
    } else {
      if (!corporateName) {
        setMsgError("Preencha a razão social corretamente");
        setCorporateNameInvalid(true);
        return false;
      }
      if (
        !cnpj ||
        sanitizeInput(SanitizeInputType.NUMERIC, cnpj).length !== 14
      ) {
        setActiveTab("details");
        setMsgError("Preencha o CNPJ corretamente");
        setCnpjInvalid(true);
        return false;
      }
    }

    if (!zipCode) {
      setActiveTab("details");
      setMsgError("Preencha o CEP corretamente");
      setZipCodeInvalid(true);
      return false;
    }
    if (!address) {
      setActiveTab("details");
      setMsgError("Preencha o endereço corretamente");
      setAddressInvalid(true);
      return false;
    }
    if (!number) {
      setActiveTab("details");
      setMsgError("Preencha o número do endereço corretamente");
      setNumberInvalid(true);
      return false;
    }
    if (!district) {
      setActiveTab("details");
      setMsgError("Preencha o bairro corretamente");
      setDistrictInvalid(true);
      return false;
    }
    if (!city) {
      setActiveTab("details");
      setMsgError("Preencha a cidade corretamente");
      setCityInvalid(true);
      return false;
    }
    if (!state) {
      setActiveTab("details");
      setMsgError("Preencha o estado corretamente");
      setStateInvalid(true);
      return false;
    }

    if (mainCompanyId) {
      return true;
    }

    if (!profileId) {
      setActiveTab("access");
      setMsgError("Selecione um perfil");
      return false;
    }

    // if (!subscriptionPlanId) {
    //     setActiveTab("subscriptionPlan");
    //     setMsgError("Atribua um plano para a empresa")
    //     return false;
    // }

    // const today = new Date();
    // const expirationDateObj = new Date(expirationDate);
    // if (expirationDate && expirationDateObj.getTime() - today.getTime() <= 0) {
    //   if (subscriptionPlanId) {
    //     setActiveTab("subscriptionPlan");
    //     setMsgError("A data de expiração do plano não pode estar no passado!");
    //     return false;

    //   }
    // }

    return true;
  }

  async function accountantsVerify(): Promise<boolean> {
    const accountantsAux = accountants;

    if (accountantsAux.length === 0) {
      setActiveTab("accounting");
      setMsgError("Adicione pelo menos um contador da empresa!");
      return false;
    }

    setAccountants((prevState) =>
      prevState.map((accountant) => {
        return {
          ...accountant,
          nameHasError: false,
          cpfHasError: false,
          cnpjHasError: false,
          emailHasError: false,
          cellHasError: false,
        };
      })
    );

    for (const accountant of accountantsAux) {
      if (!accountant.name) {
        accountant.nameHasError = true;
        setActiveTab("accounting");
        setMsgError("Preencha o nome do Contador corretamente");
        setAccountants([...accountantsAux]);
        return false;
      }

      if (
        accountant.cpf &&
        sanitizeInput(SanitizeInputType.NUMERIC, accountant.cpf).length !== 11
      ) {
        accountant.cpfHasError = true;
        setActiveTab("accounting");
        setMsgError("CPF do Contador é inválido");
        setAccountants([...accountantsAux]);
        return false;
      }

      if (
        accountant.cnpj &&
        sanitizeInput(SanitizeInputType.NUMERIC, accountant.cnpj).length !== 14
      ) {
        accountant.cnpjHasError = true;
        setActiveTab("accounting");
        setMsgError("CNPJ do Contador é inválido");
        setAccountants([...accountantsAux]);
        return false;
      }

      if (!accountant.email) {
        accountant.emailHasError = true;
        setActiveTab("accounting");
        setMsgError("Preencha o e-mail do Contador corretamente");
        setAccountants([...accountantsAux]);
        return false;
      }

      if (!accountant.cell) {
        accountant.cellHasError = true;
        setActiveTab("accounting");
        setMsgError("Preencha o celular do Contador corretamente");
        setAccountants([...accountantsAux]);
        return false;
      }

      // const accountantEmailExists = await UserService.verifyEmailExists(accountant.email, accountant.userId);
      // const foundAccountantByEmailIsNotAccountant = accountantEmailExists && accountantEmailExists.isAccountant === 'n';
      // const foundAccountantByEmailIsDifferentFromPrevious = accountantEmailExists && accountant.userId && accountant.userId !== accountantEmailExists.id;
      // if (foundAccountantByEmailIsNotAccountant || foundAccountantByEmailIsDifferentFromPrevious) {
      //     accountant.emailHasError = true;
      //     setActiveTab("accounting");
      //     setMsgError("O e-mail do contador informado não pertence à um usuário contador");
      //     setAccountants([...accountantsAux]);
      //     return false;
      // }

      // const accountantCellExists = await UserService.verifyCellExists(accountant.cell, accountant.userId);
      // const foundAccountantByCellIsNotAccountant = accountantCellExists && accountantCellExists.isAccountant === 'n';
      // const foundAccountantByCellIsDifferentFromPrevious = accountantCellExists && accountant.userId && accountant.userId !== accountantCellExists.id;
      // if (foundAccountantByCellIsNotAccountant || foundAccountantByCellIsDifferentFromPrevious) {
      //     accountant.cellHasError = true;
      //     setActiveTab("accounting");
      //     setMsgError("O celular do contador informado não pertence à um usuário contador");
      //     setAccountants([...accountantsAux]);
      //     return false;
      // }
    }

    return true;
  }

  async function verifyCellExists() {
    try {
      const response = await api.head(
        `companies/check/cell?id=${id}&cell=${cell}`
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async function verifyEmailExists() {
    try {
      const response = await api.head(
        `companies/check/email?id=${id}&email=${email}`
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async function verifyCnpjExists() {
    try {
      const response = await api.head(
        `companies/check/cnpj?id=${id}&cnpj=${cnpj.replace(/[^0-9]/g, "")}`
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  function partnersVerify(): boolean {
    if (partners.length === 0) {
      return true;
    }

    for (const partner of partners) {
      if (!partner.name) {
        setIsSubmit(false);
        setMsgError("Preencha o nome do Sócio corretamente");
        setShowModalError(true);
        return false;
      }
      if (
        !partner.cpf ||
        sanitizeInput(SanitizeInputType.NUMERIC, partner.cpf).length !== 11
      ) {
        setIsSubmit(false);
        setMsgError("Preencha o cpf do Sócio corretamente");
        setShowModalError(true);
        return false;
      }
      if (!partner.cell) {
        setIsSubmit(false);
        setMsgError("Preencha o celular do Sócio corretamente");
        setShowModalError(true);
        return false;
      }
    }

    return true;
  }

  function handleUploadClick() {
    if (imgFileInput && imgFileInput.current) {
      imgFileInput.current.click();
    }
  }

  function handleChangeImgFile(event: any) {
    const input: HTMLInputElement = event.target;
    if (!input.files || input.files.length === 0) return;
    const fileUrl = window.URL.createObjectURL(input.files[0]);
    setLogo(fileUrl);
  }

  function handleChangeProfile(
    event: React.ChangeEvent<{}>,
    profile: Profile | null
  ) {
    setProfileId(profile?.id ?? 0);
    if (profile) {
      const aux = permissionMenus.map((menu) => ({ ...menu }));
      const permissions = mapPermissionsChecked(
        JSON.parse(profile.permissions),
        aux
      );
      setPermissionMenus([...permissions]);
    }
  }

  function handleChangeSubscriptionPlan(
    event: React.ChangeEvent<{}>,
    subscriptionPlan: SubscriptionPlan | null
  ) {
    if (subscriptionPlan) {
      const planExpirationDateObj = SubscriptionPlanService.addPlanDurationToTodayDate(
        subscriptionPlan
      );
      const planExpirationDateString = planExpirationDateObj
        ? getDate({ initialDate: planExpirationDateObj, dateFormat: "y-m-d" })
            .dateStr
        : "";

      setExpirationDate(planExpirationDateString);
    }

    const profileObject: Profile | null =
      profiles.find((profile) => profile.id == subscriptionPlan?.profileId) ??
      null;
    setSubscriptionPlanId(subscriptionPlan?.id ?? 0);
    handleChangeProfile(event, profileObject);
  }

  function handleSetPartnerField(index: number, field: string, value: string) {
    const aux = partners;
    if (field in aux[index] && field !== "id") {
      const partnerObj = aux[index];
      type PartnerKey = keyof typeof partnerObj;
      aux[index][field as PartnerKey] = value;
    }

    setPartners([...aux]);
  }

  function handleAddPartnerClick() {
    const aux = partners;
    aux.push({
      id: "0",
      name: "",
      cpf: "",
      phone: "",
      cell: "",
      rg: "",
      email: "",
      birthDate: "",
      share: "",
    });

    setPartners([...aux]);
  }

  function handlePartnerRemove(index: number, id: string) {
    const aux = partners;
    const partner = aux[index];
    if (partner.id !== "0") {
      const ids = partnersIdsToDelete;
      const idExists = ids.find((idObj) => idObj === partner.id);
      if (!idExists) {
        ids.push(partner.id);
        setPartnersIdsToDelete(ids);
      }
    }
    const partnersFiltered = aux.filter((obj, objIndex) => objIndex !== index);

    setPartners(partnersFiltered);
  }

  function handleSetAccountantField(index: number, field: string, value: any) {
    const aux = accountants;
    if (field in aux[index] && field !== "id") {
      const accountantObj = aux[index];
      type AccountantKey = keyof typeof accountantObj;
      aux[index][field as AccountantKey] = value as never;
    }

    setAccountants([...aux]);
  }

  function handleAddAccountantClick() {
    const aux = accountants;
    aux.push({
      id: "",
      userId: "",
      name: "",
      cpf: "",
      cnpj: "",
      crc: "",
      email: "",
      cell: "",
      phone: "",
      zipCode: "",
      address: "",
      district: "",
      city: "",
      state: "",
      number: "",
      complement: "",
    });

    setAccountants([...aux]);
  }

  function handleAccountantRemove(index: number) {
    const aux = accountants;
    const accountant = aux[index];
    if (accountant.id) {
      const ids = accountantsIdsToDelete;
      const idExists = ids.find((idObj) => idObj === String(accountant.id));
      if (!idExists) {
        ids.push(String(accountant.id));
        setAccountantsIdsToDelete(ids);
      }
    }
    const accountantsFiltered = aux.filter(
      (obj, objIndex) => objIndex !== index
    );

    setAccountants(accountantsFiltered);
  }

  function filterPermissionMenu(menus: PermissionMenu[]): PermissionMenu[] {
    const filtered = menus.filter((menu) => menu.checked);
    if (!filtered || filtered.length === 0) {
      return [];
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].submenus?.length) {
        filtered[i].submenus = filterPermissionMenu(
          filtered[i].submenus as PermissionMenu[]
        ).filter(() => true);
      }
    }
    return filtered;
  }

  function mapPermissionMenu(menus: PermissionMenu[]): Array<number> {
    let mapped = menus.map((menu) => menu.id);
    for (let i = 0; i < menus.length; i++) {
      if (menus[i].submenus?.length) {
        mapped = mapped.concat(
          mapPermissionMenu(menus[i].submenus as PermissionMenu[])
        );
      }
    }
    return mapped;
  }

  function mapPermissionsChecked(
    permissions: Array<number>,
    menus: PermissionMenu[]
  ) {
    const aux = menus;
    for (let i = 0; i < aux.length; i++) {
      aux[i].checked = permissions.includes(aux[i].id);
      if (aux[i].submenus?.length) {
        aux[i].submenus = mapPermissionsChecked(
          permissions,
          aux[i].submenus as PermissionMenu[]
        );
      }
    }
    return aux;
  }

  const onSubmit = async (evt: any) => {
    evt.preventDefault();

    setIsSubmit(true);

    const isValid = await inputsVerify();

    if (!isValid) {
      setIsSubmit(false);
      setShowModalError(true);
      return;
    }

    if (!partnersVerify()) return;

    if (!(await accountantsVerify())) {
      setIsSubmit(false);
      setShowModalError(true);
      return;
    }

    const aux = permissionMenus.map((menu) => ({ ...menu }));

    const menusFiltered = filterPermissionMenu(aux);
    const permissions = mapPermissionMenu(menusFiltered);

    let companyProfileId = profileId;
    let companyPermissions = JSON.stringify(permissions);
    let planSubscriptionPlanId = subscriptionPlanId;
    let planExpirationDate = expirationDate;
    let planAlertMsg = alertMsg;
    let extraAttributes = {};

    const subscriptionPlan = await SubscriptionPlanService.getSubscriptionPlanById(
      planSubscriptionPlanId
    );

    const raw = {
      profileId: companyProfileId,
      permissions: companyPermissions,
      typePeople: typePeople,
      name: name ? name : null,
      corporateName: corporateName ? corporateName : null,
      cpf: cpf ? sanitizeInput(SanitizeInputType.NUMERIC, cpf) : null,
      cnpj: cnpj ? sanitizeInput(SanitizeInputType.NUMERIC, cnpj) : null,
      stateRegistration: stateRegistration ? stateRegistration : null,
      municipalRegistration: municipalRegistration
        ? municipalRegistration
        : null,
      taxRegime: taxRegime ? taxRegime : null,
      cnae: cnae ? cnae : null,
      rntrc: rntrc ? rntrc : null,
      businessCategory: businessCategory ? businessCategory : null,
      responsible: responsible ? responsible : null,
      cell: cell ? cell : null,
      phone: phone ? phone : null,
      email: email ? email : null,
      website: website ? website : null,
      zipCode: zipCode ? zipCode : null,
      address: address ? address : null,
      number: number ? number : null,
      district: district ? district : null,
      complement: complement ? complement : null,
      city: city ? city : null,
      state: state ? state : null,
      onBusinessDay: onBusinessDay ? "y" : "n",
      onSaturday: onSaturday ? "y" : "n",
      onSunday: onSunday ? "y" : "n",
      businessDayOpenHour:
        onBusinessDay && businessDayOpenHour ? businessDayOpenHour : null,
      saturdayOpenHour:
        onSaturday && saturdayOpenHour ? saturdayOpenHour : null,
      sundayOpenHour: onSunday && sundayOpenHour ? sundayOpenHour : null,
      businessDayCloseHour:
        onBusinessDay && businessDayCloseHour ? businessDayCloseHour : null,
      saturdayCloseHour:
        onSaturday && saturdayCloseHour ? saturdayCloseHour : null,
      sundayCloseHour: onSunday && sundayCloseHour ? sundayCloseHour : null,
    };

    try {
      const companyResponse = await api.put(`companies/${id}`, raw);

      // Vincular plano
      // const rawPlan = {
      //     companyId: companyResponse.data.id,
      //     planId: planSubscriptionPlanId,
      //     expirationDate: planExpirationDate || null,
      //     alertMsg: planAlertMsg || null,
      //     value: subscriptionPlan.value,
      //     paymentDate: new Date(),
      //     active: 'y',
      // };

      // if (planSubscriptionPlanId !== savedCompanySubscriptionPlan?.planId || planExpirationDate !== savedCompanySubscriptionPlan?.expirationDate) {
      //     await api.post(`companySubscriptionPlans`, rawPlan);
      // } else {
      //     await api.put(`companySubscriptionPlans/${savedCompanySubscriptionPlan.id}`, rawPlan);
      // }

      // Salvar Dados dos contadores
      await deleteAccountants();

      if (accountants.length > 0) {
        for (const accountant of accountants) {
          await CompanyService.saveCompanyAccountantAndUpdateUser(
            accountant,
            companyResponse.data
          );
        }
      }

      await deletePartners();

      // Salvar parceiros
      if (partners.length > 0) {
        const aux = partners;
        const auxLength = aux.length;

        for (let i = 0; i < auxLength; i++) {
          const partner = aux[i];
          const partnerRaw = {
            companyId: id,
            name: partner.name ? partner.name : null,
            cpf: partner.cpf
              ? sanitizeInput(SanitizeInputType.NUMERIC, partner.cpf)
              : null,
            cell: partner.cell
              ? sanitizeInput(SanitizeInputType.NUMERIC, partner.cell)
              : null,
            phone: partner.phone
              ? sanitizeInput(SanitizeInputType.NUMERIC, partner.phone)
              : null,
            email: partner.email ? partner.email : null,
            birthDate: partner.birthDate ? partner.birthDate : null,
            rg: partner.rg ? partner.rg : null,
            share: partner.share ? formatToFloat(partner.share) : 0,
          };

          if (Number(partner.id)) {
            await api.put(`companyPartners/${partner.id}`, partnerRaw);
          } else {
            const { data } = await api.post(`companyPartners`, partnerRaw);
            partner.id = String(data.id);
            aux[i] = partner;
          }
        }
        setPartners(aux);
      }

      // Upload logo da empresa
      let uploadSuccess = true;
      try {
        uploadSuccess = await uploadLogo(Number(id));
      } catch (error) {
        uploadSuccess = false;
      }
      if (uploadSuccess && savedLogo) {
        await removeOldLogo(savedLogo);
      }

      setMessageSuccess("Edição realizada com sucesso!");
      setShowModalSuccess(true);
    } catch (error) {
      console.log("error", error);
    }

    setIsSubmit(false);
  };

  console.log({ permissionMenus });

  async function removeOldLogo(filename: string) {
    await api.delete("/file", { data: { filename } });
  }

  async function uploadLogo(id: number): Promise<boolean> {
    if (imgFileInput && imgFileInput.current) {
      if (
        !imgFileInput.current.files ||
        imgFileInput.current.files.length === 0
      ) {
        return false;
      }
      const imageFile = imgFileInput.current.files[0];
      if (!imageFile) {
        return false;
      }

      var formFile = new FormData();
      formFile.append("image", imageFile, imageFile.name);

      const response = await api.post("/image", formFile);
      const raw = {
        logo: response.data,
      };

      await api.put(`companies/${id}`, raw);
      return true;
    }

    return false;
  }

  async function deletePartners() {
    if (partnersIdsToDelete.length === 0) return;

    for (const idToDelete of partnersIdsToDelete) {
      await api.delete(`companyPartners/${idToDelete}`);
    }
  }

  async function deleteAccountants() {
    if (accountantsIdsToDelete.length === 0) return;

    for (const idToDelete of accountantsIdsToDelete) {
      await api.delete(`companyAccountants/${idToDelete}`);
    }
  }

  return (
    <div className="row card card-body pt-4 newProductWrapper">
      <ModalSuccess
        setShowModal={setShowModalSuccess}
        showModal={showModalSuccess}
        msgModal={messageSuccess}
        redirect={mainCompanyId ? "/empresas-filiais" : "/empresas"}
      />
      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />
      <form
        className={"makeStyles-container-12"}
        onSubmit={(evt) => onSubmit(evt)}
      >
        <div className="row">
          <div className="col-lg-2 text-center">
            <img
              src={
                logo
                  ? logo
                  : toAbsoluteUrl("/media/logos/header/logo-header-black.png")
              }
              className="img-fluid d-block"
              alt=""
            />

            {user.isAccountant == "n" ? (
              <Button
                className="mt-3 mb-2"
                variant="primary"
                onClick={handleUploadClick}
              >
                Upload
              </Button>
            ) : (
              <></>
            )}
            <input
              type="file"
              className="d-none"
              ref={imgFileInput}
              onChange={(e) => handleChangeImgFile(e)}
              accept="image/png, image/jpeg"
              disabled={user.isAccountant === "y" ? true : false}
            />
            {/* <label htmlFor="formFile" className="mt-3">Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.</label> */}
          </div>

          <div className="col-lg-3">
            <TextField
              id="outlined-select-currency"
              select
              size="small"
              label="Tipo de pessoa"
              className={classes.textField}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              margin="normal"
              variant="outlined"
              value={typePeople}
              onChange={(e) => {
                setTypePeople(e.target.value);
                cleanInputs();
              }}
              disabled={user.isAccountant === "y" ? true : false}
            >
              <MenuItem
                key="0"
                value="physical"
                onClick={() => setTypePeople("physical")}
              >
                Pessoa Física
              </MenuItem>

              <MenuItem
                key="1"
                value="legal"
                onClick={() => setTypePeople("legal")}
              >
                Pessoa Jurídica
              </MenuItem>
            </TextField>
          </div>

          {typePeople === "physical" ? (
            <>
              <div className="col-6">
                <TextField
                  label="Nome"
                  required
                  size="small"
                  className={classes.error}
                  margin="normal"
                  variant="outlined"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameInvalid(e.target.value ? false : true);
                  }}
                  error={nameInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              {user.isAccountant == "n" ? (
                <div className="col-lg-1 d-flex align-items-start justify-content-end">
                  <Button
                    type="submit"
                    className="mt-4"
                    variant="primary"
                    disabled={isSubmit}
                  >
                    {isSubmit ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />

                        <span className="ml-2">Aguarde...</span>
                      </>
                    ) : (
                      <>
                        <span>Salvar</span>
                      </>
                    )}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <div className="col-3">
                <TextField
                  value={corporateName}
                  label="Razão social"
                  required
                  size="small"
                  className={classes.error}
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setCorporateName(e.target.value);
                    setCorporateNameInvalid(e.target.value ? false : true);
                  }}
                  error={corporateNameInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>
              <div className="col-3">
                <TextField
                  value={name}
                  label="Nome fantasia"
                  required
                  size="small"
                  className={classes.error}
                  margin="normal"
                  variant="outlined"
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameInvalid(e.target.value ? false : true);
                  }}
                  error={nameInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              {user.isAccountant == "n" ? (
                <div className="col-lg-1 d-flex align-items-start">
                  <Button
                    type="submit"
                    className="mt-4"
                    variant="primary"
                    disabled={isSubmit}
                  >
                    {isSubmit ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />

                        <span className="ml-2">Aguarde...</span>
                      </>
                    ) : (
                      <>
                        <span>Salvar</span>
                      </>
                    )}
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>

        <Tabs
          activeKey={activeTab}
          onSelect={(tab: string) => setActiveTab(tab)}
          id="editcompany-form-tabs"
        >
          <Tab eventKey="details" title="Dados da Empresa">
            {typePeople === "physical" ? (
              <>
                <div className="row">
                  <div className="col-lg-6">
                    <PatternFormat
                      label="CPF"
                      format="###.###.###-##"
                      mask="_"
                      required
                      value={cpf}
                      onChange={(e) => {
                        setCpf(e.target.value);
                        setCpfInvalid(e.target.value ? false : true);
                      }}
                      error={cpfInvalid}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row d-flex align-items-center">
                  <div className="col-lg-3">
                    <PatternFormat
                      label="CNPJ"
                      required
                      format="##.###.###/####-##"
                      mask="_"
                      endAdornment={
                        <IconButton
                          edge="end"
                          aria-label="CNPJ search"
                          onClick={() => CheckCnpj(cnpj)}
                        >
                          <i className="flaticon-search"></i>
                        </IconButton>
                      }
                      value={cnpj}
                      onChange={(e) => {
                        setCnpj(e.target.value);
                        setCnpjInvalid(e.target.value ? false : true);
                      }}
                      error={cnpjInvalid}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                  <div className="col-lg-2">
                    <TextField
                      size="small"
                      label="Inscrição estadual"
                      margin="normal"
                      variant="outlined"
                      value={stateRegistration}
                      onChange={(e) => {
                        setStateRegistration(e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                  <div className="col-lg-1 pl-0">
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          disabled={user.isAccountant === "y" ? true : false}
                          color="primary"
                          onChange={(e) =>
                            setStateRegistration(
                              e.target.checked ? "ISENTO" : ""
                            )
                          }
                          checked={
                            stateRegistration === "ISENTO" ? true : false
                          }
                        />
                      }
                      label="Isento"
                      labelPlacement="end"
                      className="ml-1"
                    />
                  </div>
                  <div className="col-lg-2">
                    <TextField
                      size="small"
                      label="Inscrição municipal"
                      margin="normal"
                      variant="outlined"
                      value={municipalRegistration}
                      onChange={(e) => {
                        setMunicipalRegistration(e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                  <div className="col-lg-4">
                    <TextField
                      select
                      label="Regime tributário"
                      size="small"
                      SelectProps={{
                        autoWidth: false,
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="normal"
                      variant="outlined"
                      value={taxRegime}
                      onChange={(e) => setTaxRegime(e.target.value)}
                      disabled={user.isAccountant === "y" ? true : false}
                    >
                      <MenuItem key="1" value="simple national">
                        Simples Nacional
                      </MenuItem>

                      <MenuItem
                        key="2"
                        value="simple national excess revenue"
                        style={{ whiteSpace: "normal" }}
                      >
                        Simples Nacional - excesso de receita de sublimite de
                        receita bruta
                      </MenuItem>

                      <MenuItem key="3" value="normal regime">
                        Regime Normal
                      </MenuItem>
                    </TextField>
                  </div>
                  <div className="col-lg-3">
                    <TextField
                      size="small"
                      label="CNAE"
                      margin="normal"
                      variant="outlined"
                      value={cnae}
                      onChange={(e) => {
                        setCnae(e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                  <div className="col-lg-3">
                    <TextField
                      size="small"
                      label="RNTRC"
                      margin="normal"
                      variant="outlined"
                      value={rntrc}
                      onChange={(e) => {
                        setRntrc(e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                  <div className="col-lg-3">
                    <BusinessCategoryList
                      businessCategory={businessCategory}
                      setBusinessCategory={setBusinessCategory}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row d-flex align-items-center">
              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Responsável"
                  required
                  margin="normal"
                  variant="outlined"
                  value={responsible}
                  onChange={(e) => {
                    setResponsible(e.target.value);
                    setResponsibleInvalid(e.target.value ? false : true);
                  }}
                  error={responsibleInvalid}
                />
              </div>
              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Login do responsável"
                  required
                  margin="normal"
                  variant="outlined"
                  value={companyMainUser?.login ?? ""}
                  onChange={(e) =>
                    setCompanyMainUser((state: any) => ({
                      ...state,
                      login: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col-lg-4">
                <Link
                  to={`/usuarios/${companyMainUser?.id ?? ""}`}
                  className="btn btn-primary"
                >
                  Ver Usuário
                </Link>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 d-flex justify-content-between align-items-center">
                <PatternFormat
                  label="CEP"
                  className="mr-3"
                  format="#####-###"
                  mask="_"
                  value={zipCode}
                  onChange={(e) => {
                    setZipCode(e.target.value);
                    setZipCodeInvalid(!e.target.value);
                  }}
                  error={zipCodeInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />

                {user.isAccountant == "n" ? (
                  <Button
                    variant="primary"
                    onClick={() => infoAddress()}
                    className="btn-sm"
                  >
                    <i className="flaticon-search p-0"></i>
                  </Button>
                ) : (
                  <></>
                )}
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Endereço"
                  margin="normal"
                  variant="outlined"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setAddressInvalid(!e.target.value);
                  }}
                  error={addressInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Número"
                  margin="normal"
                  variant="outlined"
                  value={number}
                  onChange={(e) => {
                    setNumber(e.target.value);
                    setNumberInvalid(!e.target.value);
                  }}
                  error={numberInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Bairro"
                  margin="normal"
                  variant="outlined"
                  value={district}
                  onChange={(e) => {
                    setDistrict(e.target.value);
                    setDistrictInvalid(!e.target.value);
                  }}
                  error={districtInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Complemento"
                  margin="normal"
                  variant="outlined"
                  value={complement}
                  onChange={(e) => setComplement(e.target.value)}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Cidade"
                  margin="normal"
                  variant="outlined"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                    setCityInvalid(!e.target.value);
                  }}
                  error={cityInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              <div className="col-lg-4 pt-3">
                <Autocomplete
                  size="small"
                  value={{ sigla: state }}
                  options={allStates.states}
                  getOptionLabel={({ sigla }) => sigla}
                  style={{ width: "100%", marginTop: "6px" }}
                  onInputChange={(event, newInputValue) => {
                    setState(newInputValue);
                    setStateInvalid(!newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Estado"
                      variant="outlined"
                      error={stateInvalid}
                    />
                  )}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="E-mail"
                  className={classes.error}
                  margin="normal"
                  variant="outlined"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailInvalid(e.target.value ? false : true);
                  }}
                  error={emailInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              <div className="col-lg-4">
                <PatternFormat
                  label="Telefone"
                  className={classes.error}
                  format="(##) ####-####"
                  mask="_"
                  required
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setPhoneInvalid(e.target.value ? false : true);
                  }}
                  error={phoneInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              <div className="col-lg-4">
                <PatternFormat
                  label="Celular"
                  className={classes.error}
                  format="(##) #####-####"
                  mask="_"
                  value={cell}
                  onChange={(e) => {
                    setCell(e.target.value);
                    setCellInvalid(e.target.value ? false : true);
                  }}
                  error={cellInvalid}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>

              <div className="col-lg-4">
                <TextField
                  size="small"
                  label="Website"
                  margin="normal"
                  variant="outlined"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  disabled={user.isAccountant === "y" ? true : false}
                />
              </div>
            </div>
          </Tab>

          <Tab eventKey="partners" title="Sócios">
            {partners.map((partner, index) => (
              <React.Fragment key={index}>
                {user.isAccountant == "n" ? (
                  <div className="row mt-3">
                    <div className="col d-flex justify-content-end align-items-center">
                      <Button
                        variant="danger"
                        onClick={() => handlePartnerRemove(index, partner.id)}
                      >
                        Excluir
                      </Button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="row">
                  <div className="col">
                    <TextField
                      size="small"
                      label="Nome Completo"
                      required
                      margin="normal"
                      variant="outlined"
                      value={partner.name}
                      onChange={(e) =>
                        handleSetPartnerField(index, "name", e.target.value)
                      }
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                  <div className="col">
                    <PatternFormat
                      label="CPF"
                      required
                      format="###.###.###-##"
                      mask="_"
                      value={partner.cpf}
                      onChange={(e) => {
                        handleSetPartnerField(index, "cpf", e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4">
                    <TextField
                      size="small"
                      label="E-mail"
                      className={classes.error}
                      margin="normal"
                      variant="outlined"
                      value={partner.email}
                      onChange={(e) => {
                        handleSetPartnerField(index, "email", e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PatternFormat
                      label="Celular"
                      required
                      className={classes.error}
                      format="(##) #####-####"
                      mask="_"
                      value={partner.cell}
                      onChange={(e) => {
                        handleSetPartnerField(index, "cell", e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                  <div className="col-lg-4">
                    <PatternFormat
                      label="Telefone"
                      className={classes.error}
                      format="(##) ####-####"
                      mask="_"
                      value={partner.phone}
                      onChange={(e) => {
                        handleSetPartnerField(index, "phone", e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                </div>

                <div className="row border-bottom mb-3">
                  <div className="col-lg-4">
                    <TextField
                      type="date"
                      label="Data de nascimento"
                      className={classes.textField}
                      size="small"
                      margin="normal"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={partner.birthDate}
                      onChange={(e) =>
                        handleSetPartnerField(
                          index,
                          "birthDate",
                          e.target.value
                        )
                      }
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>

                  <div className="col-lg-4">
                    <TextField
                      size="small"
                      label="RG"
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      value={partner.rg}
                      onChange={(e) =>
                        handleSetPartnerField(index, "rg", e.target.value)
                      }
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>

                  <div className="col-lg-4">
                    <NumericFormat
                      label="Quota (%)"
                      startAdornment="%"
                      value={partner.share}
                      onChange={(e) =>
                        handleSetPartnerField(index, "share", e.target.value)
                      }
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}

            {user.isAccountant == "n" ? (
              <div className="row mt-5">
                <div className="col">
                  <Button
                    variant="primary"
                    className="mr-3"
                    onClick={handleAddPartnerClick}
                  >
                    + Adicionar Sócio
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Tab>

          <Tab eventKey="business_hours" title="Horário de Funcionamento">
            <div className="row d-flex align-items-center">
              <div className="col-lg-2">Segunda à Sexta</div>
              <div className="col-lg-1 pl-0">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      disabled={user.isAccountant === "y" ? true : false}
                      color="primary"
                      value={onBusinessDay}
                      onChange={(e) => {
                        setOnBusinessDay(e.target.checked);
                        setBusinessDayOpenHour("");
                        setBusinessDayCloseHour("");
                      }}
                      checked={onBusinessDay}
                    />
                  }
                  label="Aberto"
                  labelPlacement="end"
                  className="ml-1"
                />
              </div>

              {onBusinessDay && (
                <>
                  <div className="col-lg-2">
                    <PatternFormat
                      label="Abertura"
                      format="##:##"
                      mask="_"
                      value={businessDayOpenHour}
                      onChange={(e) => {
                        setBusinessDayOpenHour(e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>

                  <div className="col-lg-2">
                    <PatternFormat
                      label="Fechamento"
                      format="##:##"
                      mask="_"
                      value={businessDayCloseHour}
                      onChange={(e) => {
                        setBusinessDayCloseHour(e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="row d-flex align-items-center">
              <div className="col-lg-2">Sábado</div>
              <div className="col-lg-1 pl-0">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      disabled={user.isAccountant === "y" ? true : false}
                      color="primary"
                      value={onSaturday}
                      onChange={(e) => {
                        setOnSaturday(e.target.checked);
                        setSaturdayOpenHour("");
                        setSaturdayCloseHour("");
                      }}
                      checked={onSaturday}
                    />
                  }
                  label="Aberto"
                  labelPlacement="end"
                  className="ml-1"
                />
              </div>

              {onSaturday && (
                <>
                  <div className="col-lg-2">
                    <PatternFormat
                      label="Abertura"
                      format="##:##"
                      mask="_"
                      value={saturdayOpenHour}
                      onChange={(e) => {
                        setSaturdayOpenHour(e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>

                  <div className="col-lg-2">
                    <PatternFormat
                      label="Fechamento"
                      format="##:##"
                      mask="_"
                      value={saturdayCloseHour}
                      onChange={(e) => {
                        setSaturdayCloseHour(e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="row d-flex align-items-center">
              <div className="col-lg-2">Domingo</div>
              <div className="col-lg-1 pl-0">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      disabled={user.isAccountant === "y" ? true : false}
                      color="primary"
                      value={onSunday}
                      onChange={(e) => {
                        setOnSunday(e.target.checked);
                        setSundayOpenHour("");
                        setSundayCloseHour("");
                      }}
                      checked={onSunday}
                    />
                  }
                  label="Aberto"
                  labelPlacement="end"
                  className="ml-1"
                />
              </div>

              {onSunday && (
                <>
                  <div className="col-lg-2">
                    <PatternFormat
                      label="Abertura"
                      format="##:##"
                      mask="_"
                      value={sundayOpenHour}
                      onChange={(e) => {
                        setSundayOpenHour(e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>

                  <div className="col-lg-2">
                    <PatternFormat
                      label="Fechamento"
                      format="##:##"
                      mask="_"
                      value={sundayCloseHour}
                      onChange={(e) => {
                        setSundayCloseHour(e.target.value);
                      }}
                      disabled={user.isAccountant === "y" ? true : false}
                    />
                  </div>
                </>
              )}
            </div>
          </Tab>

          <Tab eventKey="accounting" title="Contabilidade">
            {user.isAccountant !== "y" && (
              <div className="row mt-3">
                <div className="col bg-warning-o-30 p-5 mb-3">
                  <p>
                    <strong>Atenção: </strong>
                    Adicionar um contador irá criar um usuário com acesso à área
                    de contabilidade da empresa. Um email será enviado ao
                    contador para que ele confirme o acesso.
                  </p>
                </div>
              </div>
            )}

            <div className="row mt-3">
              <div className="col">
                {accountants.map((accountant, index) => (
                  <React.Fragment key={index}>
                    <CompanyFormAccountant
                      accountantId={accountant.id}
                      accountantName={accountant.name}
                      onChangeAccountantName={(value) =>
                        handleSetAccountantField(index, "name", value)
                      }
                      accountantCpf={accountant.cpf}
                      onChangeAccountantCpf={(value) =>
                        handleSetAccountantField(index, "cpf", value)
                      }
                      accountantCnpj={accountant.cnpj}
                      onChangeAccountantCnpj={(value) =>
                        handleSetAccountantField(index, "cnpj", value)
                      }
                      accountantCrc={accountant.crc}
                      onChangeAccountantCrc={(value) =>
                        handleSetAccountantField(index, "crc", value)
                      }
                      accountantEmail={accountant.email}
                      onChangeAccountantEmail={(value) =>
                        handleSetAccountantField(index, "email", value)
                      }
                      accountantPhone={accountant.phone}
                      onChangeAccountantPhone={(value) =>
                        handleSetAccountantField(index, "phone", value)
                      }
                      accountantCell={accountant.cell}
                      onChangeAccountantCell={(value) =>
                        handleSetAccountantField(index, "cell", value)
                      }
                      accountantZipCode={accountant.zipCode}
                      onChangeAccountantZipCode={(value) =>
                        handleSetAccountantField(index, "zipCode", value)
                      }
                      accountantAddress={accountant.address}
                      onChangeAccountantAddress={(value) =>
                        handleSetAccountantField(index, "address", value)
                      }
                      accountantNumber={accountant.number}
                      onChangeAccountantNumber={(value) =>
                        handleSetAccountantField(index, "number", value)
                      }
                      accountantDistrict={accountant.district}
                      onChangeAccountantDistrict={(value) =>
                        handleSetAccountantField(index, "district", value)
                      }
                      accountantComplement={accountant.complement}
                      onChangeAccountantComplement={(value) =>
                        handleSetAccountantField(index, "complement", value)
                      }
                      accountantCity={accountant.city}
                      onChangeAccountantCity={(value) =>
                        handleSetAccountantField(index, "city", value)
                      }
                      accountantState={accountant.state}
                      onChangeAccountantState={(value) =>
                        handleSetAccountantField(index, "state", value)
                      }
                      accountantNameHasError={accountant.nameHasError}
                      accountantEmailHasError={accountant.emailHasError}
                      accountantCellHasError={accountant.cellHasError}
                      accountantCpfHasError={accountant.cpfHasError}
                      accountantCnpjHasError={accountant.cnpjHasError}
                      allStates={allStates}
                      CheckCnpj={(value) => CheckCnpjAccountant(value, index)}
                      infoAddress={(value) =>
                        infoAddressAccountant(value, index)
                      }
                      currentIndex={index}
                      onAccountantRemove={handleAccountantRemove}
                      currentUserIsAccountant={user.isAccountant === "y"}
                    />
                    <hr />
                  </React.Fragment>
                ))}
                {user.isAccountant !== "y" && (
                  <div className="row mt-5">
                    <div className="col">
                      <Button
                        variant="primary"
                        className="mr-3"
                        onClick={handleAddAccountantClick}
                      >
                        + Adicionar Contador
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Tab>

          {!mainCompanyId && (
            <></>
            // <Tab eventKey="subscriptionPlan" title="Plano">
            //     <div className="row d-flex align-items-center">
            //         <div className="col-lg-4">
            //             <Autocomplete
            //                 size="small"
            //                 noOptionsText="Sem opções"
            //                 options={subscriptionPlans}
            //                 value={subscriptionPlans.find(plan => plan.id === subscriptionPlanId) ?? null}
            //                 getOptionLabel={({name, value}) => name ? `${name} - R$ ${formatCurrency(value)}` : ''}
            //                 onChange={(event, newInputValue) => handleChangeSubscriptionPlan(event, newInputValue)}
            //                 renderInput={(params) =>
            //                     <TextField
            //                         size="small"
            //                         { ...params }
            //                         label="Plano"
            //                         margin="normal"
            //                         variant="outlined"
            //                     />
            //                 }
            //                 disabled={user.isAccountant === 'y' ? true : false}
            //             />
            //         </div>
            //         <div className="col-lg-4">
            //             <TextField
            //                 type="date"
            //                 label="Data de Expiração"
            //                 margin="normal"
            //                 variant="outlined"
            //                 size="small"
            //                 InputLabelProps={{
            //                     shrink: true,
            //                 }}
            //                 value={expirationDate}
            //                 onChange={(e) => setExpirationDate(e.target.value)}
            //                 disabled={user.isAccountant === 'y' ? true : false}
            //             />
            //         </div>
            //         <div className="col-lg-1 pl-0">
            //             <FormControlLabel
            //                 value="end"
            //                 control={(
            //                     <Checkbox
            //                         disabled={user.isAccountant === 'y' ? true : false}
            //                         color="primary"
            //                         onChange={e => setExpirationDate("")}
            //                         checked={!expirationDate}
            //                     />
            //                 )}
            //                 label="Indeterminado"
            //                 labelPlacement="end"
            //                 className="ml-1"
            //             />
            //         </div>
            //     </div>

            //     {/* <div className="row">
            //         <div className="col-lg-8">
            //             <TextField
            //                 label="Mensagem de alerta vencimento"
            //                 margin="normal"
            //                 variant="outlined"
            //                 value={alertMsg}
            //                 onChange={(e) => setAlertMsg(e.target.value)}
            //                 disabled={user.isAccountant === 'y' ? true : false}
            //             />
            //         </div>
            //     </div> */}
            // </Tab>
          )}

          {!mainCompanyId && (
            <Tab eventKey="access" title="Acesso">
              <div className="row">
                <div className="col-lg-4">
                  <Autocomplete
                    size="small"
                    noOptionsText="Sem opções"
                    options={profiles}
                    value={
                      profiles.find((profile) => profile.id === profileId) ??
                      null
                    }
                    getOptionLabel={({ name }) => name ?? ""}
                    onChange={(event, newInputValue) =>
                      handleChangeProfile(event, newInputValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        label="Perfil"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                    disabled={user.isAccountant === "y" ? true : false}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <PermissionMenuSelector
                    permissionMenus={permissionMenus}
                    setPermissionMenus={setPermissionMenus}
                  />
                </div>
              </div>
            </Tab>
          )}
        </Tabs>

        <div className="row">
          <div className="col-lg-11"></div>

          <div className="col-lg-1 d-flex align-items-start justify-content-end">
            <Button
              type="submit"
              className="mt-4"
              variant="primary"
              disabled={isSubmit}
            >
              {isSubmit ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="ml-2">Aguarde...</span>
                </>
              ) : (
                <>
                  <span>Salvar</span>
                </>
              )}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
