import { Collapse, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from '../../components/ListWithModalChangeSituation';
import { Search } from '../../components/Search';
import useBackendLoad from '../../hooks/backendReload';
import api from '../../services/Api';
import { BsVariant } from '../../types/BsVariant';
import { UserAccess } from '../../types/UserAccess';
import { getDate } from '../../utils/dateTimeHelper';
import { useSelector } from 'react-redux';
import { useCompanyBranch } from '../../hooks/companyBranch';
import { orderByIdDesc } from '../../utils/orderTable';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import CompanyService from '../../services/CompanyService';
import { CompanyAccountant } from '../../types/CompanyAccountant';
import { formatPhoneNumber } from '../../utils/formatPhoneNumber';
import { ValidationBadge } from '../../components/ValidationBadge';

type Filters = {
    searchQuery: string;
    company: string;
}

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "N°" },
    { reference: "createdDate", value: "Data Cadastro" },
    { reference: "name", value: "Nome" },
    { reference: "cell", value: "Celular" },
];
const historyHeadData: HeadDataBaseProps[] = [
    { reference: "id", value: "N°" },
    { reference: "createdDate", value: "Data Acesso" },
    { reference: "ip", value: "IP" },
];

export function ListUser() {
    const { location: { pathname }, push: pushHistory } = useHistory();

    const [users, setUsers] = useState<any[]>([]);
    const [countTotalUsers, setCountTotalUsers] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [userHistory, setUserHistory] = useState<UserAccess[]>([]);
    const [historyBodyData, setHistoryBodyData] = useState<BodyDataBaseProps[][]>([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [company, setCompany] = useState("");
    const filtersRef = useRef<Filters | null>(null);

    const { selectedCompany } = useCompanyBranch();
    const { user } = useSelector((state: any) => state.auth);

    const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        const { data } = await api.get<{ rows: any[], count: number }>('users', {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const { rows, count } = data;

        setUsers(rows);
        setCountTotalUsers(count);
    }, []);

    // useEffect(() => {
    //     handleClickSearch();
    // }, [users]);

    useEffect(() => {
        (async () => {
            const list: BodyDataBaseProps[][] = [];

            for (const item of users) {
                const data: BodyDataBaseProps[] = [
                    { for: "id", value: String(item.id), id: true },
                    { for: "createdDate", value: getDate({ initialDate: new Date(item.createdDate) }).dateStr },
                    { for: "name", value: `${item.firstname ?? ''} ${item.lastname ?? ''}` },
                    { 
                        for: "cell",
                        value: formatPhoneNumber(item.cell),
                        jsx: (
                            <>
                                <span>{formatPhoneNumber(item.cell)}</span>
                                {item.cellVerifiedAt && (
                                    <span className="ml-3">
                                        <ValidationBadge label="" variant="success" title="Número verificado" />
                                    </span>
                                )}
                            </>
                        )
                    },
                    { for: "isAccountant", value: item.isAccountant },
                ];

                list.push(data);
            }

            setBodyData(list);
        })();
    }, [users, selectedCompany]);

    const handleClickDelete = useCallback(async (id: string) => {
        await api.delete(`users/${id}`);
        const aux = users.filter(u => String(u.id) !== id);

        setUsers([...aux]);
    }, [users]);

    const handleClickDeleteAccountant = useCallback(async (id: string) => {
        const accountantResponse = await api.get(`users/${id}`, { params: { includeCompanyAccountantInfo: true } });
        const accountant = accountantResponse.data;

        const branchesIds = JSON.parse(accountant.branchesIds);
        branchesIds.splice(branchesIds.indexOf(selectedCompany.id), 1);

        const permissionsAccountant = JSON.parse(accountant.permissions);
        permissionsAccountant.splice(permissionsAccountant.map((permission: any) => permission.companyId).indexOf(selectedCompany.id), 1);

        const fastAccessAccountant = JSON.parse(accountant.fastAccess);
        fastAccessAccountant.splice(fastAccessAccountant.map((fastAccess: any) => fastAccess.companyId).indexOf(selectedCompany.id), 1);
        
        let companyIdAccountant = accountant.companyId;
        if (branchesIds.length && !branchesIds.includes(companyIdAccountant)) {
            companyIdAccountant = branchesIds[0];
        }

        await api.put(`users/${id}`, {
            branchesIds: JSON.stringify(branchesIds), 
            companyId: companyIdAccountant, 
            permissions: JSON.stringify(permissionsAccountant),
            fastAccess: JSON.stringify(fastAccessAccountant)
        });

        // Desvincular informações do contador no cadastro da empresa
        const companyAccountantInfos: CompanyAccountant[] = accountant.companyAccountantInfos ?? [];
        const companyAccountantInfo = companyAccountantInfos.find((info) => info.companyId === selectedCompany.id);

        if (companyAccountantInfo) {
            await api.delete(`/companyAccountants/${companyAccountantInfo.id}`);
        }

        const aux = users.filter(u => String(u.id) !== id);

        setUsers([...aux]);
    }, [users, selectedCompany]);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`);
    }, []);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const handleClickUserProfiles = useCallback(() => {
        pushHistory(`perfis-usuario`);
    }, []);

    const handleClickHistory = useCallback(async (id: string) => {
        setUserHistory([]);
        setShowHistoryModal(true);
        getUserHistory(id);
    }, []);

    async function getUserHistory(id: string) {
        const response = await api.get<UserAccess[]>(`/users/${id}/userAccess`);
        const history = response.data;

        const list: BodyDataBaseProps[][] = [];
        for (const item of history) {
            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(item.id), id: true },
                { for: "createdDate", value: getDate({ initialDate: new Date(item.createdDate) }).fullDateStr },
                { for: "ip", value: item.ipAddress ?? '' },
            ];

            list.push(data);
        }

        setHistoryBodyData(list);
    }

    const clearSearch = () => {
        setSearchQuery('');
        setCompany('');
    }

    const handleClickSearch = useCallback(async () => {
        filtersRef.current = { searchQuery, company };

        reloadData();
    }, [users, searchQuery, company]);

    return (
        <>
            <Modal
                size="lg"
                show={showHistoryModal}
                onHide={() => setShowHistoryModal(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        Histórico de acesso do usuário
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListWithModalChangeSituation
                        headData={historyHeadData}
                        bodyData={historyBodyData}
                        lastCell={false}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowHistoryModal(!showHistoryModal)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <div className="card card-body pt-4">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-9 mt-3">
                        {
                            user.isAccountant == "n"
                            ?
                            <Button
                                type="button"
                                variant="success"
                                className="mr-2"
                                onClick={() => handleClickAdd()}
                            >
                                Adicionar Usuário
                            </Button>
                            : <></>
                        }
                        <Button
                            type="button"
                            variant="info"
                            className="mr-2"
                            onClick={() => handleClickUserProfiles()}
                        >
                            Perfis de Usuário
                        </Button>
                    </div>
                    <div className="col-lg-3 mt-1">
                        <Search
                            query={searchQuery}
                            setQuery={setSearchQuery}
                            setCollapseAdvancedSearch={user.isSuper === 'y' ? setAdvancedSearch : undefined}
                            onClickSearch={handleClickSearch}
                        />
                    </div>
                </div>
                {user.isSuper === 'y' && (
                    <Collapse in={advancedSearch}>
                        <div className="row d-flex align-items-center">
                            <div className="col-lg-6">
                                <ApiResourceSelect
                                    label="Empresa"
                                    getOptionLabel={(option: any) => option.name}
                                    value={company}
                                    onSelect={(option) => setCompany(String(option?.id ?? ''))}
                                    apiSearchHandler={(typedText) => CompanyService.getCompaniesFiltered({ filters: { name: typedText } })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!company) return null;
                                        return loadedOptions.find((option) => option.id === Number(company)) ?? CompanyService.getCompanyById(company)
                                    }}
                                />
                            </div>
                            
                            <div className="col-12 d-flex justify-content-end">
                                <Button
                                    onClick={handleClickSearch}
                                    className="mr-3"
                                >
                                    Pesquisar
                                </Button>

                                <Button
                                    onClick={clearSearch}
                                >
                                    Limpar
                                </Button>
                            </div>
                        </div>
                    </Collapse>
                )}

                <div className="mt-3">
                    <ListWithModalChangeSituation
                        headData={headData}
                        bodyData={bodyData}
                        onEdit={handleClickEdit}
                        onDelete={handleClickDelete}
                        onDeleteAccountant={handleClickDeleteAccountant}
                        customButtons={[
                            {
                                class: 'btn-light-info',
                                content: (<i className='p-0 flaticon-list-2'></i>),
                                variant: BsVariant.INFO,
                                popup: "Histórico de acesso do usuário",
                                onClick: handleClickHistory,
                            },
                        ]}
                        sortable={true}
                        loadData={loadData}
                        totalCount={countTotalUsers}
                        triggerLoad={triggerLoad}
                        setTriggerLoad={setTriggerLoad}
                    />
                </div>
            </div>
        </>
    );
}