import apiBFF from "../services/ApiBFF";

interface IScheduleMessage {
  companyMail: string;
  user: {
    id: string;
    token: string;
  };
  passwordHash?: string;
  contact: {
    name: string;
    number: string;
  };
  schedule: {
    body: string;
    sendAt: string;
    userId: string;
  };
}

export const makeScheduleMessage = async (props: IScheduleMessage) => {
  try {
    const data = {
      contact: {
        name: props?.contact?.name,
        number: props?.contact?.number?.replace(/\D/g, ""),
      },
      schedule: {
        body: props?.schedule?.body,
        sendAt: `${props?.schedule?.sendAt}T10:00:00.000Z`,
        sentAt: "",
        userId: props.user.id,
      },
    };

    await apiBFF.post("millionzap/schedules/create", data, {
      headers: {
        Authorization: `Bearer ${props.user.token}`,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
