import React from "react";
import { TextField } from "@material-ui/core";
import { Label } from "recharts";

interface IProps {
  setTemplateOpticsMessage: (value: string) => void;
  templateOpticsMessage: string;
}

export function ConfigOptics({
  setTemplateOpticsMessage,
  templateOpticsMessage,
}: IProps) {
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-8">
              <TextField
                label="Mensagem de vencimento de receita"
                size="small"
                multiline
                margin="normal"
                variant="outlined"
                value={templateOpticsMessage}
                onChange={(e) => setTemplateOpticsMessage(e.target.value)}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>Exemplo de tags:</label>
                <label>{`None: {{name}}`}</label>
                <label>{`Saudação: {{ms}}`}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
