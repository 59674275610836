import api from "axios";

const url = process.env.REACT_APP_BFF_APP_URL;

const apiBFF = api.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
});

export default apiBFF;
