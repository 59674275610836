export const BusinessCategory = {
  OPTICS: "OPTICS",
  DEFAULT: "DEFAULT",
  FASHION_AND_CLOTHING: "FASHION_AND_CLOTHING",
  SERVICE_PROVIDERS: "SERVICE_PROVIDERS",
  MARKET: "MARKET",
  WORKSHOP: "WORKSHOP",
  CONSTRUCTION_MATERIAL: "CONSTRUCTION_MATERIAL",
  TECHNOLOGY_AND_IT: "TECHNOLOGY_AND_IT",
  DISTRIBUTORS: "DISTRIBUTORS",
  FOOD_AND_BEVERAGES: "FOOD_AND_BEVERAGES",
  OFFICE_SERVICES: "OFFICE_SERVICES",
  BEAUTY_AND_AESTHETICS: "BEAUTY_AND_AESTHETICS",
  AUTO_PARTS: "AUTO_PARTS",
  PET_SHOP: "PET_SHOP",
  ACCOUNTING: "ACCOUNTING",
};
