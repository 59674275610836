import React, { useCallback, useEffect, useState } from "react";
import { InputAdornment, makeStyles, Popper, TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomerService from "../services/CustomerService";
import { Customer } from "../types/Customer";
import api from "../services/Api";
import { ApiResourceSelectAddButton } from "./ApiResourceSelectAddButton";
import { useStyles } from "../hooks/styles";

type Option = Customer | '__add__';

type CustomerSelectProps = {
    label: string;
    isEdit?: boolean;
    hasError?: boolean;
    customerId?: number;
    setCustomerId: React.Dispatch<React.SetStateAction<number | undefined>>;
    customerName?: string;
    setCustomerName?: React.Dispatch<React.SetStateAction<string>>;
    onCustomerChanged?: (customerId: number | undefined, customerName: string) => void
    entityType?: string;
    entityId?: number | string;
    disabled?: boolean;
    required?: boolean;
    allowIncomplete?: boolean;
    typeCustomer?: 'both' | 'customer' | 'supplier';
    onClickAddCustomer?: (typedText: string) => void;
};

export default function CustomerSelect({
    label,
    isEdit,
    hasError,
    customerId,
    setCustomerId,
    customerName,
    setCustomerName,
    onCustomerChanged,
    entityType,
    entityId,
    disabled,
    required,
    allowIncomplete = false,
    typeCustomer = 'both',
    onClickAddCustomer,
}: CustomerSelectProps) {

    const classes = useStyles();

    const [foundCustomers, setFoundCustomers] = useState<Option[]>([]);
    const [isSearching, setIsSearching] = useState(false);

    const customerCanBeIncomplete = allowIncomplete && !!setCustomerName && customerName !== undefined;
    const selectedCustomer = customerId
        ? (foundCustomers.find(data => data !== '__add__' && data.id === customerId) ?? null)
        : (customerCanBeIncomplete ? customerName : null);

    useEffect(() => {
        async function handleSelectedCustomer() {
            if (customerId) {
                const customerLoaded = foundCustomers.find((customer) => customer !== '__add__' && customer.id === customerId);
                if (!customerLoaded) {
                    const customer = await CustomerService.getCustomerById(customerId);
                    setFoundCustomers((prevState) => [...([customer].concat(prevState))]);
                }
            }
        }
        handleSelectedCustomer();
    }, [customerId, foundCustomers]);

    const handleChangeCustomerId = useCallback(async (value: string | Customer | null) => {
        if (typeof value === 'object') {
            setCustomerId(value?.id ?? undefined);

            let customerName = '';

            if (customerCanBeIncomplete && setCustomerName) {
                customerName = CustomerService.getCustomerName(value);
                setCustomerName(CustomerService.getCustomerName(value));
            }

            if (onCustomerChanged) {
                onCustomerChanged(value?.id ?? undefined, customerName);
            }
        }
    }, [foundCustomers]);

    const handleChangeCustomerName = useCallback(async (value: string, reason: AutocompleteInputChangeReason) => {
        if (reason === 'reset') {
            return;
        }
        setFoundCustomers([]);

        if (value.length >= 1) {
            setIsSearching(true);
            api.get<Customer[]>('customer', { params: { filters: { name: value, nameWithId: true, typeRegister: typeCustomer } } }).then((foundCustomers) => {
                const optionsArray: Option[] = foundCustomers.data;

                if (onClickAddCustomer) {
                    optionsArray.push('__add__');
                }

                setIsSearching(false);
                setFoundCustomers(optionsArray);
            });
        }

        setCustomerId(undefined);

        if (customerCanBeIncomplete && setCustomerName) {
            setCustomerName(value);
        }

        if (onCustomerChanged) {
            onCustomerChanged(undefined, value);
        }
    }, [typeCustomer]);

    function handleGetOptionLabel(option: Option | string) {
        if (option === '__add__') {
            return '';
        }

        return CustomerService.getCustomerNameWithId(option, customerName);
    }

    function handleRenderOption(option: Option | string, typedText: string) {
        if (option === '__add__' && onClickAddCustomer) {
            return (
                <ApiResourceSelectAddButton
                    label={`Adicionar ${label.replace(' *', '').replace('*', '')}`}
                    onClick={() => onClickAddCustomer(typedText)}
                />
            );
        }

        return <>{handleGetOptionLabel(option)}</>;
    }

    return (
        <Autocomplete
            filterOptions={(options, state) => options}
            size="small"
            loading={isSearching}
            loadingText="Carregando..."
            freeSolo={customerCanBeIncomplete}
            noOptionsText="Sem opções"
            options={foundCustomers}
            getOptionLabel={option => handleGetOptionLabel(option)}
            value={selectedCustomer}
            disabled={disabled}
            onChange={(evt, value) => handleChangeCustomerId(value)}
            onInputChange={(evt, value, reason) => handleChangeCustomerName(value, reason)}
            PopperComponent={
                (props) => {
                    return <Popper {...props} className={classes.popper} placement="bottom-start" />;
                }
            }
            renderOption={(option, state) => handleRenderOption(option, state.inputValue)}
            renderInput={(params) =>
                <TextField
                    size="small"
                    {...params}
                    label={label}
                    margin="normal"
                    variant="outlined"
                    error={hasError}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: customerCanBeIncomplete && customerName && !customerId ? (
                            <InputAdornment position="end">
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip id='tooltip-top'>
                                            {label} sem cadastro vinculado
                                            clique para realizar o cadastro
                                        </Tooltip>
                                    }
                                >
                                    {onClickAddCustomer ? (
                                        <div className="cursor-pointer" onClick={() => onClickAddCustomer(String(customerName))}>
                                            <Badge pill variant="warning" className="text-light">
                                                <i className="flaticon2-warning" style={{ color: "#fff" }}></i>
                                            </Badge>
                                        </div>
                                    ) : (
                                        <Badge pill variant="warning" className="text-light">
                                            <i className="flaticon2-warning" style={{ color: "#fff" }}></i>
                                        </Badge>
                                    )}
                                </OverlayTrigger>
                            </InputAdornment>
                        ) : params.InputProps.startAdornment,
                    }}
                />
            }
        />
    );
} 