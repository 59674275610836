import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { BusinessCategory } from "../../../types/BusinessCategory";
import { useStyles } from "../../../hooks/styles";

interface IProps {
  businessCategory: string;
  setBusinessCategory: (value: string) => void;
  error?: boolean;
  style?: any;
  showLabel?: boolean;
}

export default function BusinessCategoryList({
  businessCategory,
  setBusinessCategory,
  error,
  style,
  showLabel = true,
}: IProps) {
  const classes = useStyles();

  return (
    <TextField
      style={style}
      select
      label={showLabel && "Segmento de atuação"}
      size="small"
      SelectProps={{
        autoWidth: false,
        MenuProps: {
          className: classes.menu,
        },
      }}
      error={error}
      margin="normal"
      variant="outlined"
      value={businessCategory}
      onChange={(e) => setBusinessCategory(e.target.value)}
    >
      <MenuItem key="10" value={BusinessCategory.FOOD_AND_BEVERAGES}>
        Alimentos e Bebidas
      </MenuItem>
      <MenuItem key="13" value={BusinessCategory.AUTO_PARTS}>
        Autopeças
      </MenuItem>
      <MenuItem key="12" value={BusinessCategory.BEAUTY_AND_AESTHETICS}>
        Beleza e Estética
      </MenuItem>
      <MenuItem key="15" value={BusinessCategory.ACCOUNTING}>
        Contabilidade
      </MenuItem>
      <MenuItem key="9" value={BusinessCategory.DISTRIBUTORS}>
        Distribuidoras
      </MenuItem>
      <MenuItem key="7" value={BusinessCategory.CONSTRUCTION_MATERIAL}>
        Material de Construção
      </MenuItem>
      <MenuItem key="5" value={BusinessCategory.MARKET}>
        Mercado
      </MenuItem>
      <MenuItem key="3" value={BusinessCategory.FASHION_AND_CLOTHING}>
        Moda e Vestuário
      </MenuItem>
      <MenuItem key="6" value={BusinessCategory.WORKSHOP}>
        Oficina
      </MenuItem>
      <MenuItem key="1" value={BusinessCategory.OPTICS}>
        Ótica
      </MenuItem>
      <MenuItem key="2" value={BusinessCategory.DEFAULT}>
        Outros
      </MenuItem>
      <MenuItem key="14" value={BusinessCategory.PET_SHOP}>
        Pet Shop
      </MenuItem>
      <MenuItem key="4" value={BusinessCategory.SERVICE_PROVIDERS}>
        Prestadores de Serviços
      </MenuItem>
      <MenuItem key="11" value={BusinessCategory.OFFICE_SERVICES}>
        Serviços de Escritórios
      </MenuItem>
      <MenuItem key="8" value={BusinessCategory.TECHNOLOGY_AND_IT}>
        Tecnologia e Informática
      </MenuItem>
    </TextField>
  );
}
