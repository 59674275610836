export function validatePromotionDate(value: string, allowPastDates = false) {
  const [year, month, day] = value.split("-").map(Number);

  const date = new Date(Date.UTC(year, month - 1, day));
  date.setDate(date.getDate() + 1);

  console.log("date=", date, "value=", value);

  if (isNaN(date.getTime())) {
    return {
      isValid: false,
      message: "A data fornecida não é válida.",
    };
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  if (allowPastDates || date.getTime() >= today.getTime()) {
    return {
      isValid: true,
      message: "A data é válida e é maior ou igual à data atual.",
    };
  } else {
    return {
      isValid: false,
      message: "A data é menor que a data atual.",
    };
  }
}