import React from 'react';

export function ListModelProcess() {
    return (
        <div className="card card-body pt-4">
            <div className="row d-flex align-items-center">
                <h1>Modelagem de Processos</h1>
            </div>
        </div>
    );
}