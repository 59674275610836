import React, { FormEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Tab, Tabs, Spinner, Modal, Alert } from "react-bootstrap";
import {
    MenuItem,
    TextField,
    Link,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination
} from '@material-ui/core';

import api from "../../services/Api";
import { useSubheader } from "../../../_metronic/layout";
import { paymentOptions } from '../../utils/paymentOptions';
import { NumericFormat } from '../../components/NumericFormat';

import '../../style.css';
import ModalError from '../../components/ModalError';
import ModalChangeBillSituation from '../../components/ModalChangeBillSituation';
import useChangeBillSituation from '../../hooks/changeBillSituation';
import { formatCurrency, formatNumberToString, formatToFloat } from '../../utils/formatCurrency';
import { dateIsBetweenRange, extractDateStringFromTimestamp, getDate } from '../../utils/dateTimeHelper';
import { format } from 'date-fns';
import LogService from '../../services/LogService';
import CustomerService from '../../services/CustomerService';
import { getSituationText } from '../../utils/getSituationText';
import { getBillType } from '../../utils/getBillType';
import { SubCategory } from '../../types/Dre';
import { partialPayments } from '../../components/ListWithModalChangeSituation';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import BankAccountService from '../../services/BankAccountService';
import CenterCostService from '../../services/CenterCostService';
import { BankAccount } from '../../types/BankAccount';
import DreCategoryService from '../../services/DreCategoryService';
import { CenterCost } from '../../types/CenterCost';
import { formatStringDateToLocale } from '../../utils/dateFormat';
import BillToReceiveService from '../../services/BillToReceiveService';
import { BillsToReceive, billetType } from '../../types/BillsToReceive';
import { ApiResourceSelectAddButton } from '../../components/ApiResourceSelectAddButton';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer } from '../../types/Customer';
import { AddAccountBankModal } from '../../components/AccountBank/AddAccountBankModal';
import useAddAccountBank from '../../hooks/addAccountBank';
import { useCompanyBranch } from '../../hooks/companyBranch';
import ModalSuccess from '../../components/ModalSuccess';
import { useStyles } from '../../hooks/styles';
import { bankNumberIsInter } from '../../utils/bankNumberIsInter';

type partialPaymentsView = partialPayments & {
    bankAccountName?: string;
}

export function EditBillsToReceive() {
    const { user } = useSelector((state: any) => state.auth);
    const { selectedCompany } = useCompanyBranch();

    const [activeTab, setActiveTab] = useState("detalhes-receita");

    const [beforeSubmitData, setBeforeSubmitData] = useState<any>({});
    const [name, setName] = useState("");
    const [amount, setAmount] = useState(0);
    const [dueDate, setDueDate] = useState("");
    const [payment, setPayment] = useState("");
    const [isPaid, setIsPaid] = useState(true);
    const [isCanceled, setIsCanceled] = useState(false);
    const [reasonCanceled, setReasonCanceled] = useState("");
    const [comments, setComments] = useState("");
    const [docNumber, setDocNumber] = useState("");
    const [centerCost, setCenterCost] = useState("");
    const [occurrence, setOccurrence] = useState("");
    const [recordType, setRecordType] = useState("");
    const [bankAccount, setBankAccount] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [issuanceDate, setIssuanceDate] = useState("");
    const [customer, setCustomer] = useState<string | null>("");
    const [dreSubCategoryId, setDreSubCategoryId] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [selectedBankAccountIsInter, setSelectedBankAccountIsInter] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);
    const [msgError, setMsgError] = useState("");
    const [showModalError, setShowModalError] = useState(false);
    const [msgSuccess, setMsgSuccess] = useState("");
    const [showModalSuccess, setShowModalSuccess] = useState(false);

    // Inputs Verify
    const [revenueNameError, setRevenueNameError] = useState(false);
    const [accountBankError, setAccountBankError] = useState(false);
    const [accountPlanError, setAccountPlanError] = useState(false);
    const [payMethodsError, setPayMethodsError] = useState(false);
    const [customerError, setCustomerError] = useState(false);
    const [dueError, setDueError] = useState(false);
    const [valueError, setValueError] = useState(false);
    const [issuanceDateError, setIssuanceDateError] = useState(false);

    const [partialJson, setPartialJson] = useState<partialPaymentsView[]>([]);
    const [pagesPartial, setPagesPartial] = useState(0);
    const [rowsPerPagePartial, setRowsPerPagePartial] = useState(10);

    const classes = useStyles();
    const subHeader = useSubheader();
    const { id } = useParams<{ id: string }>();
    const {
        isSituationModalOpen,
        setIsSituationModalOpen,
        isReversalModalOpen,
        setIsReversalModalOpen,
        dueDate: dueDateInfo,
        setDueDate: setDueDateInfo,
        supplier,
        setSupplier,
        remaining,
        setRemaining,
        status,
        setStatus,
        paymentDate,
        setPaymentDate,
        valueToPay,
        setValueToPay,
        fee,
        setFee,
        discount,
        setDiscount,
        tax,
        setTax,
        acc,
        setAcc,
        total,
        setTotal,
        writeOffValue,
        partialPayments,
        setPartialPayments,
        validateDiscount,
        getChangedStatus,
    } = useChangeBillSituation();

    subHeader.setTitle("Adicionar Conta a Receber");

    // Modal AddAccountBank
    const { showModalAddAccountBank, setShowModalAddAccountBank } = useAddAccountBank();
    const handleCreateAccountBank = (accountBank: BankAccount) => {
        handleChangeAccountBank(accountBank);
    }

    // Modal New Customer
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: Customer) => {
        setCustomer(String(createdCustomer.id));
    };

    const handleClickAddCustomer = (typedText: string) => {
        setNewCustomerDefaultData({ name: typedText });
        setShowModalNewCustomer(true);
    };

    useLayoutEffect(() => {
        async function loadData() {
            const { data } = await api.get<BillsToReceive>(`billsToReceive/${id}`);

            setName(data.name);
            setAmount(data.amount);
            setRemaining(data.remaining);
            setDueDate(data.dueDate);
            setPayment(data.payment);
            // setIsPaid(data.status == "paid" ? true : false);
            setIsCanceled(data.status == "canceled" ? true : false);
            setReasonCanceled(data.reasonCanceled);
            setComments(data.comments ?? '');
            setCustomer(data.customer ? String(data.customer) : "");
            setCustomerName(CustomerService.getCustomerName(data.customerEntity));
            setDocNumber(data.docNumber ?? '');
            setCenterCost(String(data.centerCost || ''));
            setOccurrence(data.occurrence);
            setRecordType(data.recordType);
            handleChangeAccountBank(data.bankAccountEntity);
            setCategoryName(data.categoryName);
            setIssuanceDate(data.issuanceDate);
            setAcc(formatCurrency(data.accValue ?? 0));
            setTax(formatCurrency(data.taxValue ?? 0));
            setFee(formatCurrency(data.feeValue ?? 0));
            setDiscount(formatCurrency(data.discountValue ?? 0));
            setStatus(data.status);
            setDreSubCategoryId(data.dreSubCategoryId);
            setPartialPayments(data.partialPayments);

            if (data.status == "paid") {
                setValueToPay(formatCurrency(data.amount));
            } else {
                setValueToPay(formatCurrency(data.remaining));
            }

            setPaymentDate(BillToReceiveService.getBillPaymentDate(data) ?? '');

            setBeforeSubmitData(data);
        }

        loadData();
    }, [id]);

    useEffect(() => {
        async function mapPartialPayments() {
            const partials: partialPayments[] = partialPayments ? JSON.parse(partialPayments) : [];

            const mapped: partialPaymentsView[] = await Promise.all(
                partials.map(async (partial) => {
                    const bank = await BankAccountService.getBankAccountById(partial.bankAccount);
                    return {
                        ...partial,
                        bankAccountName: bank ? `${bank.nameBank} - ${bank.name}` : '',
                    };
                })
            );

            setPartialJson(mapped);
        }

        mapPartialPayments();
    }, [partialPayments]);

    useEffect(() => {
        const oldAmount = beforeSubmitData.amount;
        const oldRemaining = beforeSubmitData.remaining;
        let remainingValue = 0;

        if (oldAmount === oldRemaining) {
            remainingValue = amount;
        } else {
            const paidValue = oldAmount - oldRemaining;
            remainingValue = amount - paidValue;
            remainingValue = remainingValue < 0 ? 0 : remainingValue;
        }

        if (remainingValue === 0) {
            setIsPaid(true);
        }

        setRemaining(remainingValue);
    }, [beforeSubmitData, amount]);

    useEffect(() => {
        const valueNumber = formatToFloat(valueToPay);
        const feeNumber = formatToFloat(fee);
        const taxNumber = formatToFloat(tax);
        const accNumber = formatToFloat(acc);
        const discountNumber = formatToFloat(discount);

        validateDiscount({
            valueNumber,
            feeNumber,
            taxNumber,
            accNumber,
            discountNumber,
        });
    }, [valueToPay, fee, tax, acc, discount]);

    const handleChangeAccountBank = useCallback((accountBank: BankAccount | null | undefined) => {
        setBankAccount(String(accountBank?.id ?? ''));
        setSelectedBankAccountIsInter(accountBank ? bankNumberIsInter(accountBank.numberBank) : false);
    }, []);

    const handleChangeRecordType = (value: string) => {
        setRecordType(value);

        if (value === 'billet') {
            setPayment('Boleto');
        }
    };

    const handleChangePagePartial = useCallback((next: number) => {
        setPagesPartial(next);
    }, []);

    const handleRowsPerPagePartial = useCallback((value: number) => {
        setPagesPartial(0);
        setRowsPerPagePartial(value);
    }, []);

    function handleOpenChangeSituationModal() {
        const dueDateFormatted = formatStringDateToLocale(dueDate);
        const defaultPaymentDate = dueDate || format(Date.now(), "yyyy-MM-dd");

        setSupplier(customer ?? '');
        setValueToPay(formatCurrency(remaining));
        setTotal(remaining);
        setDueDateInfo(dueDateFormatted);
        setRemaining(status == "paid" ? 0 : remaining);

        if (status !== 'paid') {
            setPaymentDate(defaultPaymentDate);
        }

        setIsSituationModalOpen(true);
    }

    const handleChangeSituation = useCallback(async () => {
        setIsSubmit(true);
        const isValid = await inputsVerify();
        if (!isValid) return;

        try {
            const changedRemaining = remaining - formatToFloat(valueToPay);
            const changedStatus = getChangedStatus(changedRemaining);

            const partialsJson = partialPayments ? JSON.parse(partialPayments) : null;

            var rawData;
            var auxPartials: any = [];

            if (changedRemaining == 0 && partialsJson == null) {
                rawData = {
                    status: changedStatus,
                    dueDate,
                    payedDate: changedStatus === 'paid' ? paymentDate : null,
                    payment,
                    remaining: changedRemaining,
                    bankAccount,
                    customer,
                    accValue: Number(formatToFloat(acc)),
                    taxValue: Number(formatToFloat(tax)),
                    discountValue: Number(formatToFloat(discount)),
                    feeValue: Number(formatToFloat(fee)),
                    totalPaid: Number(formatToFloat(total)),
                };

            } else if (changedRemaining == 0 && partialsJson) {
                auxPartials = [
                    ...partialsJson,
                    {
                        payedDate: paymentDate,
                        writeOffValue,
                        feeValue: Number(formatToFloat(fee)),
                        discountValue: Number(formatToFloat(discount)),
                        taxValue: Number(formatToFloat(tax)),
                        accValue: Number(formatToFloat(acc)),
                        totalPaid: Number(formatToFloat(total)),
                        payment,
                        bankAccount
                    }
                ];

                rawData = {
                    status: "paid",
                    partialPayments: JSON.stringify(auxPartials),
                    remaining: changedRemaining
                };

            } else {
                if (partialsJson) {
                    auxPartials = [
                        ...partialsJson,
                        {
                            payedDate: paymentDate,
                            writeOffValue,
                            feeValue: Number(formatToFloat(fee)),
                            discountValue: Number(formatToFloat(discount)),
                            taxValue: Number(formatToFloat(tax)),
                            accValue: Number(formatToFloat(acc)),
                            totalPaid: Number(formatToFloat(total)),
                            payment,
                            bankAccount
                        }
                    ];

                } else {
                    auxPartials = [
                        {
                            payedDate: paymentDate,
                            writeOffValue,
                            feeValue: Number(formatToFloat(fee)),
                            discountValue: Number(formatToFloat(discount)),
                            taxValue: Number(formatToFloat(tax)),
                            accValue: Number(formatToFloat(acc)),
                            totalPaid: Number(formatToFloat(total)),
                            payment,
                            bankAccount
                        }
                    ];
                }

                rawData = {
                    partialPayments: JSON.stringify(auxPartials),
                    remaining: changedRemaining
                };
            }

            const res = await saveBillToReceive(false, true, rawData);

            // const res = await saveBillToReceive(false, {
            //     status: changedStatus,
            //     dueDate,
            //     payment,
            //     remaining,
            //     bankAccount,
            //     customer,
            //     accValue: Number(formatToFloat(acc)),
            //     taxValue: Number(formatToFloat(tax)),
            //     discountValue: Number(formatToFloat(discount)),
            //     feeValue: Number(formatToFloat(fee)),
            //     totalPaid: Number(total),
            // });

            setIsSituationModalOpen(false);

            if (res) {
                setIsPaid(true);
                setStatus(changedStatus);
            }

            if (auxPartials.length) {
                setPartialPayments(JSON.stringify(auxPartials));
                setRemaining(changedRemaining);
            }

        } catch (error: any) {
            setShowModalError(true);
            setMsgError('Ocorreu um erro ao salvar a conta!');
            console.log(error.message);
        }
        setIsSubmit(false);
    }, [saveBillToReceive]);

    const handleReversal = useCallback(async () => {
        setIsSubmit(true);
        // if (!inputsVerify()) return

        const hasPermission = await BillToReceiveService.checkIfAuthUserHasPermissionToChangeBillSituationOfPastMonth(paymentDate, selectedCompany);
        if (!hasPermission) {
            setMsgError('O mês fiscal anterior foi encerrado e o usuário não tem permissão para administrar fechamento de mês! Em caso de dúvidas contate o administrador do sistema.');
            setIsReversalModalOpen(false);
            setShowModalError(true);
            return;
        }

        try {
            await saveBillToReceive(false, true, {
                status: 'pending',
                remaining: amount,
                accValue: 0,
                taxValue: 0,
                discountValue: 0,
                feeValue: 0,
                totalPaid: 0,
                payedDate: "",
                partialPayments: null
            });

            setIsReversalModalOpen(false);
            setIsPaid(true);
            setStatus('pending');
            setAcc("R$0,00");
            setTax("R$0,00");
            setFee("R$0,00");
            setDiscount("R$0,00");
            setRemaining(amount);
            setPartialPayments(null);

        } catch (error: any) {
            setShowModalError(true);
            setMsgError('Ocorreu um erro ao salvar a conta!');
            console.log(error.message);
        }
        setIsSubmit(false);
    }, [saveBillToReceive]);

    function handleCloseBillSituationModal() {
        setIsSituationModalOpen(false);
        setBankAccount('');
    };

    const onSubmit = async (evt: FormEvent) => {
        evt.preventDefault();

        setIsSubmit(true);

        const isValid = await inputsVerify();

        if (!isValid) return;

        try {
            await saveBillToReceive();

            setIsSubmit(false);
        } catch (error: any) {
            setIsSubmit(false);
            setShowModalError(true);
            setMsgError('Ocorreu um erro ao salvar a conta!');
            console.log(error.message);
        }
    }

    async function saveBillToReceive(redirect: boolean = true, liquidateOrReversal: boolean = false, additionalData?: any) {
        /** 
         * Algum dado mudou então deve gerar o boleto novamente
         */
        const dataForBilletChanged = (
            beforeSubmitData.bankAccount != bankAccount
            || beforeSubmitData.dueDate != dueDate
            || beforeSubmitData.amount != Number(amount)
            || beforeSubmitData.customer != customer
            || (beforeSubmitData.recordType != recordType && (beforeSubmitData.recordType == "billet" || recordType == "billet"))
        );

        const bank = await api.get(`/accountBank/${parseInt(bankAccount)}`);

        let billBilletType: billetType | null = null;

        if (recordType === 'billet' && dataForBilletChanged) {
            // Verificando se o banco selecionado está corretamente configurado para emitir boletos
            const bankError = await BankAccountService.validateBilletRequiredInformation(bank.data);

            if (bankError) {
                setMsgError(bankError);
                setShowModalError(true);
                setIsSubmit(false);
                return;
            }

            if (selectedBankAccountIsInter) {
                billBilletType = billetType.INTER;
            } else {
                billBilletType = billetType.MANUAL;
            }
        }

        /** 
         * Se antes do submit era um boleto inter e agora alterou alguma coisa, deve cancelar o boleto do inter antes de gerar um novo
        */
        const beforeSubmitDataIsInterBilletAndDataChanged = dataForBilletChanged && beforeSubmitData.billetType === billetType.INTER;

        let data: any = {
            isPaid,
            name: name,
            amount: Number(amount),
            remaining: Number(remaining),
            bankAccount: bankAccount ? bankAccount : null,
            nameBank: bankAccount ? bank.data.nameBank : null,
            categoryName: String(categoryName).length > 0 ? categoryName : null,
            centerCost: String(centerCost).length > 0 ? centerCost : null,
            comments: String(comments).length > 0 ? comments : null,
            customer: String(customer).length > 0 ? customer : null,
            docNumber: String(docNumber).length > 0 ? docNumber : null,
            dueDate: String(dueDate).length > 0 ? dueDate : null,
            issuanceDate: String(issuanceDate).length > 0 ? issuanceDate : null,
            occurrence: String(occurrence).length > 0 ? occurrence : null,
            payment: String(payment).length > 0 ? payment : null,
            recordType: String(recordType).length > 0 ? recordType : null,
            generateRemittance: dataForBilletChanged ? "n" : beforeSubmitData.generateRemittance,
            billetNumber: dataForBilletChanged ? bank.data.billetSequence : beforeSubmitData.billetNumber,
            billetType: billBilletType,
            dreSubCategoryId: dreSubCategoryId > 0 ? dreSubCategoryId : null,
            beforeSubmitDataIsInterBilletAndDataChanged,
            billetDocument: beforeSubmitDataIsInterBilletAndDataChanged ? null : undefined,
            generatedBilletStructure: beforeSubmitDataIsInterBilletAndDataChanged ? null : undefined,
        }

        if (remaining === 0) {
            data.status = 'paid';
        }

        if (additionalData) {
            data = {
                ...data,
                ...additionalData,
            };
        }

        const res = await api.put(`billsToReceive/${id}`, data);
        res.data.bankAccountEntity = bank.data;

        if (recordType == "billet" && payment === 'Boleto' && res.data.status !== 'paid' && dataForBilletChanged) {
            const billetDocument = await BillToReceiveService.generateBillet(res.data, {
                updateBilletSequence: false,
            });
            res.data.billetDocument = billetDocument;

            window.open(await BillToReceiveService.getBilletFileUrl(res.data), '_blank', 'width=800,height=800');
        }

        LogService.logEdit({
            itemId: beforeSubmitData.id,
            itemName: data.name,
            module: 'Contas a Receber',
            oldData: beforeSubmitData,
            newData: data,
            formattedFields: {
                bankAccount: async (value) => (await BankAccountService.getBankAccountById(Number(value)))?.name ?? '',
                centerCost: async (value) => (await CenterCostService.getCenterCostById(Number(value)))?.name ?? '',
                issuanceDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                dueDate: (value) => value ? (new Date(value)).toLocaleDateString() : '',
                customer: (value) => CustomerService.getNameByIdAsync(value),
                amount: (value) => formatNumberToString(value),
                status: (value) => getSituationText(value),
                recordType: (value) => getBillType(value)
            },
            fieldsMap: {
                name: 'Nome da receita',
                bankAccount: 'Conta bancária',
                payment: 'Forma de pagamento',
                categoryName: 'Plano de contas',
                customer: 'Cliente',
                dueDate: 'Data de Vencimento',
                amount: 'Valor',
                recordType: 'Tipo de registro',
                issuanceDate: 'Data da emissão',
                docNumber: 'Nº do documento',
                centerCost: 'Centro de custos',
                comments: 'Observações',
                status: 'Status',
            },
        });

        if (redirect) {
            setMsgSuccess('Conta a receber atualizada com sucesso!');
            setShowModalSuccess(true);
        }

        setBeforeSubmitData(res.data);

        return true;
    }

    async function inputsVerify() {
        setRevenueNameError(false);
        setAccountBankError(false);
        setAccountPlanError(false);
        setPayMethodsError(false);
        setCustomerError(false);
        setDueError(false);
        setValueError(false);
        setIssuanceDateError(false);

        if (!name) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Nome da despesa é obrigatório!");
            setShowModalError(true);
            setRevenueNameError(true);
            return false;
        }

        if (!bankAccount) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Selecione uma conta bancária!");
            setShowModalError(true);
            setAccountBankError(true);
            return false;
        }

        if (!payment) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Forma de Pagamento é obrigatório!");
            setShowModalError(true);
            setPayMethodsError(true);
            return false;
        }

        if (!dreSubCategoryId) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Plano de Contas é obrigatório!");
            setShowModalError(true);
            setAccountPlanError(true);
            return false;
        }

        if (!customer) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Cliente é obrigatório!");
            setShowModalError(true);
            setCustomerError(true);
            return false
        }

        if (!dueDate) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Vencimento é obrigatório!");
            setShowModalError(true);
            setDueError(true);
            return false;
        }

        if (!amount) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Valor é obrigatório!");
            setShowModalError(true);
            setValueError(true);
            return false;
        }
        if (amount <= 0) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Valor deve ser maior que zero!");
            setShowModalError(true);
            setValueError(true);
            return false;
        }

        if (!issuanceDate) {
            setActiveTab("detalhes-receita");
            setIsSubmit(false);
            setMsgError("Campo Data da Emissão é obrigatório!");
            setShowModalError(true);
            setIssuanceDateError(true);
            return false;
        }

        if (issuanceDate) {
            const dateIsValid = dateIsBetweenRange(issuanceDate, '', extractDateStringFromTimestamp());
            if (!dateIsValid) {
                setActiveTab("detalhes-receita");
                setIsSubmit(false);
                setMsgError("A data de emissão não pode ser maior que a data atual!");
                setShowModalError(true);
                setIssuanceDateError(true);
                return false;
            }
        }

        // Validacoes quando for boleto do banco inter
        if (recordType === 'billet' && payment === 'Boleto' && selectedBankAccountIsInter) {
            const validationResult = await BillToReceiveService.validateGenerateInterBilletFields({ amount, customerId: customer, });

            if (validationResult.error) {
                switch (validationResult.field) {
                    case 'amount':
                        setActiveTab("detalhes-receita");
                        setValueError(true);
                        break;
                    case 'customerId':
                        setActiveTab("detalhes-receita");
                        setCustomerError(true);
                        break;
                }

                setMsgError(validationResult.message);
                setIsSubmit(false);
                setShowModalError(true);
                return false;
            }
        }

        return true;
    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <ModalSuccess
                msgModal={msgSuccess}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                redirect="/contas-a-receber"
            />

            {/* Modal de situação */}
            <ModalChangeBillSituation
                isSituationModalOpen={isSituationModalOpen}
                handleCloseModal={handleCloseBillSituationModal}
                viewOnly={status == "paid" ? true : false}
                situationModalTitle={status == "paid" ? 'Ver pagamento' : 'Liquidar conta a receber'}
                billsToPay={false}
                name={name}
                status={status}
                supplier={customerName}
                dueDate={dueDateInfo}
                amount={amount}
                remaining={remaining}
                paymentDate={paymentDate}
                setPaymentDate={setPaymentDate}
                payment={payment}
                setPayment={setPayment}
                bankAccount={bankAccount}
                handleChangeBankAccount={handleChangeAccountBank}
                recordType={recordType}
                valueToPay={valueToPay}
                setValueToPay={setValueToPay}
                fee={fee}
                setFee={setFee}
                discount={discount}
                setDiscount={setDiscount}
                tax={tax}
                setTax={setTax}
                acc={acc}
                setAcc={setAcc}
                handleChangeSituation={handleChangeSituation}
                total={total}
                setTotal={setTotal}
                writeOffValue={writeOffValue}
                partialPayments={partialPayments}
                isPaid={isPaid}
                setIsPaid={setIsPaid}
                isSubmitting={isSubmit}
            />

            <AddAccountBankModal
                showModal={showModalAddAccountBank}
                setShowModal={setShowModalAddAccountBank}
                onCreateAccountBank={handleCreateAccountBank}
            />

            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
            />

            {/* Modal estorno */}
            <Modal
                centered
                aria-labelledby="contained-modal-warning"

                show={isReversalModalOpen}
                onHide={() => setIsReversalModalOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <strong>Tem certeza que deseja estornar este registro ?</strong>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="warning" onClick={() => handleReversal()} disabled={isSubmit}>
                        {isSubmit ?
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>
                                    Aguarde...
                                </span>
                            </> :
                            <>
                                <span>
                                    Sim
                                </span>
                            </>}
                    </Button>
                    <Button variant="secondary" onClick={() => setIsReversalModalOpen(false)}>Não</Button>
                </Modal.Footer>
            </Modal>

            <form onSubmit={(evt) => onSubmit(evt)}
                className={'makeStyles-container-12'}
                autoComplete="off"
            >
                <Tabs activeKey={activeTab} onSelect={(tab: string) => setActiveTab(tab)} id="newproduct-form-tabs">
                    <Tab eventKey="detalhes-receita" title="Detalhes da Receita" className="mb-15">

                        <div className="row">

                            <div className="col-lg-3">
                                <TextField
                                    label="Nome da Receita*"
                                    margin="normal"
                                    variant="outlined"
                                    error={revenueNameError}
                                    className={classes.error}
                                    size="small"
                                    value={name}
                                    onChange={(evt) => {
                                        setName(evt.target.value)
                                        setRevenueNameError(evt.target.value ? false : true)
                                    }}
                                    disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                />
                            </div>

                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Conta bancária *"
                                    getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                    value={bankAccount}
                                    hasError={accountBankError}
                                    disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                    onSelect={handleChangeAccountBank}
                                    apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!bankAccount) return null;
                                        return loadedOptions.find((option) => option.id === Number(bankAccount)) ?? BankAccountService.getBankAccountById(bankAccount)
                                    }}
                                    onChangeTextField={(e) => setAccountBankError(e.target.value ? false : true)}
                                    renderAddButton={<ApiResourceSelectAddButton label="Adicionar Conta Bancária" onClick={() => setShowModalAddAccountBank(true)} />}
                                />
                            </div>

                            <div className="col-lg-3">
                                <TextField
                                    select
                                    label="Forma de Pagamento*"
                                    className={classes.textField}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={payment}
                                    onChange={(evt) => {
                                        setPayment(evt.target.value)
                                        setPayMethodsError(evt.target.value ? false : true)
                                    }}
                                    disabled={status == "paid" || recordType === 'billet' || isCanceled || user.isAccountant == "y" ? true : false}
                                    error={payMethodsError}
                                >
                                    <MenuItem key="0" value="">
                                        Selecione
                                    </MenuItem>

                                    {
                                        paymentOptions.map((payment, index) => (
                                            <MenuItem key={index + 1} value={payment.value}>
                                                {payment.value}
                                            </MenuItem>
                                        ))
                                    }
                                </TextField>
                            </div>

                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Plano de Contas *"
                                    getOptionLabel={(option: SubCategory) => option.name}
                                    value={dreSubCategoryId}
                                    disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                    hasError={accountPlanError}
                                    onSelect={(option) => setDreSubCategoryId(option?.id ?? 0)}
                                    apiSearchHandler={(typedText) => DreCategoryService.getDreSubCategoriesFiltered({ name: typedText }, 'revenue')}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!dreSubCategoryId) return null;
                                        return loadedOptions.find((option) => option.id === Number(dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(dreSubCategoryId)
                                    }}
                                    onChangeTextField={(e) => setAccountPlanError(e.target.value ? false : true)}
                                />
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-lg-4">
                                <ApiResourceSelect
                                    style={{ width: "100%" }}
                                    label="Cliente *"
                                    getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                                    value={customer}
                                    onSelect={(option) => { setCustomer(option ? String(option.id) : ''); setCustomerName(option?.name ?? '') }}
                                    apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true })}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!customer) return null;
                                        return loadedOptions.find((option) => option.id === Number(customer)) ?? CustomerService.getCustomerById(customer)
                                    }}
                                    onChangeTextField={(e) => setCustomerError(e.target.value ? false : true)}
                                    textFieldClass={classes.error}
                                    hasError={customerError}
                                    disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                    renderAddButton={(typedText) => <ApiResourceSelectAddButton label="Adicionar Cliente" onClick={() => handleClickAddCustomer(typedText)} />}
                                />
                            </div>

                            <div className="col-lg-2">
                                <TextField
                                    type="date"
                                    label="Vencimento*"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    value={dueDate}
                                    onChange={(evt) => {
                                        setDueDate(evt.target.value)
                                        setDueError(evt.target.value ? false : true)
                                    }}
                                    disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                    error={dueError}
                                    className={classes.error}
                                />
                            </div>

                            <div className="col-lg-2">
                                <NumericFormat
                                    label="Valor*"
                                    startAdornment="R$"
                                    error={valueError}
                                    className={classes.error}
                                    value={amount}
                                    onChange={(evt) => {
                                        setAmount(formatToFloat(evt.target.value))
                                        setValueError(evt.target.value ? false : true)
                                    }}
                                    disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                />
                            </div>

                            <div className="col-lg-2 d-flex align-items-center">
                                <TextField
                                    select
                                    label="Tipo de Registro"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={recordType}
                                    onChange={(evt) => handleChangeRecordType(evt.target.value)}
                                    disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                >
                                    <MenuItem key="0" value="account">
                                        Conta
                                    </MenuItem>

                                    <MenuItem key="1" value="billet">
                                        Boleto
                                    </MenuItem>
                                </TextField>
                            </div>


                            <div className="col-lg-2">
                                <TextField
                                    type="date"
                                    label="Data de emissão *"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    value={issuanceDate}
                                    onChange={(evt) => {
                                        setIssuanceDate(evt.target.value)
                                        setIssuanceDateError(evt.target.value ? false : true)
                                    }}
                                    disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                    error={issuanceDateError}
                                    className={classes.error}
                                />
                            </div>

                        </div>

                        {/* <div className="row">

                            <div className="col-lg-2">
                                <FormControlLabel
                                    label="Pago"
                                    className="ml-1"
                                    checked={isPaid}
                                    onChange={() => setIsPaid(state => !state)}
                                    control={
                                        <Checkbox color="primary"/>
                                    }
                                />
                            </div>
                        </div> */}

                    </Tab>

                    <Tab eventKey="detalhes-conta" title="Detalhes da Conta" className="mb-15">

                        <div className="row">
                            <div className="col-lg-6">
                                <TextField
                                    label="Nº do documento"
                                    className="ml-0"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={docNumber}
                                    onChange={(evt) => setDocNumber(evt.target.value)}
                                    disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                />
                            </div>

                            <div className="col-lg-3">
                                <ApiResourceSelect
                                    label="Centro de Custos"
                                    getOptionLabel={(option: CenterCost) => option.name}
                                    value={centerCost}
                                    disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                                    onSelect={(option) => setCenterCost(option ? String(option.id) : '')}
                                    apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                    getSelectedOption={(loadedOptions) => {
                                        if (!centerCost) return null;
                                        return loadedOptions.find((option) => option.id === Number(centerCost)) ?? CenterCostService.getCenterCostById(centerCost)
                                    }}
                                />
                            </div>

                        </div>

                        <div className="row">

                            {/* <div className="col-lg-4">
                                <TextField
                                    select
                                    label="Ocorrência"
                                    className="ml-0"
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={occurrence}
                                    onChange={(evt) => setOccurrence(evt.target.value)}
                                >
                                    <MenuItem key="0" value="unica">
                                        Única
                                    </MenuItem>

                                    <MenuItem key="1" value="parcelada">
                                        Parcelada
                                    </MenuItem>
                                </TextField>
                            </div> */}

                        </div>

                        <div className="row">
                            <div className="col-lg-5">
                                <label htmlFor="formFile" className="mt-3">Anexo</label>
                                <input className="form-control" type="file" id="formFile" disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false} />
                                <label htmlFor="formFile" className="mt-3">
                                    Máximo 2MB: JPG, JPEG, GIF, PNG, BMP.
                                </label>
                            </div>
                        </div>

                        <TextField
                            label="Observações"
                            multiline
                            rows="2"
                            className="ml-0"
                            margin="normal"
                            variant="outlined"
                            size="small"
                            value={comments}
                            onChange={(evt) => setComments(evt.target.value)}
                            disabled={status == "paid" || isCanceled || user.isAccountant == "y" ? true : false}
                        />
                    </Tab>

                    {
                        partialPayments
                            ?
                            <Tab eventKey="detalhes-pagamento" title="Detalhes do Pagamento" className="mb-15">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Data</TableCell>
                                            <TableCell>Valor baixa</TableCell>
                                            <TableCell>Juros</TableCell>
                                            <TableCell>Desconto</TableCell>
                                            <TableCell>Taxa</TableCell>
                                            <TableCell>Acréscimo</TableCell>
                                            <TableCell>Valor pago</TableCell>
                                            <TableCell>Conta bancária</TableCell>
                                            <TableCell>Forma pag.</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            partialJson.slice(pagesPartial * rowsPerPagePartial, pagesPartial * rowsPerPagePartial + rowsPerPagePartial).map((value, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>{value.payedDate.split('-').reverse().join('/')}</TableCell>
                                                        <TableCell>{formatCurrency(value.writeOffValue)}</TableCell>
                                                        <TableCell>{formatCurrency(value.feeValue)}</TableCell>
                                                        <TableCell>{formatCurrency(value.discountValue)}</TableCell>
                                                        <TableCell>{formatCurrency(value.taxValue)}</TableCell>
                                                        <TableCell>{formatCurrency(value.accValue)}</TableCell>
                                                        <TableCell>{formatCurrency(value.totalPaid)}</TableCell>
                                                        <TableCell>{value.bankAccountName ?? ''}</TableCell>
                                                        <TableCell>{value.payment}</TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>

                                <TablePagination
                                    labelRowsPerPage="Linhas por página"
                                    page={pagesPartial}
                                    component="div"
                                    count={partialJson.length}
                                    rowsPerPage={rowsPerPagePartial}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    backIconButtonProps={{
                                        'aria-label': 'Página Anterior',
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': 'Próxima Página',
                                    }}
                                    onChangePage={(_, next) => handleChangePagePartial(next)}
                                    onChangeRowsPerPage={(evt) => handleRowsPerPagePartial(Number(evt.target.value))}
                                />
                            </Tab>
                            : <></>
                    }
                </Tabs>

                {
                    isCanceled
                        ?
                        <Alert variant="secondary" className="col-lg-6">
                            <Alert.Heading>Motivo do cancelamento</Alert.Heading>
                            <p>
                                {reasonCanceled}
                            </p>
                        </Alert>
                        : <></>
                }

                <div className='row col-lg-6 d-flex flex-row'>
                    {
                        user.isAccountant == "n"
                            ?
                            <>
                                {
                                    status == "paid"
                                        ?
                                        <>
                                            <Button
                                                type='button'
                                                size="lg"
                                                className='mr-3'
                                                variant="secondary"
                                                onClick={() => setIsReversalModalOpen(true)}
                                            >
                                                <span>Estornar Conta</span>
                                            </Button>
                                        </>
                                        :
                                        <>
                                            {
                                                !isCanceled
                                                    ?
                                                    <Button
                                                        type='submit'
                                                        className='mr-3'
                                                        variant="primary"
                                                        disabled={isSubmit}
                                                    >

                                                        {isSubmit ? <>

                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />

                                                            <span className='ml-2'>
                                                                Aguarde...
                                                            </span>

                                                        </> : <>

                                                            <span>
                                                                Salvar
                                                            </span>

                                                        </>}


                                                    </Button>
                                                    : <></>
                                            }
                                        </>
                                }
                            </>
                            : <></>
                    }

                    {
                        !isCanceled
                            ?
                            <>
                                <Button
                                    type='button'
                                    size="lg"
                                    className='mr-3'
                                    variant="secondary"
                                    onClick={handleOpenChangeSituationModal}
                                >
                                    <span>{status == "paid" ? 'Ver pagamento' : 'Liquidar conta'}</span>
                                </Button>
                            </>
                            : <></>
                    }

                    <Link href='/contas-a-receber' className='btn btn-secondary'>Cancelar</Link>
                </div>

            </form>
        </div>
    );
}
