import React, { useState, useCallback, useLayoutEffect, useRef } from "react";
import { format } from "date-fns";
import { Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import api from "../../services/Api";
import { Budget } from "../../types/Budget";
import {
  ObjectOrder,
  OrdersSituation,
  HeadDataBaseProps,
  BodyDataBaseProps,
  ListWithModalChangeSituation,
  LoadDataParams,
} from "../../components/ListWithModalChangeSituation";
import { formatCurrency, formatToFloat } from "../../utils/formatCurrency";
import "../../style.css";
import {
  Collapse,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { NumericFormat } from "../../components/NumericFormat";
import { Search } from "../../components/Search";
import useBackendLoad from "../../hooks/backendReload";
import CustomerService from "../../services/CustomerService";
import { BsVariant } from "../../types/BsVariant";
import ModalLinkedFieldsError from "../../components/ModalLinkedFieldsError";
import { useLinkedFieldsError } from "../../hooks/linkedFieldsError";
import BudgetService from "../../services/BudgetService";
import { useSelector } from "react-redux";
import ModalError from "../../components/ModalError";
import useCompany from "../../hooks/company";
import { makeScheduleMessage } from "./watsappSchedule";

type Filters = {
  searchQuery: string;
  situation: string;
  budgetDateMin: string;
  budgetDateMax: string;
  valueMin: number;
  valueMax: number;
};

const headData: HeadDataBaseProps[] = [
  { reference: "id", value: "Nº" },
  { reference: "date", value: "Data" },
  { reference: "client", value: "Cliente" },
  { reference: "reference", value: "Referência" },
  { reference: "amount", value: "Valor Total" },
  {
    reference: "situation",
    value: "Situação",
    situation: true,
    notSortable: true,
  },
];

export function ListBudgets() {
  const { user } = useSelector((state: any) => state.auth);
  const { companies } = useCompany({
    only: user?.companyId,
    appendSubscriptionPlan: true,
  });

  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
  const [situationData, setSituationData] = useState<ObjectOrder[]>([]);
  const [countTotalBudgets, setCountTotalBudgets] = useState(0);

  const [searchQuery, setSearchQuery] = useState("");
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [situation, setSituation] = useState("");
  const [valueMin, setValueMin] = useState(0);
  const [valueMax, setValueMax] = useState(0);
  const [budgetDateMin, setBudgetDateMin] = useState("");
  const [budgetDateMax, setBudgetDateMax] = useState("");
  const filtersRef = useRef<Filters | null>(null);

  const [isEmitting, setIsEmitting] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [linkedFieldsErrorMessage, setLinkedFieldsErrorMessage] = useState("");
  const [linkedFieldsRedirectUrl, setLinkedFieldsRedirectUrl] = useState("");
  const {
    showModalLinkedFieldsError,
    setShowModalLinkedFieldsError,
    linkedFieldsErrors,
    setLinkedFieldsErrors,
  } = useLinkedFieldsError();

  const { triggerLoad, setTriggerLoad, reloadData } = useBackendLoad();

  const {
    location: { pathname },
    push: pushHistory,
  } = useHistory();

  // useEffect(() => {
  //     handleClickSearch();
  // }, [budgets]);

  const loadData = useCallback(
    async ({
      rowsPerPage,
      currentPage,
      sortDirection,
      sortReference,
    }: LoadDataParams) => {
      const { data } = await api.get<{ rows: Budget[]; count: number }>(
        "budgets",
        {
          params: {
            skip: rowsPerPage * currentPage,
            take: rowsPerPage,
            filters: filtersRef.current
              ? JSON.stringify(filtersRef.current)
              : undefined,
            sortReference,
            sortDirection,
          },
        }
      );

      const { rows, count } = data;

      const initialSituationData = rows.map(
        ({ id, installments, situation: situationResponse }) => {
          const situation: OrdersSituation[] = JSON.parse(situationResponse);

          return {
            id,
            situation,
            installments,
          };
        }
      );

      for (const budget of rows) {
        budget.customerName = CustomerService.getCustomerName(
          budget.customerEntity,
          budget.customerName
        );
      }

      setSituationData(initialSituationData);
      setBudgets(rows);
      setCountTotalBudgets(count);
    },
    []
  );

  useLayoutEffect(() => {
    const list: BodyDataBaseProps[][] = [];
    const aux = budgets;

    aux.forEach((req) => {
      const amount = formatCurrency(req.totalValue);
      const status =
        req.status === "open"
          ? "Em aberto"
          : req.status === "progress"
          ? "Em andamento"
          : req.status === "attended"
          ? "Atendido"
          : req.status === "canceled"
          ? "Cancelado"
          : req.status === "aproved"
          ? "Aprovado"
          : req.status === "launched"
          ? "Lançado em Pedidos"
          : "";

      // Formatando data
      const [year, month, day] = req.budgetDate.split("-");
      const date = new Date(Number(year), Number(month) - 1, Number(day));
      const formatedDate = format(date, "dd/MM/yyyy");

      const data: BodyDataBaseProps[] = [
        { for: "id", value: String(req.id), id: true },
        { for: "date", value: formatedDate },
        { for: "client", value: req.customerName },
        { for: "reference", value: req.reference ?? "" },
        { for: "amount", value: amount },
        { for: "situation", value: status },
      ];

      list.push(data);
    });

    setBodyData(list);
  }, [budgets]);

  const handleClickDelete = useCallback(async (id: string) => {
    try {
      const filtered = budgets.filter((budget) => budget.id !== Number(id));

      await api.delete(`budgets/${id}`);

      setBudgets([...filtered]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleClickEdit = useCallback(
    (id: string) => {
      pushHistory(`/orcamentos/${id}`);
    },
    [pathname]
  );

  const handleClickClone = useCallback(
    (id: string) => {
      pushHistory(`${pathname}/duplicar/${id}`);
    },
    [pathname]
  );

  const handleChangeOrder = useCallback(
    async (id: number, situation: OrdersSituation) => {
      try {
        const aux = budgets;
        const aux2 = situationData;
        const budget = aux.find((budget) => budget.id === id);
        const sitData = aux2.find((situ) => situ.id === id);

        if (!budget || !sitData) {
          throw new Error();
        }

        const hasSituation = sitData.situation;

        if (!hasSituation) {
          throw new Error();
        }

        hasSituation.push(situation);

        budget.status = situation.statusSituation;
        budget.situation = JSON.stringify(sitData.situation);

        await api.put(`budgets/${id}`, budget);

        setSituationData(aux2);
        setBudgets([...aux]);
      } catch (error) {
        console.log(error);
      }
    },
    [budgets, situationData]
  );

  const handleClickAdd = useCallback(() => {
    pushHistory(`${pathname}/adicionar`);
  }, []);

  const handleClickEmitBudget = useCallback(
    async (budgetId: string) => {
      const foundBudget = budgets.find(
        (budgetObj) => budgetObj.id === Number(budgetId)
      );

      if (!foundBudget) {
        return;
      }

      const linkErrors = BudgetService.verifyLinkedFields(foundBudget);

      if (linkErrors.length > 0) {
        setLinkedFieldsErrors(linkErrors);
        setLinkedFieldsRedirectUrl(`${pathname}/${budgetId}`);
        setLinkedFieldsErrorMessage(
          "Para emitir é necessário completar o cadastro do Orçamento!"
        );
        setShowModalLinkedFieldsError(true);
        return;
      }

      const limitCreditInfo = await CustomerService.getCustomerLimitCreditInformation(
        foundBudget.customerId ?? 0
      );

      const totalValue = formatToFloat(foundBudget.totalValue);

      if (
        limitCreditInfo &&
        totalValue + limitCreditInfo.currentCreditThisPeriod >
          limitCreditInfo.limit
      ) {
        const limitFormatted = formatCurrency(limitCreditInfo.limit);
        const currentFormatted = formatCurrency(
          limitCreditInfo.currentCreditThisPeriod
        );
        setMsgError(
          `Não é possível emitir o pedido de venda pois o cliente não tem limite de crédito suficiente. Contate o administrador do sistema. (Limite: ${limitFormatted} Débito atual: ${currentFormatted})`
        );
        setShowModalError(true);
        return;
      }

      emitBudget(budgetId);

      makeScheduleMessage(foundBudget, companies[0]);
    },
    [budgets, companies]
  );

  const emitBudget = useCallback(
    async (budgetId: string) => {
      const aux = budgets;

      const soIndex = aux.findIndex((so) => so.id === Number(budgetId));
      const responseBudget = await api.get(`/budgets/${budgetId}`);

      const rawBudget = {
        status: "launched",
      };
      await api.put(`budgets/${budgetId}`, rawBudget);

      const raw = {
        ...responseBudget.data,
        id: undefined,
        requestDate: responseBudget.data.budgetDate,
      };

      await api.post("requests", raw);

      aux[soIndex].status = "launched";

      for (const budget of aux) {
        budget.customerName = CustomerService.getCustomerName(
          budget.customerEntity,
          budget.customerName
        );
      }

      setBudgets([...aux]);
    },
    [budgets]
  );

  const emitButtonShowCondition = useCallback(
    (id: string) => {
      const row = budgets.find((budget) => String(budget.id) === id);

      if (!row) return false;

      return row.status !== "launched";
    },
    [budgets]
  );

  const handleBeforeOpenSendEmailOrWhatsappModal = useCallback(
    (id: number, type: "email" | "whatsapp") => {
      const foundBudget = budgets.find(
        (budgetObj) => budgetObj.id === Number(id)
      );

      if (!foundBudget) {
        return false;
      }

      const linkErrors = BudgetService.verifyCustomerLink(foundBudget);

      if (linkErrors.length > 0) {
        setLinkedFieldsErrors(linkErrors);
        setLinkedFieldsRedirectUrl(`${pathname}/${id}`);
        setLinkedFieldsErrorMessage(
          `Para enviar ${
            type === "email" ? "email" : "whatsapp"
          } é necessário vincular um cliente cadastrado ao Orçamento!`
        );
        setShowModalLinkedFieldsError(true);
        return false;
      }

      return true;
    },
    [budgets]
  );

  const clearSearch = () => {
    setSearchQuery("");
    setSituation("");
    setValueMin(0);
    setValueMax(0);
    setBudgetDateMin("");
    setBudgetDateMax("");
  };

  const handleClickSearch = useCallback(async () => {
    filtersRef.current = {
      searchQuery,
      situation,
      valueMin,
      valueMax,
      budgetDateMin,
      budgetDateMax,
    };

    reloadData();
  }, [
    budgets,
    searchQuery,
    situation,
    valueMin,
    valueMax,
    budgetDateMin,
    budgetDateMax,
  ]);

  return (
    <div className="card card-body pt-4 newProductWrapper">
      <ModalError
        msgError={msgError}
        showModalError={showModalError}
        setShowModalError={setShowModalError}
      />

      <ModalLinkedFieldsError
        message={linkedFieldsErrorMessage}
        errors={linkedFieldsErrors}
        showModal={showModalLinkedFieldsError}
        setShowModal={setShowModalLinkedFieldsError}
        redirect={linkedFieldsRedirectUrl}
      />
      <div className="row d-flex align-items-center">
        <div className="col-lg-9 mt-3">
          {user.isAccountant == "n" ? (
            <Button
              type="button"
              variant="success"
              className="mr-2"
              onClick={() => handleClickAdd()}
            >
              Novo orçamento
            </Button>
          ) : (
            <></>
          )}
        </div>
        <div className="col-lg-3 mt-3">
          <Search
            query={searchQuery}
            setQuery={setSearchQuery}
            setCollapseAdvancedSearch={setAdvancedSearch}
            onClickSearch={handleClickSearch}
          />
        </div>
      </div>
      <Collapse in={advancedSearch}>
        <div className="row d-flex align-items-center">
          <div className="col-lg-2">
            <TextField
              select
              size="small"
              label="Situação"
              margin="normal"
              variant="outlined"
              value={situation}
            >
              <MenuItem key="0" value="" onClick={() => setSituation("")}>
                Nenhum
              </MenuItem>
              <MenuItem
                key="1"
                value="open"
                onClick={() => setSituation("open")}
              >
                Em Aberto
              </MenuItem>
              <MenuItem
                key="1"
                value="launched"
                onClick={() => setSituation("launched")}
              >
                Lançado em Pedidos
              </MenuItem>
            </TextField>
          </div>
          <div className="col-lg-2">
            <NumericFormat
              label="Valor"
              startAdornment="DE"
              value={valueMin}
              onChange={(evt) => setValueMin(formatToFloat(evt.target.value))}
            />
          </div>
          <div className="col-lg-2">
            <NumericFormat
              label="Valor"
              startAdornment="ATÉ"
              value={valueMax}
              onChange={(evt) => setValueMax(formatToFloat(evt.target.value))}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              type="date"
              label="Data"
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">DE</InputAdornment>
                ),
              }}
              value={budgetDateMin}
              onChange={(e) => setBudgetDateMin(e.target.value)}
            />
          </div>
          <div className="col-lg-3">
            <TextField
              type="date"
              label="Data"
              margin="normal"
              variant="outlined"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">ATÉ</InputAdornment>
                ),
              }}
              value={budgetDateMax}
              onChange={(e) => setBudgetDateMax(e.target.value)}
            />
          </div>
          <div className="col-12 d-flex justify-content-end">
            <Button onClick={handleClickSearch} className="mr-3">
              Pesquisar
            </Button>

            <Button onClick={clearSearch}>Limpar</Button>
          </div>
        </div>
      </Collapse>

      <div className="mt-3">
        <ListWithModalChangeSituation
          budget
          order
          typeOrder="budget"
          headData={headData}
          bodyData={bodyData}
          onEdit={handleClickEdit}
          onClone={handleClickClone}
          objectOrder={situationData}
          onDelete={handleClickDelete}
          onChangeOrder={handleChangeOrder}
          onBeforeOpenSendEmailModal={(id) =>
            handleBeforeOpenSendEmailOrWhatsappModal(id, "email")
          }
          onBeforeOpenSendWhatsappModal={(id) =>
            handleBeforeOpenSendEmailOrWhatsappModal(id, "whatsapp")
          }
          sortable={true}
          loadData={loadData}
          totalCount={countTotalBudgets}
          triggerLoad={triggerLoad}
          setTriggerLoad={setTriggerLoad}
          customButtons={
            user.isAccountant == "n"
              ? [
                  {
                    class: "btn-light-primary p-2 mr-3",
                    content: isEmitting ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ml-2">Aguarde...</span>
                      </>
                    ) : (
                      <span>Emitir</span>
                    ),
                    variant: BsVariant.SECONDARY,
                    popup: "Lançar orçamento em pedidos",
                    onClick: handleClickEmitBudget,
                    showCondition: emitButtonShowCondition,
                  },
                ]
              : []
          }
        />
      </div>
    </div>
  );
}
