import React, { useCallback } from "react";
import { NewPromotionForm } from "../../../components/Product/Promotion/NewPromotionForm";
import { useParams } from 'react-router-dom';

type UrlParam = {
   id: string;
};

export function NewPromotion() {
   const { id } = useParams<UrlParam>();

   const handleCloseModalLimit = useCallback(() => {
      window.location.href = `/produtos/${id}/promocoes`;
   }, []);

   function handleClosePromotionCreatedSuccessModal() {
      window.location.href = `/produtos/${id}/promocoes`;
   }

   return (
      <NewPromotionForm
         onCloseModalLimit={handleCloseModalLimit}
         onClosePromotionCreatedSuccessModal={handleClosePromotionCreatedSuccessModal}
      />
   );
}