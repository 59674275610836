import React from "react";
import { Button, Spinner } from "react-bootstrap";

interface CustomButtonProps
  extends React.ComponentPropsWithoutRef<typeof Button> {
  loading?: boolean;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  loading,
  children,
  ...props
}) => (
  <Button {...props} disabled={loading || props.disabled}>
    {loading ? (
      <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        {" Carregando..."}
      </>
    ) : (
      children
    )}
  </Button>
);

export default CustomButton;
