import { useCallback, useEffect, useState } from "react";
import { BodyDataBaseProps, HeadDataBaseProps, ListWithModalChangeSituation, LoadDataParams } from "../../../components/ListWithModalChangeSituation";
import { ProductPromotion } from "../../../types/ProductPromotion";
import useBackendLoad from "../../../hooks/backendReload";
import { useHistory } from "react-router";
import api  from "../../../services/Api";
import { useParams } from 'react-router-dom';
import { formatCurrency, formatToFloat } from '../../../utils/formatCurrency';
import React from "react";
import { Button } from "react-bootstrap";
import { PromotionTypesEnum } from "../../../../enums/promotion-types.enums";
import {api as apiUrl} from '../../../services/ApiURL';
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { formatDate } from "../../../utils/dateFormat";

const headData: HeadDataBaseProps[] = [
    { reference: "id", value: "Nº" },
    { reference: "promotionValue", value: "Valor"},
    { reference: "startDate", value: "Data de início" },
    { reference: "endDate", value: "Data de término" },
    { reference: "description", value: "Descrição" },
    { reference: "creativeUrl", value: "URL" },
    { reference: "photo", value: "Foto" },
];

type UrlParam = {
    id: string
 }

export function ListPromotions() {
    const [countTotalPromotions, setCountTotalPromotions] = useState(0);
    const [bodyData, setBodyData] = useState<BodyDataBaseProps[][]>([]);
    const [all, setAll] = useState<ProductPromotion[]>([]);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();
    const { id } = useParams<UrlParam>();
    const { location: { pathname }, push: pushHistory } = useHistory();

    const loadData = useCallback(async () => {
        const { data } = await api.get<ProductPromotion[]>(`product/${id}/promotions`);

        const mapped = data.map((promotion: ProductPromotion) => {
            return {
                ...promotion,
                promotionValue: formatToFloat(promotion.promotionValue)
            };
        });

        setAll(mapped);
        setCountTotalPromotions(mapped.length);
    }, []);

    async function getBodyData() {
        const list: BodyDataBaseProps[][] = [];
        const aux = all;

        for (const promotion of aux) {
            let promotionValue;
            if(promotion.promotionType === PromotionTypesEnum.PERCENTAGE){
                promotionValue = formatToFloat(promotion.promotionValue) + '%';
            }
            if(promotion.promotionType === PromotionTypesEnum.DISCOUNT_IN_VALUE){
                promotionValue = formatCurrency(promotion.promotionValue);
            }
            
            const data: BodyDataBaseProps[] = [
                { for: "id", value: String(promotion.id), id: true },
                {for: "promotionValue", value: promotionValue ?? ''},
                {for: "startDate", value: formatDate(promotion.startDate) ?? ''},
                {for: "endDate", value: formatDate(promotion.endDate) ?? ''},
                { for: "description", value: promotion.description ?? '' },
                {for: "creativeUrl", value: promotion.creativeUrl ?? ''},
                {for: "photo", 
                    value: promotion.photo ? toAbsoluteUrl(`${apiUrl}/${promotion.photo}`) : '', 
                    jsx: promotion.photo ? <img src={toAbsoluteUrl(`${apiUrl}/${promotion.photo}`)} alt="Promotion" style={{ maxWidth: '100px', maxHeight: '50px' }} /> : undefined},
            ];

            list.push(data);
        }

        setBodyData(list);
    }

    useEffect(() => {
        getBodyData();
    }, [all]);

    const handleClickAdd = useCallback(() => {
        pushHistory(`${pathname}/adicionar`);
    }, []);

    const handleClickEdit = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}`,);
    }, []);

    const handleClickSend = useCallback((id: string) => {
        pushHistory(`${pathname}/${id}/enviar`,);
    }, []);

    const handleClickDelete = useCallback(async (promotionId: string) => {
        await api.delete(`product/promotions/${promotionId}/delete`);
        window.location.href = `/produtos/${id}/promocoes`;
    }, []);

    return (
        <div className="card card-body pt-4 newProductWrapper">
        <div className="row d-flex align-items-center">
            <div className="col-lg-9 mt-3">
                <Button
                    type="button"
                    variant="success"
                    className="mr-2"
                    onClick={() => handleClickAdd()}
                >
                    Adicionar promoção
                </Button>
            </div>
        </div>

        <div className="mt-3">
            <ListWithModalChangeSituation
                headData={headData}
                bodyData={bodyData}
                sortable={true}
                loadData={loadData}
                totalCount={countTotalPromotions}
                triggerLoad={triggerLoad}
                setTriggerLoad={setTriggerLoad}
                onEditPromotion={handleClickEdit}
                onDeletePromotion={handleClickDelete}
                onSendPromotion={handleClickSend}
            />
        </div>
    </div>
    );
}