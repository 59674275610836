import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

// Dashboard
import { DashboardPage } from "./pages/Dashboard/DashboardPage";
import { AccountingDashboardPage } from "./pages/Accounting/Dashboard/AccountingDashboardPage";

//Records
import { User } from "./routes/User";
import { Customer } from "./routes/Customer";
import { Products } from "./routes/Products";
import { Carriers } from "./routes/Carriers";
import { Sellers } from "./routes/Sellers";
import { SellerConsignments } from "./routes/SellerConsignments";
import { AccountsBank } from "./routes/AccountsBank";
import { PriceList } from "./routes/PriceList";
import { CategoryProduct } from "./routes/CategoryProduct";
import { Brand } from "./routes/Brand";
import { CashFlow } from "./routes/CashFlow";
import { AccountsPlan } from "./routes/AccountsPlan";

//Company
import { Company } from "./routes/Company";

//Company sales
import { Requests } from "./routes/Requests";
import { Budgets } from "./routes/Budgets";
import { Nfe } from "./routes/Nfe";

//Company purchases
import { Entry } from "./routes/Entry";
import { PurchaseOrder } from "./routes/PurchaseOrder";
import { Quotation } from "./routes/Quotation";

//Company financial
import { BillsToPay } from "./routes/BillsToPay";
import { BillsToReceive } from "./routes/BillsToReceive";
import { RecurringExpenses } from "./routes/RecurringExpenses";
import { Extract } from "./routes/Extract";
import { BankReconciliation } from "./routes/BankReconciliation";

//Company services
import { Nfse } from "./routes/Nfse";
import { ServiceOrder } from "./routes/ServiceOrder";
import { ServiceContract } from "./routes/ServiceContract";

//Company carriers

//Reports
import { Report } from "./routes/Report";

//Config
import { MyAccount } from "./routes/MyAccount";
import { Config } from "./routes/Config";

//Others
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { Parameters } from "./routes/Parameters";
import Pdv from "./routes/Pdv";
import { StockLocations } from "./routes/StockLocations";
import { Nfce } from "./routes/Nfce";
import { SubscriptionPlan } from "./routes/SubscriptionPlan";
import { Profile } from "./routes/Profile";
import { UserProfile } from "./routes/UserProfile";
import { SubscriptionPlanCompany } from "./routes/SubscriptionPlanCompany";
import { Branches } from "./routes/Branches";
import { NotifyDueDate } from "./routes/NotifyDueDate";

// DRE
import { Dre } from "./routes/Dre";

// Accountant
import ListAccounting from "./pages/Accounting/Report/List-Accounting";
import { Indications } from "./routes/Indications";

import { useSelector } from "react-redux";
import { ListAccountingCompanies } from "./pages/Accounting/Customers/List-AccountingCompanies";
import { Invoice } from "./routes/Invoice";
import { AlertCompanyPaymentRequired } from "./pages/Accounting/Alert-CompanyPaymentRequired";
import { Bpm } from "./routes/Bpm";
import { Help } from "./routes/Help";

// import {ConsumetReceipt} from "./pages/ConsumerReceipt";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const { user } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Dashboard route */}
        {<Redirect exact from="/" to="/painel" />}
        <ContentRoute
          path="/painel"
          component={
            user.isAccountant === "y" ? AccountingDashboardPage : DashboardPage
          }
        />

        {/* Records routes */}
        <Route path="/usuarios" component={User} />
        <Route path="/perfis-usuario" component={UserProfile} />
        <Route path="/clientes" component={Customer} />
        <Route path="/produtos" component={Products} />
        <Route path="/transportadoras" component={Carriers} />
        <Route
          path="/vendedores/:sellerId/consignacoes"
          component={SellerConsignments}
        />
        <Route path="/vendedores" component={Sellers} />
        <Route path="/contas-bancarias" component={AccountsBank} />
        <Route path="/lista-de-precos" component={PriceList} />
        <Route path="/categorias-produto" component={CategoryProduct} />
        <Route path="/marcas" component={Brand} />
        <Route
          path="/parametros/locais-de-estoque"
          component={StockLocations}
        />
        <Route path="/empresas-filiais" component={Branches} />
        <Route path="/plano-de-contas" component={AccountsPlan} />
        <Route path="/bpm" component={Bpm} />

        {/* Notifications */}
        <Route path="/notificacoes" component={NotifyDueDate} />

        {/* Company routes */}
        <Route path="/empresas" component={Company} />

        {/* sales */}
        <Route path="/notas-fiscais" component={Nfe} />
        <Route path="/pedidos" component={Requests} />
        <Route path="/orcamentos" component={Budgets} />
        <Route path="/pdv" component={Pdv} />
        <Route path="/notas-fiscais-consumidor" component={Nfce} />

        {/* purchases */}
        <Route path="/entrada-de-mercadoria" component={Entry} />
        <Route path="/ordem-de-compra" component={PurchaseOrder} />
        <Route path="/cotacoes" component={Quotation} />

        {/* financial */}
        <Route path="/contas-a-pagar" component={BillsToPay} />
        <Route path="/contas-a-receber" component={BillsToReceive} />
        <Route path="/despesas-recorrentes" component={RecurringExpenses} />
        <Route path="/extrato" component={Extract} />
        <Route path="/conciliacao-bancaria" component={BankReconciliation} />
        <Route path="/dre" component={Dre} />
        <Route path="/ajuda" component={Help} />

        {/* services */}
        <Route path="/notas-fiscais-servico" component={Nfse} />
        <Route path="/ordem-de-servico" component={ServiceOrder} />
        <Route path="/contrato-servico" component={ServiceContract} />

        {/* carriers */}

        {/* Cash Flow */}
        <Route path="/fluxo-de-caixa" component={CashFlow} />

        {/* Reports routes */}
        <Route path="/relatorios" component={Report} />

        {/* Others routes */}
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/parametros" component={Parameters} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Route path="/user-profile" component={UserProfilepage} />

        {/* Subscription Plan routes */}
        <Route path="/planos" component={SubscriptionPlan} />
        <Route path="/plano" component={SubscriptionPlanCompany} />
        {/* Profile routes */}
        <Route path="/perfis-acesso" component={Profile} />

        {/* Config routes */}
        <Route path="/minha-conta" component={MyAccount} />
        <Route path="/config" component={Config} />

        {/* Invoice routes */}
        <Route path="/faturas" component={Invoice} />

        {/* Accounting routes */}
        <Route path="/contabil" component={ListAccounting} />
        <Route path="/indicacoes" component={Indications} />
        <Route path="/contador/empresas" component={ListAccountingCompanies} />
        <Route
          path="/contador/empresa-bloqueada"
          component={AlertCompanyPaymentRequired}
        />

        {/* <Route path="/nota-fiscal-do-consumidor" component={ConsumetReceipt} /> */}

        {/* 404 routes */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
