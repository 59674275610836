import React, { useState } from "react";

import {
  Grid,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";

import "../../../style.css";
import ModalSuccess from "../../../components/ModalSuccess";
import { ProductPromotion } from "../../../types/ProductPromotion";
import { validatePromotionDate } from "../../../pages/Product/Promotions/validation";
import { makeScheduleMessage } from "../../../utils/watsappSchedule";
import useCompany from "../../../hooks/company";
import { useSelector } from "react-redux";
import apiBFF from "../../../services/ApiBFF";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  error: {
    "& .Mui-error": {
      color: "#f64e60 !important",
    },
    "& .MuiFormHelperText-root": {
      color: "#f64e60 !important",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f64e60 !important",
    },
  },
}));

type SendPromotionFormProps = {
  onClosePromotionSentSuccessModal: () => void;
  promotion?: ProductPromotion;
  customers: any[];
};

type UrlParam = {
  promotionId: string;
  id: string;
};

export function SendPromotionForm({
  onClosePromotionSentSuccessModal,
  promotion,
  customers,
}: SendPromotionFormProps) {
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [sendAt, setSendAt] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalExist, setShowModalExist] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  // const [budget, setBudget] = useState<any>();

  const handleCustomerSelection = (customerId: string) => {
    setSelectedCustomers((prevSelected) => {
      const newSelected = prevSelected.includes(customerId)
        ? prevSelected.filter((id) => id !== customerId)
        : [...prevSelected, customerId];

      // updateBudget(newSelected);
      return newSelected;
    });
  };

  const { user } = useSelector((state: any) => state.auth);

  const { companies } = useCompany({
    only: user?.companyId,
    appendSubscriptionPlan: true,
  });

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCustomers([]);
      // updateBudget([]);
    } else {
      const allCustomerIds = customers.map((customer) => customer.id);
      setSelectedCustomers(allCustomerIds);
      // updateBudget(allCustomerIds);
    }
    setSelectAll(!selectAll);
  };

  // const updateBudget = (customerIds: string[]) => {
  //   const selectedCustomersData = customers.filter((customer) =>
  //     customerIds.includes(customer.id)
  //   );

  //   const customerEntities = selectedCustomersData.map((customer) => ({
  //     name: customer.name,
  //     cell: customer.cell,
  //   }));

  //   setBudget({
  //     opticsData: JSON.stringify({ medicalAppointmentDate: sendAt }),
  //     customerEntity: customerEntities,
  //   });
  // };

  async function inputsVerify(): Promise<boolean> {
    if (!message) {
      setIsSubmit(false);
      setMsgError("Escreva a mensagem a ser enviada para o cliente!");
      setShowModalExist(true);

      return false;
    }

    if (!sendAt) {
      setIsSubmit(false);
      setMsgError("Preencha a data de envio da promoção!");
      setShowModalExist(true);

      return false;
    }

    const sendAtValidation = validatePromotionDate(sendAt);
    if (!sendAtValidation.isValid) {
      setIsSubmit(false);
      setMsgError(sendAtValidation.message);
      setShowModalExist(true);

      return false;
    }

    return true;
  }

  async function handleSubmit() {
    if (!companies[0]?.email) return;
    setIsSubmit(true);

    // const dataLoginBFF = await apiBFF.post("millionzap/login", {
    //   email: userEmail,
    //   // passwordHash,
    // });

    if (!(await inputsVerify())) return;

    const selectedCustomersData = customers.filter((customer) =>
      selectedCustomers.includes(customer.id)
    );

    try {
      const { data } = await apiBFF.get(
        `millionzap/user/hash/${companies[0].email}`
      );
      const { passwordHash } = data;

      const dataLoginBFF = await apiBFF.post("millionzap/login", {
        email: companies[0].email,
        passwordHash,
      });

      const { token, user: userMillionZap } = dataLoginBFF.data;

      for (const customer of selectedCustomersData) {
        await makeScheduleMessage({
          companyMail: companies[0].email,
          user: { token, id: userMillionZap.id },
          contact: {
            name: customer.name,
            number: customer.cell,
          },
          schedule: {
            body: message,
            sendAt: sendAt,
            userId: user.id,
          },
        });
      }

      setShowModalSuccess(true);
    } catch (error) {
      console.log("error", error);
      setMsgError("Ocorreu um erro ao processar a requisição");
      setShowModalExist(true);
    }

    setIsSubmit(false);
  }

  function handleCloseModalSuccess() {
    onClosePromotionSentSuccessModal();
  }

  return (
    <div className="row card card-body pt-2 newProductWrapper">
      <ModalSuccess
        msgModal={"Envio de promoção agendado com sucesso!"}
        showModal={showModalSuccess}
        setShowModal={setShowModalSuccess}
        onClose={handleCloseModalSuccess}
      />

      <Modal
        show={showModalExist}
        onHide={() => setShowModalExist(false)}
        aria-labelledby="contained-modal-warning"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
            Atenção
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <b>{msgError}</b>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalExist(!showModalExist)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="makeStyles-container-12">
        <Grid container spacing={3} className="mb-3">
          <Grid item md={6}>
            <TextField
              size="small"
              className={classes.error}
              label="Mensagem"
              margin="normal"
              variant="outlined"
              value={message}
              InputLabelProps={{ shrink: true }}
              name="description"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mb-3">
          <Grid item md={4}>
            <TextField
              size="small"
              className={classes.error}
              label="Data de envio"
              InputLabelProps={{ shrink: true }}
              type="date"
              margin="normal"
              variant="outlined"
              value={sendAt}
              name="sendAt"
              onChange={(e) => {
                setSendAt(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} className="mb-3">
          <Grid item md={12}>
            <label>Clientes</label>
          </Grid>
          <Grid item md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  name="selectAll"
                />
              }
              label="Selecionar todos"
            />
          </Grid>
          <div
            style={{ maxHeight: "216px", overflowY: "scroll", width: "100%" }}
          >
            {customers.map((customer) => (
              <Grid
                item
                md={4}
                key={customer.id}
                style={{ maxHeight: "28px", margin: "4px" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedCustomers.includes(customer.id)}
                      onChange={() => handleCustomerSelection(customer.id)}
                      name={customer.name}
                    />
                  }
                  label={customer.name}
                />
              </Grid>
            ))}
          </div>
        </Grid>

        <Grid style={{ marginTop: "26px" }}>
          <Button
            type="button"
            className="mr-0 mt-0 mb-2"
            variant="primary"
            disabled={isSubmit}
            onClick={handleSubmit}
          >
            {isSubmit ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="ml-2">Aguarde...</span>
              </>
            ) : (
              <span>Enviar</span>
            )}
          </Button>
        </Grid>
      </div>
    </div>
  );
}
