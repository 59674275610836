import React, { useCallback, useEffect, useState } from "react";
import { InputAdornment, TextField } from "@material-ui/core";

import { useBudget, Product } from "./context";
import { NumericFormat } from "../../components/NumericFormat";
import { formatToFloat } from "../../utils/formatCurrency";
import ProductSelect from "../../components/ProductSelect";
import { useSelector } from "react-redux";
import { Product as SelectableProduct } from "../../services/ProductService";
import { SellerConsignmentProduct } from "../../types/SellerConsignment";
import { Autocomplete } from "@material-ui/lab";
import api from "../../services/Api";

interface ListProductsProps {
  index: number;
  product: Product;
  isEdit?: boolean;
  entityId?: number | string;
  onClickAddProduct?: (
    typedText: string,
    index: number,
    product: Product
  ) => void;
  sellerConsignmentProducts?: SellerConsignmentProduct[];
}

export const ListProducts: React.FC<ListProductsProps> = ({
  index,
  product,
  isEdit = false,
  entityId,
  onClickAddProduct,
  sellerConsignmentProducts,
}) => {
  const { user } = useSelector((state: any) => state.auth);

  const { dispatchProducts } = useBudget();
  const [nfeConfig, setNfeConfig] = useState<any>();

  useEffect(() => {
    getNfeConfig();
  }, []);

  const getCfop = useCallback(async () => {
    if (!nfeConfig) return undefined;

    return nfeConfig.cfopConsumer;
  }, [nfeConfig]);

  async function getNfeConfig() {
    const response = await api.get("nfeConfig");
    const config = response.data[0];
    setNfeConfig(config);
  }

  const handleChangeProduct = async (value: SelectableProduct | string) => {
    const defaultCfop = (await getCfop()) ?? "0";
    const productObj = typeof value === "string" ? null : value;
    const productName = typeof value === "string" ? value : "";

    dispatchProducts({
      type: "CHANGE",
      payload: {
        index,
        product: {
          isLinked: !!productObj,
          name: productObj?.name ?? productName,
          amount: 1,
          INSTANCE: productObj ?? ({} as SelectableProduct),
          total: productObj?.saleValue ?? 0,
          unitaryValue: productObj?.saleValue ?? 0,
          cfop: defaultCfop ?? "0",
          icms: productObj?.icmsRate?.length ? productObj.icmsRate : "0",
          ipi: productObj?.ipiRate?.length ? productObj.ipiRate : "0",
          ncm: productObj?.ncm ?? "0",
        },
      },
    });
  };

  const handleChangeValues = (amount: number, unitaryValue: any) => {
    dispatchProducts({
      type: "CHANGE",
      payload: {
        index,
        product: {
          ...product,
          amount: amount,
          unitaryValue: formatToFloat(unitaryValue),
          total: amount * formatToFloat(unitaryValue),
        },
      },
    });
  };

  return (
    <div className="row col-lg-12 ml-2">
      <div className="col-lg-4 d-flex align-items-center p-1 ">
        <div className="mr-2 d-flex justify-content-center align-items-center bg-primary p-2 px-4 mt-2">
          <label className="text-white m-0">{index + 1}.</label>
        </div>

        {sellerConsignmentProducts ? (
          <Autocomplete
            size="small"
            noOptionsText="Sem opções"
            options={sellerConsignmentProducts.map((prod) => prod.INSTANCE)}
            renderOption={(option) => {
              if (!option) return "";

              const consignmentProduct = sellerConsignmentProducts.find(
                (prod) => prod.productId === option.id
              );

              return `${option.name} - Qtde: ${consignmentProduct?.amount ??
                0}`;
            }}
            style={{ width: "100%" }}
            getOptionLabel={(option) => option?.name ?? ""}
            onChange={(e, val) =>
              handleChangeProduct(val ?? ({} as SelectableProduct))
            }
            value={product.INSTANCE}
            disabled={user.isAccountant == "y" ? true : false}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Produto"
                margin="normal"
                variant="outlined"
                error={product.hasError}
              />
            )}
          />
        ) : (
          <ProductSelect
            label="Produto"
            isEdit={isEdit}
            selectedProduct={product}
            onChangeProduct={handleChangeProduct}
            entityType="budget"
            entityId={entityId}
            disabled={user.isAccountant == "y" ? true : false}
            hasError={product.hasError}
            allowIncomplete={true}
            type="product"
            onClickAddProduct={
              onClickAddProduct
                ? (typedText) => onClickAddProduct(typedText, index, product)
                : undefined
            }
          />
        )}
      </div>

      <div className="col p-1">
        <TextField
          size="small"
          label="Qtde."
          margin="normal"
          variant="outlined"
          name="amount"
          type="number"
          value={product.amount}
          onChange={(evt) =>
            handleChangeValues(Number(evt.target.value), product.unitaryValue)
          }
          disabled={user.isAccountant == "y" ? true : false}
        />
      </div>

      <div className="col p-1">
        <NumericFormat
          label="IPI %"
          value={product.ipi}
          disabled={user.isAccountant == "y" ? true : false}
          onChange={(evt) =>
            dispatchProducts({
              type: "CHANGE",
              payload: {
                index,
                product: {
                  ...product,
                  ipi: evt.target.value,
                },
              },
            })
          }
          onBlur={(e) => {
            var converted = e.target.value ? formatToFloat(e.target.value) : 0;

            if (converted > 100) {
              dispatchProducts({
                type: "CHANGE",
                payload: {
                  index,
                  product: {
                    ...product,
                    ipi: "100",
                  },
                },
              });
            } else {
              dispatchProducts({
                type: "CHANGE",
                payload: {
                  index,
                  product: {
                    ...product,
                    ipi: converted ? String(converted) : "0",
                  },
                },
              });
            }
          }}
        />
      </div>

      <div className="col p-1">
        <NumericFormat
          label="ICMS %"
          value={product.icms}
          disabled={user.isAccountant == "y" ? true : false}
          onChange={(evt) =>
            dispatchProducts({
              type: "CHANGE",
              payload: {
                index,
                product: {
                  ...product,
                  icms: evt.target.value,
                },
              },
            })
          }
          onBlur={(e) => {
            var converted = e.target.value ? formatToFloat(e.target.value) : 0;

            if (converted > 100) {
              dispatchProducts({
                type: "CHANGE",
                payload: {
                  index,
                  product: {
                    ...product,
                    icms: "100",
                  },
                },
              });
            } else {
              dispatchProducts({
                type: "CHANGE",
                payload: {
                  index,
                  product: {
                    ...product,
                    icms: converted ? String(converted) : "0",
                  },
                },
              });
            }
          }}
        />
      </div>

      <div className="col-lg-2 p-1">
        <NumericFormat
          label="Valor Unit."
          value={product.unitaryValue}
          startAdornment="R$"
          onChange={(evt) =>
            handleChangeValues(Number(product.amount), evt.target.value)
          }
          disabled={user.isAccountant == "y" ? true : false}
        />
      </div>

      <div className="col p-1">
        <NumericFormat
          label="CFOP"
          format="####"
          value={product.cfop}
          onChange={(evt) =>
            dispatchProducts({
              type: "CHANGE",
              payload: {
                index,
                product: {
                  ...product,
                  cfop: evt.target.value,
                },
              },
            })
          }
          disabled={user.isAccountant == "y" ? true : false}
        />
      </div>

      <div className="col p-1">
        <NumericFormat
          label="NCM"
          format="########"
          value={product.ncm}
          onChange={(evt) =>
            dispatchProducts({
              type: "CHANGE",
              payload: {
                index,
                product: {
                  ...product,
                  ncm: evt.target.value,
                },
              },
            })
          }
          disabled={user.isAccountant == "y" ? true : false}
        />
      </div>

      <div className="col-lg-2 p-1">
        <NumericFormat
          label="Valor Total"
          startAdornment="R$"
          disabled
          value={product.total}
        />
      </div>

      {index > 0 && user.isAccountant == "n" && (
        <button
          type="button"
          className="btn btn-link"
          onClick={() =>
            dispatchProducts({
              type: "DELETE",
              payload: {
                index,
              },
            })
          }
        >
          <i className="flaticon-delete"></i>
        </button>
      )}
    </div>
  );
};
